import { Icon, Tag, Text } from '@nimbus-ds/components';
import { EditIcon, ExclamationTriangleIcon, TrashIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import FeatureFlag from 'App/components/FeatureFlag';
import {
  NEW_ONBOARDING_B,
  NUVEMENVIO_CORREIOS_COMMUNICATION,
  NUVEMENVIO_JADLOG_COMMUNICATION,
} from 'App/featuresFlags';
import {
  ActionIconButton,
  MethodNameAndImage,
  Stack,
} from 'commons/components';
import { useGetTags } from 'domains/Auth/hooks';
import dashboardService from 'domains/Dashboard/services';
import {
  CorreiosCommunication,
  JadlogCommunication,
} from 'domains/NuvemEnvioCommons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ActiveShippingMethodsListSkeleton from './ActiveShippingMethodsListSkeleton';

export interface ActiveShippingMethodsInterface {
  code: string;
  logo: string;
  name: string;
  id: string;
  isNative: boolean;
  tags: string[];
  enableForStoreFeaturePlan: boolean;
  isMultiCDCompatible: boolean;
}

interface ActiveShippingMethodsListProps {
  activeShippingMethods: ActiveShippingMethodsInterface[];
  onEdit: (method: ActiveShippingMethodsInterface) => void;
  onRemove: (
    method: Pick<ActiveShippingMethodsInterface, 'id' | 'isNative'>,
  ) => void;
}

function ActiveShippingMethodsList({
  activeShippingMethods,
  onEdit,
  onRemove,
}: ActiveShippingMethodsListProps) {
  const t = useTranslationShipping();
  const { locations } = useLocations();
  const tags = useGetTags();

  const handleEdit = (method: ActiveShippingMethodsInterface) => {
    if (method.code.includes('nuvem-envio')) {
      dashboardService
        .updateOnboardingTaskSetupShippingNative()
        .then(() => {
          onEdit(method);
        })
        .catch((error) => {
          console.error('Error updating nuvem-envio-task:', error);
        });
    } else {
      onEdit(method);
    }
  };

  return (
    <DataList bottomDivider={false}>
      {activeShippingMethods
        .filter((method) => method.enableForStoreFeaturePlan)
        .map((method) => (
          <DataList.Row id={method.id} key={method.id}>
            <Stack justify="space-between">
              <Stack column align="stretch" spacing="tight">
                <MethodNameAndImage name={method.name} image={method.logo} />
                <Stack wrap spacing="tight">
                  {method.tags.map((tag) => (
                    <Tag key={tag}>
                      <Text>{tag}</Text>
                    </Tag>
                  ))}
                </Stack>
              </Stack>
              <Stack spacing="tight" justify="flex-end">
                {!method.code.includes('mercado-envios') && (
                  <FeatureFlag
                    flag={NEW_ONBOARDING_B}
                    renderElse={
                      <ActionIconButton
                        content={t(
                          'deliveryMethods.activeShippingMethods.editButton',
                        )}
                        source={<EditIcon />}
                        onClick={() => onEdit(method)}
                      />
                    }
                  >
                    <ActionIconButton
                      content={t(
                        'deliveryMethods.activeShippingMethods.editButton',
                      )}
                      source={<EditIcon />}
                      onClick={() => handleEdit(method)}
                    />
                  </FeatureFlag>
                )}
                {!method.code.includes('nuvem-envio') && (
                  <ActionIconButton
                    content={t(
                      'deliveryMethods.activeShippingMethods.deleteButton',
                    )}
                    source={<TrashIcon />}
                    onClick={() =>
                      onRemove({ id: method.id, isNative: method.isNative })
                    }
                  />
                )}
              </Stack>
            </Stack>
            {method.code.includes('nuvem-envio') && (
              <>
                {tags.includes(NUVEMENVIO_JADLOG_COMMUNICATION) && (
                  <JadlogCommunication
                    activeShippingMethods={activeShippingMethods}
                    page="shipping"
                  />
                )}
                {tags.includes(NUVEMENVIO_CORREIOS_COMMUNICATION) && (
                  <CorreiosCommunication
                    activeShippingMethods={activeShippingMethods}
                    page="shipping"
                  />
                )}
              </>
            )}
            {!method.isMultiCDCompatible && locations.length > 1 && (
              <Tag appearance="warning">
                <Icon source={<ExclamationTriangleIcon size={12} />} />
                <Text fontSize="caption" color="warning-textLow" lineClamp={1}>
                  {t(
                    'deliveryMethods.activeShippingMethods.isMultiCDIncompatibleAlert',
                  )}
                </Text>
              </Tag>
            )}
          </DataList.Row>
        ))}
    </DataList>
  );
}

ActiveShippingMethodsList.Skeleton = ActiveShippingMethodsListSkeleton;

export default ActiveShippingMethodsList;
