import { Icon, IconButton, Link, Text, Tooltip } from '@nimbus-ds/components';
import { MailIcon, QuestionCircleIcon } from '@nimbus-ds/icons';
import { AbandonedCartsResponseDto } from '@tiendanube/common';
import { Stack, useResponsive } from 'commons/components';
import {
  useAbandonedCartConfigEmail,
  useAbandonedCartSendEmail,
} from 'domains/Orders/AbandonedCart/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface WarningWithTooltipProps {
  text: string;
  tooltipContent: string;
}

function WarningWithTooltip({
  text,
  tooltipContent,
}: Readonly<WarningWithTooltipProps>) {
  return (
    <Stack spacing="tight">
      <Text color="neutral-textHigh">{text}</Text>
      <Tooltip content={tooltipContent} position="left">
        <Icon
          source={<QuestionCircleIcon size="small" />}
          color="primary-interactive"
        />
      </Tooltip>
    </Stack>
  );
}

interface AbandonedCartsSendEmailProps {
  abandonedCart: AbandonedCartsResponseDto;
}

function AbandonedCartsSendEmail({
  abandonedCart,
}: Readonly<AbandonedCartsSendEmailProps>) {
  const t = useTranslationOrders();
  const { isMobile } = useResponsive();
  const { abandonedCartEmailConfig } = useAbandonedCartConfigEmail();
  const {
    isEmailSent: showEmailSentLabel,
    isLoading: isSendingEmail,
    handleSendEmail,
  } = useAbandonedCartSendEmail(abandonedCart);

  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  const abandonedCartDate = new Date(abandonedCart.date);

  const showNotAcceptsEmailsLabel = !abandonedCart.acceptToReceiveEmail;
  const showNoActionsLabel = !abandonedCart.hasStockAvailable;
  const enableSendEmailLink =
    abandonedCartEmailConfig === 'manual' || abandonedCartDate < threeDaysAgo;
  const showEmailWaitingtToBeSentLabel =
    abandonedCartEmailConfig === 'automatic' &&
    !abandonedCart.wasNotified &&
    abandonedCartDate > threeDaysAgo;

  if (showEmailSentLabel)
    return (
      <WarningWithTooltip
        text={t('abandonedCarts.sendEmail.emailSent')}
        tooltipContent={t('abandonedCarts.table.body.emailSent')}
      />
    );

  if (showNotAcceptsEmailsLabel)
    return (
      <WarningWithTooltip
        text={t('abandonedCarts.sendEmail.noSendEmailsLabel')}
        tooltipContent={t('abandonedCarts.table.body.acceptsNoMarketing')}
      />
    );

  if (showNoActionsLabel)
    return (
      <WarningWithTooltip
        text={t('abandonedCarts.sendEmail.noActions')}
        tooltipContent={t('abandonedCarts.table.body.noActions')}
      />
    );

  if (showEmailWaitingtToBeSentLabel)
    return (
      <Text color="neutral-textHigh">
        {t('abandonedCarts.sendEmail.waiting')}
      </Text>
    );

  if (!isMobile)
    return (
      <Link
        as="button"
        appearance="primary"
        textDecoration="none"
        disabled={!enableSendEmailLink || isSendingEmail}
        onClick={handleSendEmail}
      >
        {t('abandonedCarts.sendEmail.textButton')}
      </Link>
    );

  return (
    <IconButton
      source={<MailIcon />}
      size="2rem"
      aria-label={t('abandonedCarts.sendEmail.textButton')}
      disabled={!enableSendEmailLink || isSendingEmail}
      onClick={handleSendEmail}
    />
  );
}

export default AbandonedCartsSendEmail;
