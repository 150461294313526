import { Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { PopoverToogle } from 'commons/components';

interface DiscountTypeProps {
  discountType: PromotionsItemListResponseDto['discountType'];
  discountTypeInfo: PromotionsItemListResponseDto['discountTypeInfo'];
}

export function DiscountType({
  discountType,
  discountTypeInfo,
}: Readonly<DiscountTypeProps>) {
  const discountTypeComponent = (
    <Text lineClamp={1} fontWeight="regular">
      {discountType}
    </Text>
  );

  if (!discountTypeInfo) return discountTypeComponent;

  return (
    <PopoverToogle
      label={discountTypeComponent}
      content={
        <Text fontSize="caption">
          <span
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: discountTypeInfo }}
          />
        </Text>
      }
    />
  );
}
