import { Box, Text, Icon } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useMenuScrollShadow } from 'App/components/Menu/hooks';
import { useNavegate, useHandleMenuClick } from 'App/hooks';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { trackingSessionManagementMenuItemClick } from 'domains/Account/tracking';
import { useGetUserInfo, useHeaderMenuData } from 'domains/Auth/hooks';
import useLogout from 'domains/Auth/pages/LogoutPage/useLogout';
import { PATH } from '../../../utils';

interface AccountMenuContentProps {
  device?: 'mobile' | 'desktop';
}

function NewAccountMenuContent({ device }: Readonly<AccountMenuContentProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useNavegate();
  const isMobile = device === 'mobile';

  const { handleClickItem } = useHandleMenuClick();
  const logout = useLogout();
  const { goTo } = useNavegate();
  const { name } = useHeaderMenuData();
  const { email, id } = useGetUserInfo();
  const handleSessionManagementMenuItemClick = () => {
    trackingSessionManagementMenuItemClick();
    handleClickItem();
  };
  const handleClickLogOut = isMobile ? handleClickItem : logout;
  const menuBodyRef = useMenuScrollShadow();

  const profielPath = generatePath(ACCOUNT_ROUTES.userForm, { id });

  return (
    <>
      <Menu.Header>
        <Box display="flex" flexDirection="column" gap="4">
          {isMobile && (
            <Box
              display="flex"
              gap="1"
              as="button"
              backgroundColor="transparent"
              borderColor="transparent"
              onClick={() => goTo('/')}
              alignItems="center"
              cursor="pointer"
            >
              <Icon
                color="neutral-textHigh"
                source={<ChevronLeftIcon size={14} />}
              />
              <Text fontWeight="medium" lineClamp={1} color="neutral-textHigh">
                {name}
              </Text>
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Text color="neutral-textHigh" fontWeight="medium" lineClamp={1}>
              {t('accountMenu.title')}
            </Text>
            <Text fontSize="caption" lineClamp={1} wordBreak="break-all">
              {email}
            </Text>
          </Box>
        </Box>
      </Menu.Header>
      <div className="stratus--menu-body" ref={menuBodyRef}>
        <Menu.Body>
          <Menu.Section>
            <Menu.Button
              as={Link}
              to={profielPath}
              active={pathname.startsWith(profielPath)}
              label={t('accountMenu.editProfile')}
            />
            <Menu.Button
              as={Link}
              to={PATH.authenticationFactor}
              active={pathname.startsWith(PATH.authenticationFactor)}
              label={t('accountMenu.security.authenticationFactor')}
              onClick={handleClickItem}
            />
            <Menu.Button
              as={Link}
              to={PATH.sessionManagement}
              active={pathname.startsWith(PATH.sessionManagement)}
              label={t('accountMenu.security.sessionManagement')}
              onClick={handleSessionManagementMenuItemClick}
            />
          </Menu.Section>
          {!isMobile && (
            <Menu.Button
              label={t('menu.closeSession')}
              onClick={handleClickLogOut}
            />
          )}
        </Menu.Body>
      </div>
      {isMobile && (
        <Box
          boxSizing="border-box"
          display="flex"
          flex="0 1 auto"
          paddingX="2"
          paddingY="3"
          width="100%"
        >
          <Menu.Button
            as={Link}
            to={PATH.logout}
            label={t('menu.closeSession')}
            onClick={handleClickLogOut}
          />
        </Box>
      )}
    </>
  );
}

export default NewAccountMenuContent;
