import { useState } from 'react';
import { Card, Icon, Link, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { PickupPointResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { ErrorScreen, Stack } from 'commons/components';
import { useAsyncFunc, useModal, useToast } from 'commons/hooks';
import { DeleteShippingModal } from 'domains/Shipping/DeliveryMethods/pages/components';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import usePickupPoints from '../../hooks/usePickupPoints';
import PickupPointItem from '../PickupPointItem';

interface OtherPickupPointsProps {
  handleUpdateStatus: (pickupPoint: PickupPointResponseDto) => void;
}

function OtherPickupPoints({
  handleUpdateStatus,
}: Readonly<OtherPickupPointsProps>) {
  const t = useTranslationShipping('deliveryMethods.pickupPoints.pickup');
  const { goTo } = useNavegate();
  const {
    isLoadingList,
    isErrorList,
    otherPickupPoints,
    fetchPickupPoints,
    deletePickupPoint,
  } = usePickupPoints();
  const {
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
    locations,
    fetchLocations,
  } = useLocations();

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isOpenDelete, openDelete, closeDelete] = useModal();
  const { addToast } = useToast();

  const handleDeletePickupPoint = (id: string) => {
    setSelectedId(id);
    openDelete();
  };

  const [handleDelete, isDeleting] = useAsyncFunc(
    async () => {
      if (selectedId) {
        await deletePickupPoint(selectedId);
      }
    },
    () => {
      addToast({
        label: t('toast.delete.success'),
        appearance: 'success',
      });
      closeDelete();
    },
    () => {
      addToast({
        label: t('toast.delete.error'),
        appearance: 'danger',
      });
    },
  );

  const sortPickupPoints = (
    a: PickupPointResponseDto,
    b: PickupPointResponseDto,
  ): number => {
    if (a.status === b.status) {
      return 0;
    }
    return a.status ? -1 : 1;
  };

  const isLoading = isLoadingList || isLoadingLocations;
  const isError = isErrorList || isErrorLocations;
  const hasLocations = locations && locations.length > 0;

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('title')} />
      <Card.Body padding="base">
        <Stack align="flex-start" column>
          {!isError && <Text>{t('subtitle')}</Text>}
          {!isError && !isLoading && (
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={() => goTo(DELIVERY_METHODS_ROUTES.newPickupPoint)}
            >
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('action')}
            </Link>
          )}
          {isLoading && !isError && <Link.Skeleton width="20%" />}
        </Stack>
        {isError && !isLoading && (
          <ErrorScreen
            description={t('error.description')}
            onRetry={() => {
              fetchPickupPoints();
              fetchLocations();
            }}
          />
        )}
      </Card.Body>
      <Card.Footer>
        {!isError &&
          !isLoading &&
          hasLocations &&
          otherPickupPoints &&
          otherPickupPoints.length > 0 && (
            <DataList bottomDivider={false}>
              {otherPickupPoints.sort(sortPickupPoints).map((pickupPoint) => (
                <PickupPointItem
                  key={pickupPoint.id}
                  pickupPoint={pickupPoint}
                  locations={locations}
                  handleUpdateStatus={handleUpdateStatus}
                  handleDelete={handleDeletePickupPoint}
                />
              ))}
            </DataList>
          )}

        {isLoading && (
          <DataList bottomDivider={false}>
            <PickupPointItem.Skeleton />
            <PickupPointItem.Skeleton />
          </DataList>
        )}
      </Card.Footer>
      {isOpenDelete && !!selectedId && (
        <DeleteShippingModal
          isOpen
          isLoading={isDeleting}
          title={t('delete.title')}
          description={t('delete.description')}
          onClose={closeDelete}
          onConfirmDelete={handleDelete}
        />
      )}
    </Card>
  );
}

export default OtherPickupPoints;
