import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useGetIsFreePlan } from 'domains/Auth/hooks';
import {
  AbandonedCartEmalConfg,
  updateAbandonedCartsEmailConfig,
} from 'domains/Orders/AbandonedCart/abandonedCartsSlice';
import {
  getAbandonedCartEmailConfig,
  getAbandonedCartEmailStatus,
} from 'domains/Orders/AbandonedCart/abandonedCartsSlice/abandonedCartsSelectors';

interface UseAbandonedCartConfigEmailResult {
  abandonedCartEmailConfig: AbandonedCartEmalConfg;
  isAutomaticOptionAllowed: boolean;
  isLoading: boolean;
  updateEmailConfig: (config: AbandonedCartEmalConfg) => void;
}

function useAbandonedCartConfigEmail(): UseAbandonedCartConfigEmailResult {
  const dispatch = useAppDispatch();

  const { isFreePlan, isTrial } = useGetIsFreePlan();

  const isAlwaysManual = isFreePlan || isTrial;

  const abandonedCartEmailConfig = useSelector(getAbandonedCartEmailConfig);
  // Due to a bug some merchants might have the config as Automatic when they shouldn't be able to.
  // Still, emails will not be sent automatically, so we shouldn't show automatic as the selected value.
  const realAbandonedCartEmailConfig = isAlwaysManual
    ? 'manual'
    : abandonedCartEmailConfig;

  const { isLoading } = useSelector(getAbandonedCartEmailStatus);

  const updateEmailConfig = async (config: AbandonedCartEmalConfg) => {
    unwrapResult(await dispatch(updateAbandonedCartsEmailConfig(config)));
  };

  return {
    abandonedCartEmailConfig: realAbandonedCartEmailConfig,
    isAutomaticOptionAllowed: !isAlwaysManual,
    isLoading,
    updateEmailConfig,
  };
}

export default useAbandonedCartConfigEmail;
