import { useCallback } from 'react';
import { Box, Button, Label } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { SavedSearchInterface } from '../types';
import './SavedSearch.scss';

interface SavedSearchProperties {
  savedSearch: SavedSearchInterface;
  isSelected: boolean;
  onSavedSearchClick: (savedSearch: SavedSearchInterface) => void;
}

export function SavedSearch({
  savedSearch,
  isSelected,
  onSavedSearchClick,
}: SavedSearchProperties) {
  const t = useTranslationOrders();

  const onClick = useCallback(() => {
    onSavedSearchClick(savedSearch);
  }, [onSavedSearchClick, savedSearch]);

  return (
    <Box marginRight="2">
      <div className={isSelected ? 'stratus--selected-saved-search' : ''}>
        <Button onClick={onClick}>
          <Box
            paddingBottom="2"
            paddingTop="2"
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Label>{t(`savedSearches.default.${savedSearch.code}`)}</Label>
          </Box>
        </Button>
      </div>
    </Box>
  );
}
