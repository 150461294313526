import { useCallback, useEffect } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { PlusCircleIcon, EditIcon } from '@nimbus-ds/icons';
import isEqual from 'lodash.isequal';
import { Prompt, useHistory, useParams } from 'react-router';
import { Button } from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
  SubmitButton,
} from 'commons/components';
import { HELP_LINKS_CUSTOMER_CREATE } from 'commons/constants';
import {
  useAsyncFunc,
  useAutoFocus,
  useForm,
  useToast,
  useTranslationLanguage,
} from 'commons/hooks';
import useCustomerFormPageLoadTracking from 'domains/Customers/Customers/pages/CustomerDetailsPage/hooks/useCustomerFormPageLoadTracking';
import {
  useFetchCustomerMetafields,
  useTranslationCustomers,
} from 'domains/Customers/hooks';
import {
  trackingCustomerEditSave,
  trackingCustomerNewSave,
  trackingCustomerSaveMetafields,
} from 'domains/Customers/tracking';
import {
  CustomerDataCard,
  CustomerFormPageError,
  CustomerFormPageSkeleton,
  DeliveryInfoCard,
} from './components';
import SectionCustomerMetafieldsExisting from './components/SectionCustomerMetafieldsExisting';
import SectionCustomerMetafieldsNew from './components/SectionCustomerMetafieldsNew';
import {
  CustomerFormErrorsInterface,
  CustomerFormValuesInterface,
} from './types';
import useCustomerForm from './useCustomerForm';
import { validationSchema } from './validationSchema';

function CustomerFormPage() {
  const { id } = useParams<{ id: string }>();
  const t = useTranslationCustomers();
  const language = useTranslationLanguage();

  const { goBack, replace } = useHistory();
  const { addToast } = useToast();
  const { ref } = useAutoFocus();

  const { isSuccess: isMetafieldsSuccess, isError: isMetafieldsError } =
    useFetchCustomerMetafields();
  useCustomerFormPageLoadTracking(id, isMetafieldsSuccess, isMetafieldsError);

  const {
    customer: initialValues,
    isLoading: isValuesLoading,
    isError: isValuesError,
    getCustomerValues,
    addCustomer,
    updateCustomer,
  } = useCustomerForm(id);

  const {
    errors,
    values,
    isDirty,
    isValidating,
    resetValues,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
  } = useForm<CustomerFormValuesInterface, CustomerFormErrorsInterface>({
    initialValues,
    validationSchema: validationSchema(initialValues.email || ''),
    onSubmit: (customerValues) => {
      handleCreateCustomer(customerValues);
    },
    validateOnBlur: ['zipcode'],
  });

  const [handleCreateCustomer, isLoading, isError, clearError] = useAsyncFunc<
    CustomerFormValuesInterface,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        const customer = await (id ? updateCustomer(data) : addCustomer(data));
        trackingMetafieldsChange(id ? 'EditCustomer' : 'AddCustomer');

        if (!id) {
          trackingCustomerNewSave(customer.id, false);
        } else {
          trackingCustomerEditSave(customer.id, false);
        }

        addToast({
          label: id
            ? t('customerForm.success.edit')
            : t('customerForm.success.new'),
          appearance: 'success',
        });
        replace(`/customers/${customer.id}`);
      }
    },
    undefined,
    () => {
      if (!id) {
        trackingCustomerNewSave('', true);
      } else {
        trackingCustomerEditSave(id, true);
      }
    },
  );

  const trackingMetafieldsChange = useCallback(
    (pageName) => {
      if (
        !isEqual(
          values.metafields?.internals,
          initialValues.metafields?.internals,
        ) ||
        !isEqual(values.metafields?.fromApi, initialValues.metafields?.fromApi)
      ) {
        trackingCustomerSaveMetafields(pageName);
      }
    },
    [values, initialValues],
  );

  const textConfirm = id
    ? t('customerForm.confirm.edit')
    : t('customerForm.confirm.new');

  const mainAction = {
    icon: id ? EditIcon : PlusCircleIcon,
    onClick: () => handleOnSubmit(),
    children: textConfirm,
    disabled: isLoading || isValidating,
    spinner: isLoading || isValidating,
  };

  const backNavigation = {
    onClick: () => goBack(),
  };

  const headerTop = () => {
    if (isValuesError)
      return <HeaderTop navigation={{ onClick: () => goBack() }} />;
    if (!isValuesLoading && !isValuesError)
      return <HeaderTop mainAction={mainAction} navigation={backNavigation} />;
  };
  const headerContent = () => {
    if (isValuesLoading) return <HeaderContent title="skeleton" />;
    if (isValuesError)
      return <HeaderContent title={t('customerForm.errorState.title')} />;
    if (!isValuesLoading && !isValuesError) {
      return (
        <Box display="flex" flexDirection="column" gap="4">
          <HeaderContent
            title={
              initialValues.name
                ? t('customerForm.titleEditGeneric')
                : t('customerForm.title')
            }
          />
          {!!initialValues.name && <Title as="h2">{initialValues.name}</Title>}
        </Box>
      );
    }
  };

  const handleChangeMetafields = (metafields) => {
    handleOnChange({ name: 'metafields', value: metafields });
  };

  useEffect(() => {
    if (isError) {
      addToast({
        label: t('customerForm.error'),
        appearance: 'danger',
      });
      clearError();
    }
  }, [isError, clearError, addToast, t]);

  useEffect(() => {
    resetValues();
  }, [id, initialValues, resetValues]);

  return (
    <IonPageStratus
      headerTop={headerTop()}
      headerContent={headerContent()}
      width="small"
    >
      {isValuesLoading && <CustomerFormPageSkeleton />}
      {isValuesError && <CustomerFormPageError onRetry={getCustomerValues} />}
      {!isValuesLoading && !isValuesError && (
        <>
          <Prompt when={isDirty} message={t('common:exitEdit.info')} />
          <form onSubmit={handleOnSubmit}>
            <Stack column align="stretch" spacing="loose" innerRef={ref}>
              <Stack.Item>
                <CustomerDataCard
                  values={values}
                  errors={errors}
                  onChange={handleOnChange}
                />
              </Stack.Item>
              <Stack.Item>
                <DeliveryInfoCard
                  values={values}
                  errors={errors}
                  onChange={handleOnChange}
                  onBlurZipCode={handleOnBlur}
                />
              </Stack.Item>
              {!id && (
                <Stack.Item>
                  <SectionCustomerMetafieldsNew
                    selecteds={values.metafields?.internals || []}
                    apiSelecteds={values.metafields?.fromApi || []}
                    onChange={handleChangeMetafields}
                  />
                </Stack.Item>
              )}
              {!!id && (
                <Stack.Item>
                  <SectionCustomerMetafieldsExisting
                    selecteds={values.metafields?.internals || []}
                    apiSelecteds={values.metafields?.fromApi || []}
                    onChange={handleChangeMetafields}
                    id={id}
                  />
                </Stack.Item>
              )}
              <Stack.Item>
                <Stack spacing="base" justify="flex-end">
                  <Stack.Item>
                    <Button onClick={goBack}>{`${t(
                      'customerForm.cancel',
                    )}`}</Button>
                  </Stack.Item>
                  <Stack.Item>
                    <SubmitButton
                      type="submit"
                      isLoading={isValidating || isLoading}
                      disabled={isValidating || isLoading}
                      appearance="primary"
                    >
                      {textConfirm}
                    </SubmitButton>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item> </Stack.Item>
            </Stack>
          </form>
        </>
      )}
      {!id && (
        <HelpLink
          title={t('customerForm.helpLink.title')}
          previousValue=""
          currentViewTracking=""
          linkURL={HELP_LINKS_CUSTOMER_CREATE[language]}
          icon="both"
        />
      )}
    </IonPageStratus>
  );
}

export default CustomerFormPage;
