import { useCallback, useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { Status } from '@tiendanube/common/src/enums';
import {
  InterfaceNameValue,
  InterfaceSelectOption,
  InterfaceSelectOptionGroup,
} from '@tiendanube/components';
import { dateFormat, Format, ninetyDaysAgo } from 'commons/utils/date';
import { useHasOrdersMultiCD } from 'domains/Auth/hooks';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import useGetOrdersFilters from 'domains/Orders/Orders/hooks/useGetOrdersFilters';
import {
  status,
  fulfillmentStatus,
  paymentStatus,
  ExportFiltersType,
  FilterStatusEnum,
  orderOrigin,
  fulfillmentStatusFilter,
  paymentStatusFilter,
} from 'domains/Orders/Orders/ordersService';
import {
  shouldShowMultiCDFullfillmentStatus,
  validateDateLimit,
} from 'domains/Orders/Orders/utils/OrderListFilter';
import { checkCustomPaymentProvider } from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useGetShippingMethods } from 'domains/Shipping/DeliveryMethods/hooks';

interface DateRangeValues {
  dateFrom: string;
  dateTo: string;
}

interface UseOrdersExportFiltersResult {
  values: ExportFiltersType;
  statusOptions: InterfaceSelectOptionGroup[];
  fulfillmentStatusOptions: InterfaceSelectOptionGroup[];
  paymentOptions: InterfaceSelectOptionGroup[];
  paymentMethodOptions: InterfaceSelectOption[];
  paymentProviderOptions: InterfaceSelectOption[];
  shippingMethodOptions: InterfaceSelectOptionGroup[];
  originOptions: InterfaceSelectOptionGroup[];
  getFilterChips: () => { id: string; label: string; notRemovable?: boolean }[];
  handleChange: ({ name, value }: InterfaceNameValue) => void;
  handleDateRangeChange: ({ dateFrom, dateTo }: DateRangeValues) => void;
  handleMultiSelectChange: (
    name: string,
    selectedValues: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => void;
  setAllValues: (filters: ExportFiltersType) => void;
  applicationOptions: InterfaceSelectOption[];
}

function useOrdersExportFilters(
  filters: ExportFiltersType,
): UseOrdersExportFiltersResult {
  const t = useTranslationOrders();
  const hasOrdersMultiCD = useHasOrdersMultiCD();
  const { isAvailable: hasEditOrders } = useIsEditOrdersEnabled();
  const {
    shippingMethods,
    fetchShippingMethods,
    status: shippingMethodsStatus,
  } = useGetShippingMethods();

  const [values, setValues] = useState(filters);
  const {
    paymentMethodOptions,
    paymentProviderOptions,
    paymentProvidersOrdersFilters,
    applicationOptions,
    applicationOrdersFilters,
  } = useGetOrdersFilters();

  useEffect(() => {
    if (shippingMethodsStatus.isIdle) fetchShippingMethods();
  }, [shippingMethodsStatus, fetchShippingMethods]);

  const handleChange = useCallback(
    ({ name, value }) => {
      const filters = validateDateLimit({ ...values, [name]: value });
      setValues(filters);
    },
    [values],
  );

  const handleDateRangeChange = useCallback(
    ({ dateFrom, dateTo }) => {
      setValues({ ...values, dateFrom, dateTo });
    },
    [values],
  );

  const handleMultiSelectChange = useCallback(
    (name, selectedValues) => {
      setValues({
        ...values,
        [name]: selectedValues.map(({ value }) => value).join(),
      });
    },
    [values],
  );

  const setAllValues = useCallback((filters: ExportFiltersType) => {
    setValues(filters);
  }, []);

  const getFilterChips = useCallback(() => {
    const filterChips = Object.entries(filters)
      .filter(
        ([key, value]) =>
          (value !== '' && value !== FilterStatusEnum.ALL) ||
          (key === 'status' && value === FilterStatusEnum.ALL),
      )
      .filter(
        ([key, _]) =>
          key !== 'products' && !key.startsWith(METAFIELD_FILTER_PREFIX),
      )
      .flatMap(([key, value]) => {
        if (key === 'shippingMethod') {
          const label = Object.values(shippingMethods)
            .flat()
            .find(({ code }) => code === value)?.name;
          return { id: key, label: label };
        }
        if (checkCustomPaymentProvider(key, value)) {
          const label = t(`filters.paymentProvider.${value}`);
          return { id: key, label: label };
        }
        if (key === 'paymentProvider' && paymentProvidersOrdersFilters) {
          const label =
            paymentProvidersOrdersFilters &&
            Object.values(paymentProvidersOrdersFilters)
              .flat()
              .find(({ id }) => id === value)?.name;
          return { id: key, label: label };
        }
        if (key === 'dateFrom') {
          const label = t('appliedFilters.dateFrom', {
            dateFrom: dateFormat(value, Format.DAY_MONTH_YEAR_NUMBER),
          });
          const hasFiltersWithDateRestrictionsApplied =
            filters.paymentMethods !== '' ||
            filters.paymentProvider !== '' ||
            filters.appId !== '';
          const isRemovable =
            !hasFiltersWithDateRestrictionsApplied || value > ninetyDaysAgo;
          return { id: key, label: label, notRemovable: !isRemovable };
        }
        if (key === 'dateTo') {
          const label = t('appliedFilters.dateTo', {
            dateTo: dateFormat(value, Format.DAY_MONTH_YEAR_NUMBER),
          });
          return { id: key, label: label };
        }
        if (key === 'paymentMethods') {
          return {
            id: key,
            label: t(`filters.paymentMethod.${value}`),
          };
        }
        if (key === 'appId' && applicationOrdersFilters) {
          const label = Object.values(applicationOrdersFilters)
            .flat()
            .find(({ id }) => id === value)?.name;
          return { id: key, label: label };
        }
        if (key === paymentStatusFilter || key === fulfillmentStatusFilter) {
          return value.split(',').map((val) => ({
            id: `${key}-${val}`,
            label: t(`appliedFilters.${val}`, val as string),
          }));
        }

        return {
          id: key,
          label: t(`appliedFilters.${value}`, value),
        };
      });

    return filterChips;
  }, [
    filters,
    shippingMethods,
    paymentProvidersOrdersFilters,
    applicationOrdersFilters,
    t,
  ]);

  const statusOptions: InterfaceSelectOptionGroup[] = status.map((status) => ({
    value: status,
    label: t(`filters.status.${status}`),
  }));

  const fulfillmentStatusOptions: InterfaceSelectOptionGroup[] =
    fulfillmentStatus
      .filter(
        (status) =>
          status !== FilterStatusEnum.ALL &&
          shouldShowMultiCDFullfillmentStatus(status, hasOrdersMultiCD),
      )
      .map((status) => ({
        value: status,
        label: t(`filters.fulfillment.${status}`),
      }));

  const paymentOptions: InterfaceSelectOptionGroup[] = paymentStatus
    .concat(hasEditOrders ? [Status.PARTIALLY_PAID] : [])
    .filter((status) => status !== FilterStatusEnum.ALL)
    .map((payment) => ({
      value: payment,
      label: t(`filters.payment.${payment}`),
    }));

  const shippingMethodAllOption: InterfaceSelectOptionGroup = {
    group: t('filters.shippingMethod.all'),
    options: [{ label: t('filters.shippingMethod.all'), value: '' }],
  };
  const shippingMethodOptions: InterfaceSelectOptionGroup[] = [
    shippingMethodAllOption,
  ].concat(
    Object.keys(shippingMethods).map((key) => ({
      group: t(`filters.shippingMethod.${key}`),
      options: shippingMethods[key].map((method) => ({
        label: method.name,
        value: method.code,
      })),
    })),
  );

  const originOptions: InterfaceSelectOptionGroup[] = orderOrigin.map(
    (origin) => ({
      value: origin,
      label: t(`filters.origin.${origin}`),
    }),
  );

  return {
    values,
    statusOptions,
    fulfillmentStatusOptions,
    paymentOptions,
    paymentMethodOptions,
    paymentProviderOptions,
    shippingMethodOptions,
    originOptions,
    getFilterChips,
    handleChange,
    handleDateRangeChange,
    handleMultiSelectChange,
    setAllValues,
    applicationOptions,
  };
}

export default useOrdersExportFilters;
