import { useEffect, ReactElement, useRef } from 'react';
import { Tag, Text } from '@nimbus-ds/components';
import { CodeIcon } from '@nimbus-ds/icons';
import { Link, Stack } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { useDevMode } from 'App/settings/devMode/hooks';
import {
  useHasExperimentalAppTag,
  useTranslationPartnerApps,
} from 'domains/PartnersApps/hooks';
import WrapperApp from './components/WrapperApp';
import { useNexoClient } from './hooks';
import useIsEmbeddedApp from './useIsEmbeddedApp';

export interface EmbeddedAppProps {
  id: string;
  url: string;
  topBar?: ReactElement;
}

function EmbeddedApp({ id, url, topBar }: Readonly<EmbeddedAppProps>) {
  const { t } = useTranslationPartnerApps();
  const { devModeEnabled } = useDevMode();
  const hasAppDevExperimentalTag = useHasExperimentalAppTag();
  const iframeRef = useRef<HTMLIFrameElement>({} as HTMLIFrameElement);
  const windowRef = useRef<Window | null>(null);
  const statusApp = useNexoClient(id, iframeRef, windowRef);
  const { changeIsEmbeddedApp } = useIsEmbeddedApp();

  const handleRetry = () => {
    iframeRef.current.src += '';
    statusApp.handleRetry();
  };

  const handleOpenInNewTab = () => {
    const newWindow = window.open(url);
    windowRef.current = newWindow;
  };

  useEffect(() => {
    changeIsEmbeddedApp(true);
    return () => {
      changeIsEmbeddedApp(false);
    };
  }, [changeIsEmbeddedApp]);

  return (
    <>
      {topBar}
      <WrapperApp appId={id} status={statusApp.status} onRetry={handleRetry}>
        {(devModeEnabled || hasAppDevExperimentalTag) && (
          <Stack spacing="base" justify="flex-end">
            <Tag appearance="danger">
              <CodeIcon size={12} />
              <Text color="danger-textLow">
                {t(`experimentalApp.testApplication.devModeEnabled`)}
              </Text>
            </Tag>
            <Link
              onClick={handleOpenInNewTab}
              icon={ExternalLinkIcon}
              iconPosition="end"
            >
              {t('embedApp.openInNewTab')}
            </Link>
          </Stack>
        )}
        <iframe
          data-testid="iframe-app"
          onError={statusApp.handleError}
          ref={iframeRef}
          src={url}
          width="100%"
          height="100%"
          allow="camera *; microphone *;"
        />
      </WrapperApp>
    </>
  );
}

export default EmbeddedApp;
