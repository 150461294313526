import { useState } from 'react';
import { Accordion, Box, Button, Link, Text } from '@nimbus-ds/components';
import {
  BoxPackedIcon,
  CheckCircleIcon,
  CreditCardIcon,
  TagIcon,
  ToolsIcon,
  TruckIcon,
} from '@nimbus-ds/icons';
import { useSelector } from 'react-redux';
import { Link as LinkRoute } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import {
  trackingDashboardSeeStoreClick,
  trackingPaymentOthersClick,
  trackingRegisterLocationClick,
  trackingSeePreferencesNuvemEnvioClick,
  trackingSeePreferencesNuvemPagoClick,
  trackingSeePreferencesPagoNubeClick,
  trackingThemePersonalizeClick,
} from 'App/tracking';
import { ExternalLink, IconBox, Stack, SubmitButton } from 'commons/components';
import { getStoreURL } from 'domains/Auth/authSlice/authSelectors';
import {
  useLoggedAdminLink,
  useTransactionFeeAdminDisabled,
} from 'domains/Auth/hooks';
import { TRANSACTION_FEES_BASE_ROUTE } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import dashboardService from 'domains/Dashboard/services';
import {
  trackingAddProductClick,
  trackingSelectThemeClick,
  trackingShippingClick,
} from 'domains/Dashboard/tracking';
import Skeleton from './Skeleton';
import './OnboardingAccordionTasks.scss';

interface OnboardingAccordionProps {
  items: any[]; // TODO: add type
}

const ONBOARDING_TASK_ORDER: {
  setup_first_product: number;
  setup_payments: number;
  setup_layout: number;
  setup_shipping: number;
  store_created: number;
  setup_multicd: number;
} = {
  store_created: 0,
  setup_first_product: 1,
  setup_layout: 2,
  setup_multicd: 3,
  setup_shipping: 4,
  setup_payments: 5,
};

const ICONS: {
  setup_first_product: JSX.Element;
  setup_payments: JSX.Element;
  setup_layout: JSX.Element;
  setup_shipping: JSX.Element;
  store_created: JSX.Element;
  setup_multicd: JSX.Element;
} = {
  setup_layout: <ToolsIcon />,
  setup_first_product: <TagIcon />,
  setup_payments: <CreditCardIcon />,
  setup_shipping: <TruckIcon />,
  setup_multicd: <BoxPackedIcon />,
  store_created: <TruckIcon />,
};

const PRINCIPAL_LOG_EVENTS: {
  setup_first_product: () => void;
  setup_multicd: () => void;
  setup_layout: () => void;
  store_created: () => void;
} = {
  store_created: trackingDashboardSeeStoreClick,
  setup_layout: trackingThemePersonalizeClick,
  setup_first_product: trackingAddProductClick,
  setup_multicd: trackingRegisterLocationClick,
};

const SECONDARY_LOG_EVENTS: {
  setup_layout: () => void;
} = {
  setup_layout: trackingSelectThemeClick,
};

function OnboardingTaskAccordion({ items }: OnboardingAccordionProps) {
  const { goTo } = useNavegate();

  const { goToLoggedAdminLink } = useLoggedAdminLink();
  const isBlocked = useTransactionFeeAdminDisabled();
  const url = useSelector(getStoreURL);
  const sortedItems = [...items]
    .filter((item) => item.name !== 'setup_shipping_nuvemenvio')
    .sort((a, b) => {
      const orderA = ONBOARDING_TASK_ORDER[a.name];
      const orderB = ONBOARDING_TASK_ORDER[b.name];
      return orderA - orderB;
    });

  const [statusLoadingPaymentsConfig, setStatusLoadingPaymentsConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const [statusLoadingShippingConfig, setStatusLoadingShippingConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const goToConfig = (url: string, task: string) => async () => {
    if (task === 'setup_payments') {
      await paymentConfig(url);
    } else if (task === 'setup_shipping') {
      await shippingConfig(url, task);
    }
  };

  const paymentConfig = async (url: string) => {
    setStatusLoadingPaymentsConfig('loading');
    try {
      if (url.startsWith('/settings/payments')) {
        trackingPaymentOthersClick();
        goTo(url);
      } else {
        await dashboardService.updateOnboardingTaskSetupPayments();
        setStatusLoadingPaymentsConfig('success');
        if (url.startsWith('/admin')) {
          trackingSeePreferencesNuvemPagoClick();
          url = url.replace('/admin', '');
          goToLoggedAdminLink(url);
        } else {
          trackingSeePreferencesPagoNubeClick();
          goTo(url);
        }
      }
    } catch (e) {
      setStatusLoadingPaymentsConfig('error');
    }
  };

  const shippingConfig = async (url: string, task: string) => {
    setStatusLoadingShippingConfig('loading');
    try {
      if (url.startsWith('/settings/shipping-methods/carriers')) {
        await dashboardService.updateOnboardingTaskSetupShippingNative();
        trackEvent(url, task);
      }
      setStatusLoadingShippingConfig('success');
      goTo(url);
    } catch (e) {
      setStatusLoadingShippingConfig('error');
    }
  };

  const trackEvent = (url: string, taskName: string) => () => {
    if (taskName === 'setup_shipping') {
      if (url.startsWith('/settings/shipping-methods/carriers')) {
        trackingSeePreferencesNuvemEnvioClick();
      } else {
        trackingShippingClick();
      }
    } else {
      PRINCIPAL_LOG_EVENTS[taskName]();
    }
  };

  const isLoadingPayments = statusLoadingPaymentsConfig === 'loading';
  const isLoadingShipping = statusLoadingShippingConfig === 'loading';

  return (
    <div className="stratus--onboarding-accordion-tasks">
      <Accordion>
        {sortedItems.map(
          ({
            name,
            title,
            ctaText,
            ctaLink,
            completed,
            secondaryCtaLink,
            secondaryCtaText,
            description,
          }) =>
            name === 'store_created' ? (
              <Accordion.Item key={name} index={name}>
                <div className="stratus--store-created-item">
                  <Stack align="stretch" justify="space-between">
                    <Stack spacing="none">
                      <IconBox
                        backgroundColor="success-surfaceHighlight"
                        color="primary-textLow"
                        borderRadius="full"
                        height="24px"
                        width="24px"
                        marginRight="2-5"
                      >
                        <CheckCircleIcon color="currentColor" />
                      </IconBox>
                      <Stack column align="flex-start" spacing="none">
                        <Text fontWeight="medium" color="neutral-textHigh">
                          <s>{title}</s>
                        </Text>
                        <Text fontSize="caption">{description}</Text>
                      </Stack>
                    </Stack>
                    <div className="stratus--store-created-link">
                      <ExternalLink
                        href={isBlocked ? TRANSACTION_FEES_BASE_ROUTE : url}
                        textDecoration="none"
                        appearance="primary"
                        fontSize="caption"
                        onClick={PRINCIPAL_LOG_EVENTS[name]}
                      >
                        {ctaText}
                      </ExternalLink>
                    </div>
                  </Stack>
                </div>
              </Accordion.Item>
            ) : (
              <Accordion.Item key={name} index={name}>
                <Accordion.Header borderTop="base">
                  <Box width="100%" display="flex" alignItems="center">
                    <Box>
                      <IconBox
                        backgroundColor={
                          completed
                            ? 'success-surfaceHighlight'
                            : 'primary-surface'
                        }
                        color={completed ? 'currentColor' : 'primary-textLow'}
                        borderRadius="full"
                        height="24px"
                        width="24px"
                        marginRight="2-5"
                      >
                        {completed ? (
                          <CheckCircleIcon color="currentColor" />
                        ) : (
                          ICONS[name]
                        )}
                      </IconBox>
                    </Box>
                    {completed ? (
                      <Text fontWeight="medium" color="neutral-textHigh">
                        <s>{title}</s>
                      </Text>
                    ) : (
                      <Text fontWeight="medium" color="neutral-textHigh">
                        {title}
                      </Text>
                    )}
                  </Box>
                </Accordion.Header>
                <Accordion.Body padding="none">
                  <Box width="100%" paddingLeft="12" paddingBottom="4">
                    <Text fontSize="caption" textAlign="left">
                      {description}
                    </Text>
                    <Box
                      paddingTop="1-5"
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Box marginRight="4">
                        {name === 'setup_payments' ||
                        name === 'setup_shipping' ? (
                          <SubmitButton
                            appearance="primary"
                            onClick={goToConfig(ctaLink, name)}
                            disabled={
                              name === 'setup_payments'
                                ? isLoadingPayments
                                : isLoadingShipping
                            }
                          >
                            {ctaText}
                          </SubmitButton>
                        ) : (
                          <Button
                            as={LinkRoute}
                            appearance="primary"
                            to={
                              isBlocked ? TRANSACTION_FEES_BASE_ROUTE : ctaLink
                            }
                            onClick={trackEvent(ctaLink, name)}
                          >
                            {ctaText}
                          </Button>
                        )}
                      </Box>
                      {secondaryCtaLink && (
                        <Link
                          fontSize="caption"
                          textDecoration="none"
                          appearance="primary"
                          as="a"
                          target="_blank"
                          href={
                            isBlocked
                              ? TRANSACTION_FEES_BASE_ROUTE
                              : secondaryCtaLink
                          }
                          onClick={SECONDARY_LOG_EVENTS[name]}
                        >
                          {secondaryCtaText}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Accordion.Body>
              </Accordion.Item>
            ),
        )}
      </Accordion>
    </div>
  );
}

OnboardingTaskAccordion.Skeleton = Skeleton;

export default OnboardingTaskAccordion;
