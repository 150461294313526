export const FEATURE_DISCOUNTS = 'discounts';
export const FEATURE_SEARCH_FILTER = 'search_filter_actions';
export const FEATURE_BULK_ACTIONS = 'bulk_actions';
export const FEATURE_MONTHLY_CATALOG_EXPORT = 'monthly_catalog_export';
export const FEATURE_MONTHLY_CLIENTS_EXPORT = 'monthly_clients_export';
export const FEATURE_MONTHLY_ORDERS_EXPORT = 'monthly_orders_export';
export const FEATURE_MAX_CUSTOM_PAYMENTS = 'max_custom_payments';
export const FEATURE_NEW_ADMIN_STATS = 'new_admin_stats';
export const FEATURE_METAFIELDS_ORDERS = 'metafields_orders';
export const FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_CONFIG =
  'metafields_products_variants_admin_config';
export const FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER =
  'metafields_products_variants_admin_filter';
export const FEATURE_METAFIELDS_CUSTOMER = 'metafields_customers';
export const FEATURE_METAFIELDS_CATEGORIES = 'metafields_categories';
export const FEATURE_METAFIELDS_PRODUCTS_ADMIN_CONFIG =
  'metafields_products_admin_config';
export const FEATURE_METAFIELDS_PRODUCTS_ADMIN_FILTER =
  'metafields_products_admin_filter';
export const FEATURE_MAX_LOCATIONS = 'max_locations';
export const FEATURE_STATS = 'stats';
export const FEATURE_ABANDONED_CARTS = 'abandoned_carts';
export const FEATURE_CUSTOMER_ACCOUNTS = 'customer_accounts';
export const FEATURE_MARKETING_CATALOG = 'marketing_catalog';
export const FEATURE_MELI = 'meli';
export const FEATURE_ONLY_SHIPPING_NATIVE = 'only_shipping_native';
export const FEATURE_MAX_SHIPPING_CUSTOMS = 'max_shipping_customs';
export const FEATURE_MAX_SHIPPING_INTERNATIONALS =
  'max_shipping_internationals';
export const FEATURE_ONLY_NUVEM_PAGO = 'only_nuvem_pago';
export const FEATURE_MAX_USERS = 'max_users';
export const FEATURE_USER_PERMISSIONS = 'user_permissions';
export const FEATURE_ALLOW_CUSTOM_DOMAIN = 'allow_custom_domain';
export const FEATURE_CUSTOM_THEME_CAPABLE = 'custom_theme_capable';
export const FEATURE_GOOGLE_TAG_MANAGER = 'google_tag_manager';
export const FEATURE_CS_WHATSAPP = 'cs_whatsapp';
export const FEATURE_CS_PHONE_CALL = 'cs_phone_call';
export const FEATURE_ONLY_LAYOUT_DEFAULT = 'only_layout_default';
export const FEATURE_AI_TIER = 'ai_tier';
export const FEATURE_ONLY_NUVEM_PAGO_PAYMENTS = 'only_nuvem_pago_payment';
export const FEATURE_EDIT_ORDER = 'edit_orders';
export const FEATURE_NUVEM_MARKETING = 'ma_nuvem_marketing';
export const FEATURE_ORDER_PRODUCTS_FILTER = 'order_products_filter';
export const FEATURE_HIDE_CATEGORIES = 'hide_categories';

// Values for tiered features
export const TIER_DISABLED = 'disabled';
export const TIER_FREE = 'tier-free';
export const TIER_1 = 'tier-1';
export const TIER_2 = 'tier-2';
export const TIER_3 = 'tier-3';
export const TIER_TOP = 'tier-top';

//Legacy tiers. Should be removed when all features are migrated to the new tier system
export const TIER_LEGACY_1 = 'tier-legacy-1';
export const TIER_LEGACY_2 = 'tier-legacy-2';
export const TIER_LEGACY_3 = 'tier-legacy-3'; //Just to be hable to compare with the new tiers
export const TIER_LEGACY_TOP = 'tier-legacy-top'; //Just to be hable to compare with the new tiers
