import { ConceptCode } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import {
  CONCEPT_CODE_PAID_QUERY,
  EXTERNAL_REFERENCE_PAID_QUERY,
} from '../../checkoutRoutes';
import PaymentStatusAlert from '../PaymentStatusAlert';

export default function AfterPaymentAlert() {
  const { getQueryParam } = useNavegate();
  const conceptCodePaidSetting = getQueryParam(CONCEPT_CODE_PAID_QUERY) as
    | ConceptCode
    | undefined;
  const externalReferencePaidSetting = getQueryParam(
    EXTERNAL_REFERENCE_PAID_QUERY,
  ) as string | undefined;

  if (!conceptCodePaidSetting) return null;

  return (
    <PaymentStatusAlert
      concept={conceptCodePaidSetting}
      externalReference={externalReferencePaidSetting}
      showSuccessAlert
    />
  );
}
