import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import {
  ProductCreateRequestDto,
  ProductSectionsCodeType,
  SectionCodesResponseDto,
  VariantRequestDto,
} from '@tiendanube/common';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { VariantStockInterface } from 'domains/Catalog/Products/productsSlice';
import { attributeHasValues } from 'domains/Catalog/Products/utils';
import { Variant, Attributes } from '../../components/Variants/types';
import { removeErrorsFromSectionCodes } from '../../EditProductPage/digests';
import { ProductFormState } from '../../hooks/useProductForm';

export function createAttributes(attributes: Attributes): LanguageInterface[] {
  return Object.values(attributes)
    .filter((attribute) => attributeHasValues(attribute))
    .map((attribute) => attribute.name);
}

const SEPARATION = '[SEPARATION_VARIANT_GROUPS]';

export function variantRequestDigest(
  variant: Variant,
  i: number,
  hasShippingMultiCD: boolean,
): VariantRequestDto {
  const promotional_price = variant.pricePublished
    ? Number(variant.promotionalPrice)
    : 0;
  const price = variant.pricePublished ? Number(variant.price) : 0;
  const cost = Number(variant.costPrice);

  const stock =
    variant.stock === null || variant.stock === ''
      ? null
      : Number(variant.stock);

  return {
    image_id: variant.imageId,
    product_id: '',
    position: i + 1,
    price,
    promotional_price,
    cost,
    stock_management: stock !== null,
    stock,
    weight: variant.weight,
    height: variant.height,
    width: variant.width,
    depth: variant.depth,
    sku: variant.sku,
    values: variant.values,
    barcode: variant.barcode,
    mpn: variant.mpn,
    age_group: variant.ageGroup,
    gender: variant.gender,
    locationId: hasShippingMultiCD ? variant.locationId : undefined,
    metafields: variant.metafields,
  };
}

const sectionCodesDigest = (
  sectionCodes: SectionCodesResponseDto | undefined,
): ProductSectionsCodeType[] | undefined => {
  if (!sectionCodes) return undefined;
  if (sectionCodes.includes('get-error')) return undefined;
  return removeErrorsFromSectionCodes(sectionCodes);
};

export function createProductDigest(
  product: Partial<ProductFormState>,
  language: LanguagesType,
  hasShippingMultiCD: boolean,
  hasMedia: boolean,
): ProductCreateRequestDto {
  const emptyLanguage = { [language]: '' };
  const {
    name = emptyLanguage,
    description = emptyLanguage,
    images = [],
    media = [],
    videoUrl,
    attributes,
    isDigital,
    categories,
    freeShipping,
    published = true,
    variants,
    tags,
    brand,
    seoDescription,
    seoTitle,
    seoUrl,
    sectionCodes,
    productMetafields,
  } = product;

  return {
    name,
    description,
    published,
    sectionCodes: sectionCodesDigest(sectionCodes),
    ...(!hasMedia && {
      images: images // to be removed after sunset new-admin-catalog-upload-videos
        .filter((image) => !image.isError)
        .map(({ id, alt }) => ({ image_id: id, alt })),
    }),
    ...(hasMedia && {
      media: media
        .filter((media) => !media.isError)
        .map(({ id, alt, mediaType }) => ({
          id,
          alt,
          media_type: mediaType,
        })),
    }),
    ...(videoUrl && { video_url: videoUrl }),
    ...(attributes && { attributes: createAttributes(attributes) }),
    ...(freeShipping && { free_shipping: freeShipping }),
    ...(isDigital && { requires_shipping: !isDigital }),
    ...(categories && { categories: product.categories }),
    ...(brand && { brand: product.brand }),
    ...(tags && { tags: product.tags?.join(',') }),
    ...(seoDescription && { seo_description: seoDescription }),
    ...(seoTitle && { seo_title: seoTitle }),
    ...(seoUrl && { handle: seoUrl }),
    ...(productMetafields && { productMetafields }),
    ...(variants && {
      variants: variants
        .filter((variant) => variant.show)
        .map((variant, i) =>
          variantRequestDigest(variant, i, hasShippingMultiCD),
        ),
    }),
  };
}

export const cartesian = (actual: Array<string[]>): Array<string[]> => {
  const result = actual.reduce(
    (acc, current) => acc.flatMap((d) => current.map((e) => [d, e].flat())),
    [[]] as Array<string[]>,
  );
  return result;
};

export function createVariantsStructure(
  attributes: Attributes,
  language: string,
  languages: LanguagesType[],
): Record<string, string>[] {
  const attributesArray = Object.values(attributes).filter((attribute) =>
    attributeHasValues(attribute),
  );
  const variantsCartesian = {};
  const newVariants: Record<string, string>[] = [];

  languages.forEach((lang) => {
    variantsCartesian[lang] = cartesian(
      attributesArray.map((att) =>
        att.values
          .filter((value) => !!value)
          .map((option) => option[lang] || ''),
      ),
    );
  });

  for (let i = 0; i < variantsCartesian[language].length; i++) {
    const variant = {};
    languages.forEach((lang) => {
      variant[lang] = variantsCartesian[lang][i].join(SEPARATION);
    });
    newVariants.push(variant);
  }

  return newVariants;
}

export function hasTheSameValues(
  a: Record<string, string>[],
  b: Record<string, string>[],
): boolean {
  return isEqual(a, b);
}

export function getDifferences<T extends object>(
  value: T,
  newValue: T,
): Partial<T> {
  return Object.keys(value).reduce((acc, key) => {
    if (!isEqual(value[key], newValue[key])) {
      return {
        ...acc,
        [key]: newValue[key],
      };
    }
    return acc;
  }, {});
}

export const buildNewVariant = (
  newVariantsKeys: Record<string, string>[],
  variants: Variant[],
  language: LanguagesType,
): Variant[] =>
  newVariantsKeys.map((variantKey) => {
    const found = variants.find((variant) => {
      const variantValues = Object.keys(variantKey).map((lang) =>
        variant.values.map((v) => v[lang]).join(SEPARATION),
      );

      return isEqual(variantValues, Object.values(variantKey));
    });

    if (found) {
      return found;
    }

    const variantValues: LanguageInterface[] = [];

    const variantValuesQuant = variantKey[language].split(SEPARATION).length;

    for (let i = 0; i < variantValuesQuant; i++) {
      const variantValue: LanguageInterface = {};
      Object.keys(variantKey).forEach((lang) => {
        variantValue[lang] = variantKey[lang].split(SEPARATION)[i];
      });
      variantValues.push(variantValue);
    }

    const name = variantKey[language].replaceAll(SEPARATION, ' ');

    return {
      ...variants[0],
      id: '',
      name,
      values: variantValues,
      stock: null,
      isInfinite: true,
      show: true,
    };
  });

export const getCurrentVariants = (
  variants: Variant[],
): Record<string, string>[] => {
  const variantsValues: Record<string, string>[] = [];

  variants.forEach((variant) => {
    const variantValues = {};
    variant.values.forEach((variantValue) => {
      Object.keys(variantValue).forEach((lang) => {
        variantValues[lang] = variant.values
          .map((value) => value[lang])
          .join(SEPARATION);
      });
    });
    variantsValues.push(variantValues);
  });

  return variantsValues;
};

export const getVariantEdited = (
  variants: Variant[],
  index: number,
  newValue: Partial<Variant>,
): Variant[] =>
  variants.map((variant, i) => {
    if (i !== index) return variant;

    if ('isInfinite' in newValue) {
      return {
        ...variant,
        ...newValue,
        stock: newValue.isInfinite ? null : '0',
      };
    }
    return {
      ...variant,
      ...newValue,
    };
  });

export const getUpdatedStockValue = (
  variantsStock: VariantStockInterface,
  variant: Variant,
) => {
  if (variantsStock?.[variant.id] === undefined) return variant.stock;
  if (variantsStock?.[variant.id] === null) return null;
  return String(variantsStock[variant.id]);
};

export const getIsFormDirty = (
  initialValues: ProductFormState,
  currentValues: ProductFormState,
) => {
  if (
    currentValues.variants[0]?.isInfinite !==
    initialValues.variants[0]?.isInfinite
  ) {
    return true;
  }
  const initialValuesWithoutVariants = omit(initialValues, 'variants');
  const currentValuesWithoutVariants = omit(currentValues, 'variants');

  // Exclude stock from variants because are updated in separated popUp and produces false positives
  const initialValuesVariantsWithoutStock = initialValues.variants.map((e) =>
    omit(e, 'stock'),
  );
  const currentValuesVariantsWithoutStock = currentValues.variants.map((e) =>
    omit(e, 'stock'),
  );

  return (
    !isEqual(initialValuesWithoutVariants, currentValuesWithoutVariants) ||
    !isEqual(
      initialValuesVariantsWithoutStock,
      currentValuesVariantsWithoutStock,
    )
  );
};
