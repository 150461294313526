import {
  MetafieldItemV2Dto,
  OwnerResourceType as OwnerResourceTypeCommon,
} from '@tiendanube/common';

export interface MetafieldInterface extends MetafieldItemV2Dto {
  id: string;
}

export interface MetafieldSelectedInterface {
  id: string;
  value: string | null;
}

export type OnChangeMetafieldType = (
  id: string,
  value: string,
  source: SourceType,
  resource?: OwnerResourceTypeCommon,
  name?: string,
) => void;

export type OwnerResourceType = 'order' | 'variant' | 'customer';

export enum SourceType {
  ADMIN = 'admin',
  APP = 'app',
}
