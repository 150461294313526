import { PlusCircleIcon } from '@nimbus-ds/icons';
import FeatureFlag from 'App/components/FeatureFlag';
import { FEATURE_MAX_USERS } from 'App/features';
import { REVAMP_MENU_V1, EMAIL_TYPES_CONFIGURATION } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
} from 'commons/components';
import { HELP_LINKS_USERS_PERMISSIONS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { USERS_CREATE_USER } from 'config/upsellFlowSources';
import { accountRoutes } from 'domains/Account';
import { useGetTags } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { UsersListDesktop, UsersListMobile } from './components';
import { useGetUsersQuantity } from './hooks';

function UsersListPage() {
  const t = useTranslationConfigurations('usersListPage');
  const language = useTranslationLanguage();
  const { goTo } = useNavegate();
  const usersQuantity = useGetUsersQuantity();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const goToCreateUser = useUpsellFlow({
    title: t('upsellTitle'),
    featureKey: FEATURE_MAX_USERS,
    minValue: usersQuantity + 1,
    trackingSource: USERS_CREATE_USER,
    callback: () =>
      goTo(getUrlWithParams(accountRoutes.userForm, { id: 'new' })),
  });

  const mainAction = {
    appearance: 'primary' as const,
    icon: PlusCircleIcon,
    onClick: goToCreateUser,
    children: t('addUser'),
  };

  const headerTop = <HeaderTop mainAction={mainAction} />;
  const actionsDesktop = <ActionsDesktop actions={[mainAction]} />;

  return (
    <IonPageStratus
      width="large"
      headerTop={headerTop}
      headerContent={
        <FeatureFlag
          flag={EMAIL_TYPES_CONFIGURATION}
          renderElse={
            <HeaderContent
              title={t(isRevampMenu ? 'revampTitle' : 'title')}
              actions={actionsDesktop}
            />
          }
        >
          <HeaderContent
            title={t('titleWithNotifications')}
            actions={actionsDesktop}
          />
        </FeatureFlag>
      }
    >
      <Responsive mobile={<UsersListMobile />} desktop={<UsersListDesktop />} />
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_USERS_PERMISSIONS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default UsersListPage;
