import { Box, Button, Text, Title } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { Trans, useTranslation } from 'react-i18next';
import { useResponsive } from 'commons/components';
import { NUVEM_CHAT_APP_INSTALL_LINK } from 'commons/constants/externalLinks';
import { useTranslationLanguage } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { Divider } from './components/Divider';
import NuvemChatImageLanding from './images/nuvem-chat-image-landing.png';
import NuvemChatImagePt from './images/nuvem-chat-image-pt.png';
import NuvemChatImageSmPt from './images/nuvem-chat-image-sm-pt.png';
import NuvemChatImageSm from './images/nuvem-chat-image-sm.png';
import NuvemChatImage from './images/nuvem-chat-image.png';
import { trackingNuvemChatInstallClick } from '../tracking';
import './NuvemChatPage.scss';

export function NuvemChatPage() {
  const { t } = useTranslation('nuvemchat');
  const language = useTranslationLanguage();
  const { isMobile } = useResponsive();

  const handleInstallApplicationClick = () => {
    const url = NUVEM_CHAT_APP_INSTALL_LINK[language];
    trackingNuvemChatInstallClick();
    openWindow(url, true);
  };

  const chatView = language === 'pt-BR' ? NuvemChatImagePt : NuvemChatImage;
  const chatViewSm =
    language === 'pt-BR' ? NuvemChatImageSmPt : NuvemChatImageSm;
  return (
    <div className="stratus--nuvem-chat-page">
      <div className="stratus--nuvem-chat-page__hero">
        <div className="stratus--nuvem-chat-page__wrapper">
          <Layout columns="2 - symmetric">
            <Layout.Section justifyContent="center" alignItems="center">
              <Box maxWidth="360px">
                <img src={NuvemChatImageLanding} alt="NuvemChat" />
              </Box>
            </Layout.Section>
            <Layout.Section justifyContent="center" alignItems="flex-start">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="2-5"
              >
                <Text
                  fontSize="base"
                  color="neutral-textLow"
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  {t('hero.subtitle').toUpperCase()}
                </Text>
                <Title as="h1" fontWeight="medium" textAlign="left">
                  {t('hero.title')}
                </Title>
                <Text
                  fontSize="highlight"
                  color="neutral-textHigh"
                  textAlign="left"
                >
                  <Trans
                    t={t}
                    i18nKey="hero.description"
                    components={{ bold: <strong /> }}
                  />
                </Text>
              </Box>
              <Box paddingY="2">
                <Button
                  onClick={handleInstallApplicationClick}
                  appearance="primary"
                >
                  {t('hero.button')}
                </Button>
              </Box>
            </Layout.Section>
          </Layout>
        </div>
      </div>
      <div className="stratus--nuvem-chat-page__content">
        <Box
          display="flex"
          flexDirection="row"
          gap="2"
          justifyContent="flex-start"
          marginLeft="14"
        >
          <Title as="h2" fontWeight="medium" textAlign="left">
            {t('contentSection.title')}
          </Title>
        </Box>
        <div className="stratus--nuvem-chat-page__wrapper">
          <Layout columns="2 - symmetric">
            <>
              {isMobile && (
                <Layout.Section
                  justifyContent="center"
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                >
                  <Box
                    maxWidth="500px"
                    borderRadius="6"
                    borderColor="primary-interactive"
                    borderWidth="4"
                    borderStyle="solid"
                  >
                    <img
                      src={chatViewSm}
                      alt="NuvemChatView"
                      style={{ borderRadius: '20px' }}
                    />
                  </Box>
                </Layout.Section>
              )}
              <Layout.Section justifyContent="center" alignItems="flex-start">
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h4" fontWeight="medium">
                    {t('contentSection.content1.title')}
                  </Title>
                  <Text>{t('contentSection.content1.description')}</Text>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h4" fontWeight="medium">
                    {t('contentSection.content2.title')}
                  </Title>
                  <Text>{t('contentSection.content2.description')}</Text>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h4" fontWeight="medium">
                    {t('contentSection.content3.title')}
                  </Title>
                  <Text>{t('contentSection.content3.description')}</Text>
                </Box>
                <Box paddingY="2">
                  <Button
                    onClick={handleInstallApplicationClick}
                    appearance="primary"
                  >
                    {t('contentSection.button')}
                  </Button>
                </Box>
              </Layout.Section>
              {!isMobile && (
                <Layout.Section
                  justifyContent="center"
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                >
                  <Box
                    maxWidth="600px"
                    maxHeight="500px"
                    borderRadius="6"
                    borderColor="primary-interactive"
                    borderWidth="4"
                    borderStyle="solid"
                  >
                    <img
                      src={chatView}
                      alt="NuvemChatView"
                      style={{ borderRadius: '20px' }}
                    />
                  </Box>
                </Layout.Section>
              )}
            </>
          </Layout>
        </div>
      </div>
    </div>
  );
}
