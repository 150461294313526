import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import { Dates } from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { DiscountCombination } from './DiscountCombination';
import { PromotionFormProps } from '../PromotionForm';

export function LimitsCard({
  values,
  errors,
  onChange,
}: Readonly<Pick<PromotionFormProps, 'values' | 'errors' | 'onChange'>>) {
  const t = useTranslationMarketing('promotions.settingsPage.limits');
  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('title')}</Title>
            <Text>{t('optional')}</Text>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="3" marginBottom="1">
          <Text>{t('info')}</Text>
          <DiscountCombination values={values} onChange={onChange} />
          <Dates values={values} errors={errors} onChange={onChange} />
        </Box>
      </Card.Body>
    </Card>
  );
}

export default LimitsCard;
