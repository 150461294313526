import { ChangeEvent } from 'react';
import { Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import { RocketIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { CreateUserRequestDto, RoleResponseDto } from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { FEATURE_USER_PERMISSIONS } from 'App/features';
import { EMAIL_TYPES_CONFIGURATION } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { Stack } from 'commons/components';
import CardCollapsible from 'commons/components/CardCollapsible';
import { USERS_MANAGE_PERMISSIONS } from 'config/upsellFlowSources';
import {
  useGetCountry,
  useGetPlan,
  useStatusFeatureByKey,
} from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { UserRolesType } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { AppsSectionRoles, InfoTooltip } from './components';
import './RolesCard.scss';

interface RolesCardProps {
  roles: UserRolesType;
  values: CreateUserRequestDto;
  errors: Partial<Record<string, string>>;
  onChangeRole: (name: string, value: string[]) => void;
}

function RolesCard({
  roles,
  values,
  errors,
  onChangeRole,
}: Readonly<RolesCardProps>) {
  const country = useGetCountry();
  const { hasTransactionFeesForMx } = useGetPlan();
  const t = useTranslationConfigurations('userFormPage');
  const { isAvailable: isRolesEditAvailable } = useStatusFeatureByKey(
    FEATURE_USER_PERMISSIONS,
  );

  const handleUpsellCallout = useUpsellFlow({
    title: t('rolesCard.upsellTitle'),
    featureKey: FEATURE_USER_PERMISSIONS,
    trackingSource: USERS_MANAGE_PERMISSIONS,
  });

  const roleSectionsKeys = Object.keys(roles.sections);
  const roleFull = roles.sections.noSection[0];

  const roleSectionsKeysWithoutFullAndApps = roleSectionsKeys.filter((role) => {
    if (['noSection', 'externalApps', 'apps'].includes(role)) return false;
    return roles.sections[role].length > 0;
  });
  const externalAppsRoles = roles.sections.externalApps;
  const appsRoles = roles.sections.apps;
  const appRolesIds = Object.keys(roles.appRoles);
  const roleIds = Object.keys(roles.roles);

  const handleChangeFullRole = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeRole('roles', event.target.checked ? roleIds : []);
    onChangeRole('appRoles', event.target.checked ? appRolesIds : []);
  };

  const handleChangeRole = (
    event: ChangeEvent<HTMLInputElement>,
    role: RoleResponseDto,
  ) => {
    const roleId = event.target.name;

    let newRoles = values.roles;
    if (event.target.checked) {
      newRoles = [...newRoles, roleId];
    } else {
      const filterRoles =
        role.children && role.children.length > 0
          ? [roleId, ...role.children.map((child) => child.id)]
          : [roleId];
      newRoles = newRoles.filter((roleName) => !filterRoles.includes(roleName));
    }

    callChangeRoles(newRoles);
  };

  const handleChangeChildRole = (
    event: ChangeEvent<HTMLInputElement>,
    parentRole: string,
  ) => {
    const permission = event.target.name;
    let newRoles = values.roles;
    if (event.target.checked) {
      const add = newRoles.includes(parentRole)
        ? [permission]
        : [parentRole, permission];
      newRoles = [...newRoles, ...add];
    } else {
      newRoles = newRoles.filter((role) => role !== permission);
    }

    callChangeRoles(newRoles);
  };

  const callChangeRoles = (newRoles: string[]) => {
    if (newRoles.includes('full') && newRoles.length < roleIds.length)
      newRoles.splice(newRoles.indexOf('full'), 1);

    onChangeRole('roles', newRoles);
  };

  const handleChangeAppRole = (event: ChangeEvent<HTMLInputElement>) => {
    const newAppRoles = event.target.checked
      ? [...values.appRoles, event.target.name]
      : values.appRoles.filter((app) => app !== event.target.name);

    onChangeRole('appRoles', newAppRoles);
  };

  const getPaymentsTooltipText = () => {
    if (
      ![Country.CL, Country.CO].includes(country) &&
      (country !== Country.MX || hasTransactionFeesForMx)
    ) {
      return t('rolesCard.paymentsTooltip');
    }
    return t('rolesCard.paymentsTooltipWithoutCpt');
  };

  const [emailPreferencesConfiguration] = useHasTags(EMAIL_TYPES_CONFIGURATION);

  return !emailPreferencesConfiguration ? (
    <Card>
      <Card.Header>
        <Title as="h3">{t('rolesCard.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="baseline">
          {!isRolesEditAvailable && (
            <CalloutCard
              title={t('rolesCard.upgradePlan')}
              appearance="primary"
              icon={RocketIcon}
              subtitle={t('rolesCard.upgradePlanSubtitle')}
              onClick={handleUpsellCallout}
            />
          )}
          <Stack spacing="tight" align="center">
            <Checkbox
              label={roleFull.shortName}
              name="full"
              checked={values.roles.includes('full')}
              disabled={!isRolesEditAvailable}
              onChange={handleChangeFullRole}
            />
            <InfoTooltip content={t('rolesCard.fullTooltip')} />
          </Stack>
          {roleSectionsKeysWithoutFullAndApps.map((section) => (
            <>
              <Text
                fontWeight="bold"
                fontSize="highlight"
                color="neutral-textHigh"
              >
                {t(`rolesCard.sections.${section}`)}
              </Text>
              {roles.sections[section].map((role: RoleResponseDto) => (
                <Stack column key={`role-${role.id}`} align="baseline">
                  <Stack spacing="tight" align="center">
                    <Checkbox
                      label={role.shortName}
                      name={role.id}
                      checked={values.roles.includes(role.id)}
                      disabled={!isRolesEditAvailable}
                      onChange={(e) => handleChangeRole(e, role)}
                    />
                    {role.id === 'payments_and_subscriptions' && (
                      <InfoTooltip content={getPaymentsTooltipText()} />
                    )}
                  </Stack>
                  {role.children && role.children.length > 0 && (
                    <div className="stratus--role-children-list">
                      <Stack column align="baseline">
                        {role.children.map((child) => (
                          <Checkbox
                            key={`permission-${child.id}`}
                            label={child.name}
                            name={child.id}
                            checked={values.roles.includes(child.id)}
                            disabled={!isRolesEditAvailable}
                            onChange={(e) => handleChangeChildRole(e, role.id)}
                          />
                        ))}
                      </Stack>
                    </div>
                  )}
                </Stack>
              ))}
            </>
          ))}
          <AppsSectionRoles
            appsRoles={appsRoles}
            externalAppsRoles={externalAppsRoles}
            values={values}
            isRolesEditAvailable={isRolesEditAvailable}
            onChangeRole={handleChangeRole}
            onChangeAppRole={handleChangeAppRole}
          />
          {!!errors.roles && (
            <Text color="danger-textLow" fontWeight="bold">
              {t(`errors.${errors.roles}`)}
            </Text>
          )}
        </Stack>
      </Card.Body>
    </Card>
  ) : (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h3">{t('rolesCard.title')}</Title>
        </Stack>
      }
      active={!!errors.roles}
      cursorEnable={emailPreferencesConfiguration}
    >
      <Stack>
        <Stack.Item fill>
          <Stack column align="baseline">
            {!!errors.roles && (
              <Text color="danger-textLow" fontWeight="bold">
                {t(`errors.${errors.roles}`)}
              </Text>
            )}
            {!isRolesEditAvailable && (
              <CalloutCard
                title={t('rolesCard.upgradePlan')}
                appearance="primary"
                icon={RocketIcon}
                subtitle={t('rolesCard.upgradePlanSubtitle')}
                onClick={handleUpsellCallout}
              />
            )}
            <Stack spacing="tight" align="center">
              <Checkbox
                label={roleFull.shortName}
                name="full"
                checked={values.roles.includes('full')}
                disabled={!isRolesEditAvailable}
                onChange={handleChangeFullRole}
              />
              <InfoTooltip content={t('rolesCard.fullTooltip')} />
            </Stack>
            {roleSectionsKeysWithoutFullAndApps.map((section) => (
              <>
                <Text
                  fontWeight="bold"
                  fontSize="highlight"
                  color="neutral-textHigh"
                >
                  {t(`rolesCard.sections.${section}`)}
                </Text>
                {roles.sections[section].map((role: RoleResponseDto) => (
                  <Stack column key={`role-${role.id}`} align="baseline">
                    <Stack spacing="tight" align="center">
                      <Checkbox
                        label={role.shortName}
                        name={role.id}
                        checked={values.roles.includes(role.id)}
                        disabled={!isRolesEditAvailable}
                        onChange={(e) => handleChangeRole(e, role)}
                      />
                      {role.id === 'payments_and_subscriptions' && (
                        <InfoTooltip content={getPaymentsTooltipText()} />
                      )}
                    </Stack>
                    {role.children && role.children.length > 0 && (
                      <div className="stratus--role-children-list">
                        <Stack column align="baseline">
                          {role.children.map((child) => (
                            <Checkbox
                              key={`permission-${child.id}`}
                              label={child.name}
                              name={child.id}
                              checked={values.roles.includes(child.id)}
                              disabled={!isRolesEditAvailable}
                              onChange={(e) =>
                                handleChangeChildRole(e, role.id)
                              }
                            />
                          ))}
                        </Stack>
                      </div>
                    )}
                  </Stack>
                ))}
              </>
            ))}
            <AppsSectionRoles
              appsRoles={appsRoles}
              externalAppsRoles={externalAppsRoles}
              values={values}
              isRolesEditAvailable={isRolesEditAvailable}
              onChangeRole={handleChangeRole}
              onChangeAppRole={handleChangeAppRole}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </CardCollapsible>
  );
}

export default RolesCard;
