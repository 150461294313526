import { FulfillmentOrderLineItemForDocumentResponseDto } from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface ProductsDocumentPreviewProps {
  lineItems: FulfillmentOrderLineItemForDocumentResponseDto[];
  showPrices?: boolean;
}

function ProductsDocumentPreview({
  lineItems,
  showPrices = true,
}: Readonly<ProductsDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general.lineItems');
  return (
    <div className="products">
      <div className="product-row">
        <div className="product-row-info">
          <p className="bold">{t('name')}</p>
          {showPrices ? (
            <p className="bold">{t('price')}</p>
          ) : (
            <p className="bold">{t('quantity')}</p>
          )}
        </div>
      </div>
      {lineItems.map(
        ({ name, quantity, totalPrice, unitPrice, sku, properties }) => (
          <div key={name} className="product-row">
            <div className="product-row-info">
              <div className="product-name">
                <p>{name}</p>
                {!!sku && (
                  <p className="product-sku">
                    {t('sku')} {sku}
                  </p>
                )}
              </div>
              {}
              <p className="product-quantity">
                {quantity} {showPrices ? `x ${unitPrice}` : ''}
              </p>
              {showPrices && <p className="product-total bold">{totalPrice}</p>}
            </div>
            {properties?.map(({ key, value }) => (
              <p key={sku + key} className="product-property">
                {key}: {value}
              </p>
            ))}
          </div>
        ),
      )}
    </div>
  );
}

export default ProductsDocumentPreview;
