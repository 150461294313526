import { useEffect, useState } from 'react';
import { Alert, Box, Card, Text, Title } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from '@tiendanube/components';
import { InputNumberNimbus } from 'commons/components';
import { Products } from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  CROSS_SELLING_DEFAULT_REWARD_VALUES,
  CROSS_SELLING_DEFAULT_TRIGGER_VALUES,
} from 'domains/Marketing/Promotions/pages/constants';
import { PromotionFormProps } from '../PromotionForm';

export default function RewardsCard({
  values,
  errors,
  onChangeRewards,
}: Readonly<
  Pick<PromotionFormProps, 'values' | 'errors' | 'onChangeRewards'>
>) {
  const t = useTranslationMarketing('promotions.settingsPage.crossSelling');

  const initialReward = values.rewards?.[0];
  const [selectedProducts, setSelectedProducts] = useState(
    initialReward?.scope_value || [],
  );
  const [discountValue, setDiscountValue] = useState<number | ''>(
    initialReward?.discount_value ?? '',
  );

  const scopeValueError = errors['rewards[0].scope_value'] || '';
  const discountValueError = errors['rewards[0].discount_value'] || '';

  useEffect(() => {
    onChangeRewards([
      {
        ...(initialReward?.id && { id: initialReward.id }),
        ...CROSS_SELLING_DEFAULT_REWARD_VALUES,
        scope_value: selectedProducts,
        discount_value: Number(discountValue),
        triggers: initialReward?.triggers?.map((trigger) => ({
          ...(trigger?.id && { id: trigger.id }),
          ...trigger,
          ...CROSS_SELLING_DEFAULT_TRIGGER_VALUES,
        })) ?? [{ ...CROSS_SELLING_DEFAULT_TRIGGER_VALUES }],
      },
    ]);
  }, [selectedProducts, discountValue, initialReward, onChangeRewards]);

  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('title')}</Title>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="6" marginBottom="1">
          <Box display="flex" flexDirection="column" gap="2">
            <Text>{t('info')}</Text>
            <Products
              products={selectedProducts}
              title={t('products.title')}
              showTitle={false}
              subtitle={t('products.subtitle')}
              emptyTitle={t('products.empty.title')}
              emptyText={t('products.empty.text')}
              ctaText={t('products.empty.ctaText')}
              selectLinkText={t('products.selector.select')}
              editLinkText={t('products.selector.edit')}
              limit={1}
              requiredText=""
              onChange={setSelectedProducts}
            />
            {!!scopeValueError && (
              <Box marginY="1">
                <Alert appearance="warning">{t(scopeValueError)}</Alert>
              </Box>
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap="2">
            <Title as="h6">{t('discount.title')}</Title>
            <FormField
              label={t('discount.text')}
              id="discount_value"
              helpText={discountValueError ? t(discountValueError) : ''}
              helpIcon={
                discountValueError ? ExclamationTriangleIcon : undefined
              }
              showHelpText={!!discountValueError}
              appearance={discountValueError ? 'danger' : undefined}
            >
              <InputNumberNimbus
                type="float"
                min="0"
                name="discount_value"
                id="discount_value"
                append="%"
                appendPosition="end"
                value={discountValue}
                onChange={(e) =>
                  setDiscountValue(e.target.value ? +e.target.value : '')
                }
                appearance={discountValueError ? 'danger' : undefined}
              />
            </FormField>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
