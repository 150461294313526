import { Redirect, Route } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import BillingPlansPage from './pages/BillingPlansPage';
import { BILLING_ROUTES } from '../billingRoutes';

function BillingPlans(): JSX.Element {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  return (
    <Route exact path={BILLING_ROUTES.plans}>
      {isRevampMenu ? (
        <Redirect to={CONFIGURATIONS_ROUTES.plans} />
      ) : (
        <BillingPlansPage />
      )}
    </Route>
  );
}

export default BillingPlans;
