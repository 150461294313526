import isEqual from 'lodash.isequal';
import {
  i18nResponseDto,
  ProductSectionsCodeType,
  PutRelatedProductsRequestDto,
  SectionCodesErrorsType,
  SectionCodesResponseDto,
  VariantResponseDto,
} from '@tiendanube/common';
import { UpdateProductParamsInterface } from 'domains/Catalog/Products/productsServices';
import { attributeHasValues } from 'domains/Catalog/Products/utils';
import { Attributes, Variant } from '../../../components/Variants/types';
import {
  ProductFormState,
  RelatedProductInterface,
} from '../../../hooks/useProductForm';

function variantsDigest(
  variants: Variant[],
  initialVariants: Variant[],
  product_id: string,
  hasShippingMultiCD: boolean,
): VariantResponseDto[] {
  const hiddenVariantsCount = variants.filter((v) => !v.show).length;

  // all variants are hidden
  if (hiddenVariantsCount === variants.length) {
    const variant = variants[0];
    const price = variant.pricePublished ? Number(variant.price) : null;
    const promotional_price = variant.pricePublished
      ? Number(variant.promotionalPrice)
      : null;
    const cost = Number(variant.costPrice);
    const initialVariant = initialVariants.find(({ id }) => id === variant.id);

    // only send stock update if it was set to infinite (null) or finite (0)
    const stockHasChanged =
      !!initialVariant &&
      variant.stock !== initialVariant.stock &&
      (variant.stock === null || variant.stock === '0');

    const requiredUpdateInventoryLevel = !isEqual(
      variant.inventory_levels,
      initialVariant?.inventory_levels,
    );

    return [
      {
        id: '',
        image_id: '',
        product_id,
        position: 1,
        price,
        promotional_price,
        cost,
        ...(stockHasChanged && {
          stock_management: variant.stock !== null,
          stock: variant.stock !== null ? Number(variant.stock) : null,
        }),
        weight: variant.weight,
        height: variant.height,
        width: variant.width,
        depth: variant.depth,
        sku: variant.sku,
        values: [{ es: '', pt: '', en: '' }],
        barcode: variant.barcode,
        mpn: variant.mpn,
        age_group: variant.ageGroup,
        gender: variant.gender,
        locationId: hasShippingMultiCD ? variant.locationId : undefined,
        metafields: variant.metafields,
        inventory_levels:
          requiredUpdateInventoryLevel &&
          hasShippingMultiCD &&
          variant.inventory_levels?.length !== 0
            ? variant.inventory_levels
            : undefined,
      },
    ];
  }

  return variants
    .filter((variant) => variant.show)
    .map((variant, i) => {
      const price = variant.pricePublished ? Number(variant.price) : null;
      const promotional_price = variant.pricePublished
        ? Number(variant.promotionalPrice)
        : null;
      const cost = Number(variant.costPrice);

      const initialVariant = initialVariants.find(
        ({ id }) => id === variant.id,
      );

      // only send stock update if it was set to infinite (null) or finite (0)
      const stockHasChanged =
        !!initialVariant &&
        variant.stock !== initialVariant.stock &&
        (variant.stock === null || variant.stock === '0');

      const isNewVariant = !initialVariant;
      const requiredUpdateStock = isNewVariant || stockHasChanged;

      const requiredUpdateInventoryLevel = !isEqual(
        variant.inventory_levels,
        initialVariant?.inventory_levels,
      );

      return {
        id: variant.id,
        image_id: variant.imageId,
        product_id,
        position: i + 1,
        price,
        promotional_price,
        cost,
        ...(requiredUpdateStock && {
          stock_management: variant.stock !== null,
          stock: variant.stock !== null ? Number(variant.stock) : null,
        }),
        weight: variant.weight,
        height: variant.height,
        width: variant.width,
        depth: variant.depth,
        sku: variant.sku,
        values: variant.values,
        barcode: variant.barcode,
        mpn: variant.mpn,
        age_group: variant.ageGroup,
        gender: variant.gender,
        locationId: hasShippingMultiCD ? variant.locationId : undefined,
        metafields: variant.metafields,
        inventory_levels:
          requiredUpdateInventoryLevel &&
          hasShippingMultiCD &&
          variant.inventory_levels?.length !== 0
            ? variant.inventory_levels
            : undefined,
      };
    });
}

function attributesDigest(attributes: Attributes): i18nResponseDto[] {
  return Object.values(attributes)
    .filter((attribute) => attributeHasValues(attribute))
    .map((attribute) => attribute.name);
}

export const removeErrorsFromSectionCodes = (
  sectionCodes: SectionCodesResponseDto,
): ProductSectionsCodeType[] =>
  sectionCodes.filter(
    (code) => code !== 'get-error' && code !== 'update-error',
  ) as ProductSectionsCodeType[];

export const getErrorsFromSectionCodes = (
  sectionCodes: SectionCodesResponseDto,
): SectionCodesErrorsType[] =>
  sectionCodes.filter(
    (code) => code === 'get-error' || code === 'update-error',
  ) as SectionCodesErrorsType[];

const sectionCodesDigest = (
  sectionCodes: SectionCodesResponseDto | undefined,
  initialSectionCodes: SectionCodesResponseDto,
): ProductSectionsCodeType[] | undefined => {
  if (!sectionCodes) return undefined;
  if (sectionCodes.includes('get-error')) return undefined;
  else if (!isEqual(sectionCodes, initialSectionCodes)) {
    return removeErrorsFromSectionCodes(sectionCodes);
  }
  return undefined;
};

function relatedProductDigest(
  relatedProducts?: RelatedProductInterface,
): PutRelatedProductsRequestDto[] {
  const data: PutRelatedProductsRequestDto[] = [];

  data.push({
    type: 'COMPLEMENTARY',
    related_product_ids:
      relatedProducts?.complementary?.map((product) => Number(product.id)) ||
      [],
  });

  data.push({
    type: 'ALTERNATIVE',
    related_product_ids:
      relatedProducts?.alternative?.map((product) => Number(product.id)) || [],
  });

  return data;
}

export function updateProductDigest(
  updateProduct: Partial<ProductFormState>,
  initialProduct: ProductFormState,
  hasShippingMultiCD: boolean,
  hasMedia: boolean,
): UpdateProductParamsInterface {
  const id = initialProduct.id as string;
  const {
    name,
    description,
    images,
    media,
    videoUrl,
    categories,
    freeShipping,
    published,
    isDigital,
    attributes,
    variants,
    seoTitle,
    seoDescription,
    tags,
    brand,
    seoUrl,
    sectionCodes,
    productMetafields,
    relatedProducts,
  } = updateProduct;
  const descriptionUpdate = description ?? initialProduct.description;
  const publishedUpdate = published ?? initialProduct.published;
  const attributesUpdate = attributes
    ? attributesDigest(attributes)
    : attributesDigest(initialProduct.attributes);

  const hiddenVariantsCount = variants?.filter((v) => !v.show).length ?? 0;
  const areAllVariantsHidden = hiddenVariantsCount === variants?.length;

  return {
    id,
    product: {
      published: publishedUpdate,
      attributes: areAllVariantsHidden ? [] : attributesUpdate,
      description: descriptionUpdate,
      sectionCodes: sectionCodesDigest(
        sectionCodes,
        initialProduct.sectionCodes,
      ),
      ...('relatedProducts' in updateProduct && {
        relatedProducts: relatedProductDigest(relatedProducts),
      }),
      ...('name' in updateProduct && { name }),
      ...('description' in updateProduct && { description }),
      ...(images &&
        !hasMedia && {
          images: images
            .filter((image) => !image.isError)
            .map(({ id, alt }) => ({ image_id: id, alt })),
        }),
      ...(media &&
        hasMedia && {
          media: media
            .filter((image) => !image.isError)
            .map(({ id, alt, mediaType }) => ({
              id,
              alt,
              media_type: mediaType,
            })),
        }),
      ...('videoUrl' in updateProduct && { video_url: videoUrl }),
      ...('categories' in updateProduct && { categories }),
      ...('freeShipping' in updateProduct && { free_shipping: freeShipping }),
      ...('isDigital' in updateProduct && { requires_shipping: !isDigital }),
      ...(variants && {
        variants: variantsDigest(
          variants,
          initialProduct.variants,
          id,
          hasShippingMultiCD,
        ),
      }),
      ...('brand' in updateProduct && { brand: brand }),
      ...('tags' in updateProduct && { tags: tags?.join(', ') }),
      ...('seoDescription' in updateProduct && {
        seo_description: seoDescription,
      }),
      ...('seoTitle' in updateProduct && { seo_title: seoTitle }),
      ...('seoUrl' in updateProduct && { handle: seoUrl }),
      ...('productMetafields' in updateProduct && { productMetafields }),
    },
  };
}
