import Status from '@tiendanube/common/src/enums/Status';

function serializeMultipleOptions(filterOptions: Status[]): string {
  return filterOptions.join(',');
}

export const defaultSavedSearches = [
  {
    code: 'paymentPending',
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: serializeMultipleOptions([
        Status.PENDING,
        Status.VOIDED,
        Status.PARTIALLY_PAID,
      ]),
    },
  },
  {
    code: 'readyToPack',
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: [Status.PAID, Status.PARTIALLY_PAID].join(','),
      fulfillmentStatus: serializeMultipleOptions([
        Status.UNPACKED,
        Status.PARTIALLY_PACKED,
      ]),
    },
  },
  {
    code: 'readyToSend',
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: serializeMultipleOptions([
        Status.UNFULFILLED,
        Status.PARTIALLY_FULFILLED,
      ]),
    },
  },
  {
    code: 'readyToPickup',
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: Status.PICKUP_READY,
    },
  },
  {
    code: 'readyToArchive',
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: serializeMultipleOptions([
        Status.FULFILLED,
        Status.DELIVERED,
      ]),
    },
  },
];
