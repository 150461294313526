import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ShowByRole from 'App/components/ShowByRole';
import { EMAIL_TYPES_CONFIGURATION } from 'App/featuresFlags';
import { useHandleMenuClick, useHasTags } from 'App/hooks';
import { accountRoutes } from 'domains/Account';
import { trackingMenuSecondaryUsersPermissionsClick } from '../tracking';

export function UsersMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  const handleClickNewAdmin = () => {
    trackingMenuSecondaryUsersPermissionsClick();
    handleClickItem();
  };

  const [emailPreferencesConfiguration] = useHasTags(EMAIL_TYPES_CONFIGURATION);

  const label = emailPreferencesConfiguration
    ? t('settingsMenu.general.grants_and_notifications')
    : t('settingsMenu.general.grants');

  return (
    <ShowByRole includeRoles={['full']}>
      <MenuButton
        as={Link}
        to={accountRoutes.users}
        onClick={handleClickNewAdmin}
        active={pathname.startsWith(accountRoutes.users)}
        label={label}
      />
    </ShowByRole>
  );
}
