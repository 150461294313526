import { Weekday } from '@capacitor/local-notifications';
import { BusinessHoursResponseDto } from '@tiendanube/common';
import { GroupedBusinessHours } from './PickupPointItem';

export const getWeekdayValue = (day: string): number => {
  const normalizedDay =
    day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
  return Weekday[normalizedDay as keyof typeof Weekday];
};

export const groupBusinessHours = (
  businessHours: BusinessHoursResponseDto[],
): GroupedBusinessHours[] => {
  const sortedBusinessHours = [...businessHours].sort(
    (a, b) => getWeekdayValue(a.day) - getWeekdayValue(b.day),
  );

  const groupedHours: GroupedBusinessHours[] = [];

  for (const { day, start, end } of sortedBusinessHours) {
    const lastGroup = groupedHours[groupedHours.length - 1];
    if (lastGroup && lastGroup.start === start && lastGroup.end === end) {
      lastGroup.days.push(day);
    } else {
      groupedHours.push({ start, end, days: [day] });
    }
  }

  return groupedHours;
};
