import { StatePriv, Button, Stack } from '@tiendanube/components';
import { InvoiceIcon, ExternalLinkIcon } from '@tiendanube/icons';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { HeaderContent, IonPageStratus } from 'commons/components';
import { useGetTags } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function EmptyInvoiceList(): JSX.Element {
  const { goTo } = useNavegate();
  const goToInvoicesInfo = () => goTo('/account/invoices/info');
  const t = useTranslationBilling();
  const isRevmapMenu = useGetTags().includes(REVAMP_MENU_V1);

  return (
    <IonPageStratus
      headerContent={
        <HeaderContent
          title={t(`invoiceListPage.${isRevmapMenu ? 'revampTitle' : 'title'}`)}
        />
      }
    >
      <Stack align="center" spacing="none" column>
        <StatePriv
          icon={InvoiceIcon}
          title={t('invoiceListPage.emptyInvoiceList.title')}
          text={t('invoiceListPage.emptyInvoiceList.description')}
        />
        <Button
          appearance="primary"
          icon={ExternalLinkIcon}
          iconPosition="start"
          onClick={goToInvoicesInfo}
        >
          {`${t('invoiceListPage.emptyInvoiceList.addInvoiceDataButton')}`}
        </Button>
      </Stack>
    </IonPageStratus>
  );
}

export default EmptyInvoiceList;
