import * as Yup from 'yup';
import { CostType } from '../SecondStepForm/components/PickupPointCost/PickupPointCost';

export const validationSchemaSecondStep = Yup.object().shape({
  estimateDeliveryTime: Yup.object().shape({
    maxDays: Yup.string()
      .test('timeMaxNegativeValues', 'errors.negativeValues', (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      })
      .test('timeMax', 'errors.termMax', (value, { parent: { minDays } }) => {
        if (minDays) {
          return Number(minDays) <= Number(value);
        }
        return true;
      }),
    minDays: Yup.string().test(
      'timeMinNegativeValues',
      'errors.negativeValues',
      (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      },
    ),
  }),
  cost: Yup.object().shape({
    value: Yup.number()
      .nullable()
      .when('type', {
        is: CostType.FIXED,
        then: (schema) =>
          schema
            .required('errors.requiredValue')
            .moreThan(0, 'errors.valueMustBeGreaterThanZero'),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
  range: Yup.string()
    .required('errors.requiredValue')
    .test('rangeTest', 'errors.requiredValue', (value) => {
      if (!value || parseFloat(value) < 10 || parseFloat(value) > 160) {
        return false;
      }
      return true;
    }),
  businessHours: Yup.array()
    .of(
      Yup.object().shape({
        start: Yup.string()
          .required('errors.requiredStart')
          .matches(/^([01]?\d|2[0-3]):[0-5]\d$/, 'errors.invalidTimeFormat'),
        end: Yup.string()
          .required('errors.requiredEnd')
          .matches(/^([01]?\d|2[0-3]):[0-5]\d$/, 'errors.invalidTimeFormat'),
      }),
    )
    .min(1, 'errors.businessHoursRequired'),
});
