import {
  CurrencyType,
  FulfillmentOrdersResponseDto,
  OrderDetailsAmountsDto,
} from '@tiendanube/common';
import { DataList, Text } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { TOTAL_PAID_ORDER_DETAIL } from 'App/featuresFlags';
import { CurrencyPrice } from 'commons/components';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import ShippingCosts from './components/ShippingCosts';

interface OrderSummaryAmountsProps {
  amounts: OrderDetailsAmountsDto;
  currency: CurrencyType;
  fulfillmentOrders?: FulfillmentOrdersResponseDto[];
  totalProducts: number;
  isEdited?: boolean;
}

const DRAFT_ORDER_DISCOUNT_COUPON_PREFIX = 'DRAFT-ORDER';
const POS_ORDER_DISCOUNT_COUPON_PREFIX = 'POS-ORDER-';

function CardPaymentAmounts({
  amounts: {
    subtotal,
    total,
    shipping,
    previousShippingOwner,
    discount,
    discountDetail,
    shippingDetail,
    gatewayDiscount,
    couponDiscount,
    otherDiscounts,
    freeShippingDiscount,
    totalPaid,
    otherCosts,
    paidByCustomer,
    totalDiffPending,
  },
  currency,
  fulfillmentOrders,
  totalProducts,
  isEdited,
}: OrderSummaryAmountsProps): JSX.Element {
  const t = useTranslationOrders();
  const { isAvailable: hasEditOrders } = useIsEditOrdersEnabled();

  const couponDiscountDraft = couponDiscount?.codes?.some((discountCode) =>
    discountCode.includes(DRAFT_ORDER_DISCOUNT_COUPON_PREFIX),
  );

  const couponDiscountPOS = couponDiscount?.codes?.some((discountCode) =>
    discountCode.startsWith(POS_ORDER_DISCOUNT_COUPON_PREFIX),
  );

  const normalizedShipping = shipping ?? 0;
  return (
    <>
      <DataList.Row grayed id="subtotal">
        <DataList.Cell width={70}>
          <Text>{`${t('orderSummaryCard.tableAmounts.subtotal', {
            count: totalProducts,
          })}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={subtotal}
            currency={currency}
            block
            textAlign="right"
          />
        </DataList.Cell>
      </DataList.Row>
      {(normalizedShipping >= 0 || !!fulfillmentOrders) && (
        <ShippingCosts
          shippingDetail={shippingDetail}
          shipping={normalizedShipping}
          previousShipping={previousShippingOwner}
          currency={currency}
          fulfillmentOrders={fulfillmentOrders}
        />
      )}
      {!!discount && discount > 0 && (
        <DataList.Row id="discount">
          <DataList.Cell width={70}>
            <Text>
              {freeShippingDiscount
                ? t('orderSummaryCard.tableAmounts.freeShippingDiscount')
                : discountDetail ?? t('orderSummaryCard.tableAmounts.discount')}
            </Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={discount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!discount && !freeShippingDiscount && discount < 0 && (
        <DataList.Row id="additionalShippingCost">
          <DataList.Cell width={70}>
            <Text>{t('orderSummaryCard.tableAmounts.aditionalCost')}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={-discount}
              currency={currency}
              block
              textAlign="right"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!gatewayDiscount && gatewayDiscount > 0 && (
        <DataList.Row id="gatewayDiscount">
          <DataList.Cell width={70}>
            <Text>{`${t(
              'orderSummaryCard.tableAmounts.gatewayDiscount',
            )}`}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={gatewayDiscount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!couponDiscount?.amount && couponDiscount.amount > 0 && (
        <DataList.Row id="couponDiscount">
          <DataList.Cell width={70}>
            {couponDiscountPOS ? (
              <Text>
                {t('orderSummaryCard.tableAmounts.posCouponDiscount')}
              </Text>
            ) : (
              <Text>{`${t('orderSummaryCard.tableAmounts.couponDiscount', {
                coupons: couponDiscountDraft
                  ? t('orderSummaryCard.tableAmounts.couponDiscountDraft')
                  : couponDiscount.codes?.join(' / '),
              })}`}</Text>
            )}
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={couponDiscount.amount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!otherDiscounts &&
        otherDiscounts.length > 0 &&
        otherDiscounts.map((discount) => (
          <DataList.Row key={discount.name} id={discount.name}>
            <DataList.Cell width={70}>
              <Text>
                {discount.isCustom
                  ? discount.name
                  : t('orderSummaryCard.tableAmounts.promotionDiscount', {
                      promotionName: discount.name,
                    })}
              </Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={discount.amount}
                currency={currency}
                block
                textAlign="right"
                preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
                appearance="danger"
              />
            </DataList.Cell>
          </DataList.Row>
        ))}

      <DataList.Row id="total" grayed>
        <DataList.Cell width={70}>
          <Text bold>
            {isEdited && total !== paidByCustomer && paidByCustomer > 0
              ? t('orderSummaryCard.tableAmounts.updatedTotal')
              : t('orderSummaryCard.tableAmounts.total')}
          </Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={total}
            currency={currency}
            block
            textAlign="right"
            bold
          />
        </DataList.Cell>
      </DataList.Row>

      {hasEditOrders && isEdited && (
        <>
          <DataList.Row id="paidBycustomer">
            <DataList.Cell width={70}>
              <Text>{t('orderSummaryCard.tableAmounts.paidBycustomer')}</Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={paidByCustomer}
                currency={currency}
                block
                textAlign="right"
              />
            </DataList.Cell>
          </DataList.Row>

          <DataList.Row id="totalDiff" grayed>
            <DataList.Cell width={70}>
              <Text bold>
                {totalDiffPending < 0
                  ? t('orderSummaryCard.tableAmounts.feeToPay')
                  : t('orderSummaryCard.tableAmounts.feeToCharge')}
              </Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={Math.abs(totalDiffPending)}
                currency={currency}
                block
                textAlign="right"
                bold
              />
            </DataList.Cell>
          </DataList.Row>
        </>
      )}

      <FeatureFlag flag={TOTAL_PAID_ORDER_DETAIL}>
        {!!totalPaid && !!otherCosts && (
          <>
            <DataList.Row id="otherCosts">
              <DataList.Cell width={70}>
                <Text>{`${t(
                  'orderSummaryCard.tableAmounts.otherCosts',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice
                  price={otherCosts}
                  currency={currency}
                  block
                  textAlign="right"
                />
              </DataList.Cell>
            </DataList.Row>

            <DataList.Row id="totalPaid" grayed>
              <DataList.Cell width={70}>
                <Text bold>{`${t(
                  'orderSummaryCard.tableAmounts.totalPaid',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice
                  price={totalPaid}
                  currency={currency}
                  block
                  textAlign="right"
                  bold
                />
              </DataList.Cell>
            </DataList.Row>
          </>
        )}
      </FeatureFlag>
    </>
  );
}
export default CardPaymentAmounts;
