import { Icon, Link, Text, Tooltip, Box } from '@nimbus-ds/components';
import { MailIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { generatePath } from 'react-router-dom';
import { NavigationLink, SendEmailLink, Stack } from 'commons/components';
import { customersRouter } from 'domains/Customers';
import { BASE_URL } from 'domains/Orders/Orders/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export interface EmailType {
  to: string;
  subject: string;
  body: string;
  url?: string;
  signature?: string;
}

interface CustomerLinkProps {
  id?: string;
  name: string;
  email: EmailType;
  hideNotInformedEmail?: boolean;
  hasEmailChanged?: boolean;

  openSendEmailModal: () => void;
}

const NOT_INFORMED_EMAIL_REGEX = /(@noinformado\.com|@naoinformado\.com)$/;

function CustomerLink({
  id,
  name,
  email,
  hasEmailChanged,
  hideNotInformedEmail,
  openSendEmailModal,
}: CustomerLinkProps): JSX.Element {
  const t = useTranslationOrders();
  const hideEmailLink =
    hideNotInformedEmail && NOT_INFORMED_EMAIL_REGEX.test(email.to);
  const urlCustomers = `${BASE_URL}/customers/${id}`;
  if (id) {
    return (
      <Stack column align="flex-start" gap="1">
        <NavigationLink
          name={name}
          url={urlCustomers}
          goToNavegate={generatePath(customersRouter.customerDetails, { id })}
        />
        {!hideEmailLink && (
          <Box>
            <Link
              appearance="primary"
              onClick={openSendEmailModal}
              textDecoration="none"
            >
              <Icon color="currentColor" source={<MailIcon />} />
              {email.to}
              {hasEmailChanged && (
                <Tooltip arrow content={t('customerLink.alertChangedMail')}>
                  <Icon color="currentColor" source={<InfoCircleIcon />} />
                </Tooltip>
              )}
            </Link>
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <>
      <Text>{name}</Text>
      {!hideEmailLink && (
        <SendEmailLink
          email={email.to}
          subject={email.subject}
          body={email.body}
          url={email.url}
          signature={email.signature}
        />
      )}
    </>
  );
}

export default CustomerLink;
