const MIGRATE_LINK_MX =
  'https://ayuda.tiendanube.com/es_MX/migrar-desde-mercado-shops/como-migrar-mi-tienda-online-de-mercado-shops-a-tiendanube';
const MIGRATE_LINK_BR =
  'https://atendimento.nuvemshop.com.br/pt_BR/migrando-do-mercado-shops/como-migrar-minha-loja-online-do-mercado-shops-para-nuvemshop';
const MIGRATE_LINK_AR =
  'https://ayuda.tiendanube.com/es_AR/migrar-desde-mercado-shops/como-migrar-mi-tienda-online-de-mercado-shops-a-tiendanube';
const MIGRATE_LINK_CO =
  'https://ayuda.tiendanube.com/es_CO/migrar-desde-mercado-shops/como-migrar-mi-tienda-online-de-mercado-shops-a-tiendanube';
const MIGRATE_LINK_CL =
  'https://ayuda.tiendanube.com/es_ES/migrar-desde-mercado-shops/como-migrar-mi-tienda-online-de-mercado-shops-a-tiendanube';

export const MIGRATE_LINKS = {
  MX: MIGRATE_LINK_MX,
  BR: MIGRATE_LINK_BR,
  AR: MIGRATE_LINK_AR,
  CO: MIGRATE_LINK_CO,
  CL: MIGRATE_LINK_CL,
};

const NUVEM_MARKETING_BR =
  'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/perfit?q=Nuvem%20marketing';
const NUVEM_MARKETING_MX =
  'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/perfit';
const NUVEM_MARKETING_AR =
  'https://www.tiendanube.com/tienda-aplicaciones-nube/perfit';

export const NUVEM_MARKETING_LINKS = {
  BR: NUVEM_MARKETING_BR,
  MX: NUVEM_MARKETING_MX,
  AR: NUVEM_MARKETING_AR,
  CO: NUVEM_MARKETING_AR,
  CL: NUVEM_MARKETING_AR,
};

const SHARE_LANDING_BR = 'https://www.nuvemshop.com.br/site/mercadoshops';
const SHARE_LANDING_MX = 'https://www.tiendanube.com/mx/site/mercadoshops';
const SHARE_LANDING_AR = 'https://www.tiendanube.com/site/mercadoshops';

export const SHARE_LANDING_LINKS = {
  BR: SHARE_LANDING_BR,
  MX: SHARE_LANDING_MX,
  AR: SHARE_LANDING_AR,
  CO: SHARE_LANDING_AR,
  CL: SHARE_LANDING_AR,
};
