import { ChangeEvent } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import PasswordInputs from '../PasswordInputs';

interface PasswordCardProps {
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors?: Partial<Record<string, any>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function PasswordCard({
  values,
  errors,
  requestErrors,
  onChange,
}: Readonly<PasswordCardProps>) {
  const t = useTranslationConfigurations('userFormPage');

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('passwordCard.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column>
          <PasswordInputs
            values={values}
            errors={errors}
            onChange={onChange}
            requestErrors={requestErrors}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default PasswordCard;
