/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Domain, Status } from '@tiendanube/common/src/enums';
import { Stack } from '@tiendanube/components';
import { DownloadIcon, PlusCircleIcon } from '@tiendanube/icons';
import EmailValidationAlert from 'App/components/EmailValidationAlert';
import {
  NUVEMENVIO_CORREIOS_COMMUNICATION,
  NUVEMENVIO_JADLOG_COMMUNICATION,
} from 'App/featuresFlags';
import { useNavegate, useQuery } from 'App/hooks';
import {
  IonPageStratus,
  ActionProp,
  ActionsDesktop,
  ApplicationsDesktop,
  HeaderContent,
} from 'commons/components/IonPageStratus';
import { useGetTags, useHasPermission } from 'domains/Auth/hooks';
import {
  BulkActionsProvider,
  useBulkActions,
} from 'domains/Catalog/Products/pages/ProductListPage/components/BulkActionsProvider';
import {
  AlertOrderMetafields,
  ErrorMetafieldAlert,
} from 'domains/Metafields/components';
import {
  CorreiosCommunication,
  JadlogCommunication,
} from 'domains/NuvemEnvioCommons/components';
import HeaderOrderMobile from 'domains/Orders/components/HeaderOrderMobile';
import {
  SavedSearches,
  SavedSearchInterface,
} from 'domains/Orders/components/SavedSearches';
import { defaultSavedSearches } from 'domains/Orders/components/SavedSearches/defaultSavedSearches';
import useSavedSearches from 'domains/Orders/components/SavedSearches/useSavedSearches';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import useActiveShippingMethods from 'domains/Shipping/DeliveryMethods/pages/components/ActiveShippingMethodsCard/useActiveShippingMethods';
import OrderListFilter from './components/OrderListFilter';
import OrderListResults from './components/OrderListResults';
import { useOrdersList } from '../../hooks';
import { useIsSavedSearchesEnabled } from '../../hooks/useIsSavedSearchesEnabled';
import {
  FiltersParamsType,
  defaultFilterParams,
  defaultFilters,
} from '../../ordersService';

function OrderListPageWithProvider(): JSX.Element {
  return (
    <BulkActionsProvider>
      <OrdersListPage />
    </BulkActionsProvider>
  );
}

function OrdersListPage(): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const { goTo } = useNavegate();
  const { search } = useLocation();
  const query = useQuery();
  const tags = useGetTags();

  const { activeShippingMethods, getActiveShippingMethods } =
    useActiveShippingMethods();

  const hasNuvemEnvioTag =
    tags.includes(NUVEMENVIO_JADLOG_COMMUNICATION) ||
    tags.includes(NUVEMENVIO_CORREIOS_COMMUNICATION);

  const {
    isLoading,
    statusSuccess,
    ordersIds,
    ordersCount,
    filters,
    getOrdersList,
    removeMetafieldsFilters,
  } = useOrdersList();
  const { selectedRowsId, showAlertMetafields } = useBulkActions();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { selectedSavedSearch, setSelectedSavedSearch } =
    useSavedSearches(filters);

  const { getAppsLinks } = useAppsLinks();

  const knownFilterKeys = Object.keys(defaultFilters) as Array<
    keyof FiltersParamsType
  >;

  const metafieldPattern = new RegExp(
    `^${METAFIELD_FILTER_PREFIX}[a-f\\d]{8}(-[a-f\\d]{4}){4}[a-f\\d]{8}$`,
    'i',
  );

  const isMetafieldParameter = (key: string): boolean =>
    metafieldPattern.test(key);

  const getFilters = (keys: IterableIterator<string>) =>
    Array.from(keys).reduce((filters, key) => {
      const value = query.get(key);

      if (
        value &&
        (knownFilterKeys.includes(key as keyof FiltersParamsType) ||
          isMetafieldParameter(key))
      ) {
        return { ...filters, [key]: value };
      }
      return filters;
    }, {});

  useEffect(() => {
    const queryParam = query.keys();

    const filters = {
      ...defaultFilterParams,
      ...getFilters(queryParam),
    } as FiltersParamsType;

    getOrdersList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusSuccess === 'errorMetafield') {
      removeMetafieldsFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSuccess]);

  useEffect(() => {
    if (hasNuvemEnvioTag && !activeShippingMethods) getActiveShippingMethods();
  }, [activeShippingMethods, getActiveShippingMethods, hasNuvemEnvioTag, tags]);

  const onToggleEditMode = () => {
    setIsEditMode((e) => !e);
  };

  const handleChangeFilters = (newFilters: FiltersParamsType) => {
    getOrdersList(newFilters);
  };

  const isEmptyList = !isLoading && ordersIds.length === 0;

  const editModeAction = !isEmptyList
    ? {
        onClick: onToggleEditMode,
        children: isEditMode ? t('editMode.cancel') : t('editMode.edit'),
      }
    : undefined;

  const canExportOrdersCsv = useHasPermission('export_orders_csv');

  const exportOrdersAction: ActionProp = {
    children: t('exportOrders'),
    onClick: () => {
      const urlSearchParams = new URLSearchParams(search);
      urlSearchParams.delete('products');
      urlSearchParams.delete('productsCount');
      for (const [key, value] of urlSearchParams.entries()) {
        if (
          key.startsWith(METAFIELD_FILTER_PREFIX) ||
          (key === 'status' && value === Status.OPEN)
        ) {
          urlSearchParams.delete(key);
        }
      }
      const parsedQueryParams = urlSearchParams.toString();
      goTo(`/orders/export?${parsedQueryParams}`);
    },
    icon: DownloadIcon,
  };
  const addDraftOrderAction: ActionProp = {
    children: t('draftOrders.addDraftOrder'),
    onClick: () => goTo('/draft-orders/new'),
    icon: PlusCircleIcon,
    appearance: 'primary',
  };

  const actions: ActionProp[] = canExportOrdersCsv
    ? [exportOrdersAction, addDraftOrderAction]
    : [addDraftOrderAction];

  const applications: ActionProp[] = getAppsLinks('orders', 'default').map(
    (app) => ({
      children: app.text,
      onClick: () => window.open(app.url, '_blank'),
    }),
  );

  const isBoxShowable =
    hasNuvemEnvioTag &&
    !isLoading &&
    activeShippingMethods &&
    activeShippingMethods.length > 0;

  const savedSearches: SavedSearchInterface[] = defaultSavedSearches;
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        selectedRowsId.length === 0 && (
          <HeaderOrderMobile
            actions={actions}
            mainAction={editModeAction}
            applications={applications}
          />
        )
      }
      headerContent={
        <>
          <HeaderContent
            title={t('title')}
            actions={
              !isEmptyList ? (
                <ActionsDesktop actions={!isEmptyList ? actions : []} />
              ) : undefined
            }
            applications={<ApplicationsDesktop applications={applications} />}
          />
          {isBoxShowable && (
            <Box my="2-5">
              <>
                {tags.includes(NUVEMENVIO_JADLOG_COMMUNICATION) && (
                  <JadlogCommunication
                    activeShippingMethods={activeShippingMethods}
                    page="orders"
                  />
                )}
                {tags.includes(NUVEMENVIO_CORREIOS_COMMUNICATION) && (
                  <CorreiosCommunication
                    activeShippingMethods={activeShippingMethods}
                    page="orders"
                  />
                )}
              </>
            </Box>
          )}
        </>
      }
    >
      <ErrorMetafieldAlert status={statusSuccess} />
      <AlertOrderMetafields show={showAlertMetafields} />
      <EmailValidationAlert />
      <Stack column align="stretch" spacing="tight">
        {isSavedSearchesEnabled && (
          <SavedSearches
            savedSearches={savedSearches}
            selectedSavedSearch={selectedSavedSearch}
            setSelectedSavedSearch={setSelectedSavedSearch}
            onSavedSearchClick={handleChangeFilters}
          />
        )}
        <OrderListFilter
          filters={filters}
          ordersCount={ordersCount}
          isLoading={isLoading}
          onChange={handleChangeFilters}
        />
        <OrderListResults
          isEditMode={isEditMode}
          onToggleEditMode={onToggleEditMode}
        />
      </Stack>
    </IonPageStratus>
  );
}

export default OrderListPageWithProvider;
