enum Status {
  PAID = "paid",
  VOIDED = "voided",
  UNFULFILLED = "unfulfilled",
  OPEN = "open",
  CLOSED = "closed",
  CANCELLED = "cancelled",
  ARCHIVED = "archived",
  PENDING = "pending",
  PARTIALLY_PAID = "partially_paid",
  CLOSE = "close",
  UNPACKED = "unpacked",
  UNPACKED_DIGITAL = "unpacked-digital",
  DISPATCHED = "dispatched",
  PICKUP = "pickup",
  PICKUPED = "pickuped",
  PARTIALLY_PICKED_UP = "partially_picked_up",
  FULFILLED = "fulfilled",
  UNSHIPPED = "unshipped",
  SHIPPED_DIGITAL = "shipped-digital",
  SHIPPED = "shipped",
  REFUNDED = "refunded",
  WARNIG_TRANSACTIONS = "warningTransaction",
  PARTIALLY_PACKED = "partially_packed",
  PARTIALLY_FULFILLED = "partially_fulfilled",
  PACKED = "packed",
  READY_FOR_PICKUP = "ready_for_pickup",
  MARKED_AS_FULFILLED = "marked_as_fulfilled",
  DELIVERED = "delivered",
  PICKUP_READY = "pickup_ready",
}

export default Status;
