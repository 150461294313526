import { useEffect } from 'react';
import { PlusCircleIcon, EllipsisIcon, DownloadIcon } from '@nimbus-ds/icons';
import { Link, Popover } from '@tiendanube/components';
import { FEATURE_MONTHLY_CLIENTS_EXPORT } from 'App/features';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { HELP_LINKS_CUSTOMERS_LIST } from 'commons/constants';
import { useToast, useTranslationLanguage } from 'commons/hooks';
import { CUSTOMERS_EXPORT_CUSTOMERS } from 'config/upsellFlowSources';
import { useHasPermission } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import {
  trackingCustomerListPageLoad,
  trackingCustomerNewButtonClick,
  trackingCustomerTryExport,
} from 'domains/Customers/tracking';
import { InviteToCreateMetafieldAlert } from 'domains/Metafields/components';
import {
  useCustomerMetafieldsAccess,
  useGetCustomerMetafields,
} from 'domains/Metafields/hooks';
import {
  CustomerListResults,
  CustomerListSearch,
  ExportCustomerListAlert,
} from './components';
import { useCustomerResults, useExportCustomers } from './hooks';

function CustomerListPage() {
  const t = useTranslationCustomers();
  const language = useTranslationLanguage();
  const { goTo } = useNavegate();
  const { addToast } = useToast();
  const {
    isExporting,
    isExportError,
    isExportSuccess,
    exportCustomers,
    exportToken,
  } = useExportCustomers();
  const {
    customersIds,
    pagination,
    isRefreshing,
    isRefreshError,
    isRefreshSuccess,
    resetCustomerRefreshStatus,
  } = useCustomerResults();
  const { jobStatus, csvDownloadLinkUrl, setJob, removeJob } =
    useBackgroundJobs('customerCsvDownload');
  const hasCustomers = customersIds.length > 0;
  const { isAvailable: hasCustomerMetafieldsAccess } =
    useCustomerMetafieldsAccess();
  const { metafields } = useGetCustomerMetafields();

  useEffect(
    () => () => {
      resetCustomerRefreshStatus();
    },
    [resetCustomerRefreshStatus],
  );

  useEffect(() => {
    if (!isRefreshing && isRefreshError !== isRefreshSuccess) {
      const page = pagination.currentPage;
      trackingCustomerListPageLoad(page, isRefreshError);
    }
  }, [pagination.currentPage, isRefreshing, isRefreshError, isRefreshSuccess]);

  const hasMetafields = metafields !== null && metafields?.length > 0;

  const showInviteToCreateMetafieldAlert =
    hasCustomerMetafieldsAccess && !hasMetafields;
  const canDownloadCsv = useHasPermission('export_clients_csv') && hasCustomers;

  const handleExportCustomers = useUpsellFlow({
    title: t('customerListPage.upsell.export'),
    featureKey: FEATURE_MONTHLY_CLIENTS_EXPORT,
    trackingSource: CUSTOMERS_EXPORT_CUSTOMERS,
    callback: () => {
      trackingCustomerTryExport();
      exportCustomers();
    },
  });

  const mainAction: ActionProp = {
    children: t('customerListPage.addCustomers'),
    icon: PlusCircleIcon,
    onClick: () => {
      trackingCustomerNewButtonClick();
      goTo('/customers/new');
    },
    appearance: 'primary',
  };

  const actions: ActionProp[] = canDownloadCsv
    ? [
        {
          children: t(
            `customerListPage.${
              isExporting || jobStatus === 'pending' ? 'exporting' : 'export'
            }`,
          ),
          icon: DownloadIcon,
          spinner: isExporting || jobStatus === 'pending',
          disabled: isExporting,
          onClick: handleExportCustomers,
        },
        mainAction,
      ]
    : [mainAction];

  const mainActionMobile = {
    icon: PlusCircleIcon,
    onClick: () => {
      trackingCustomerNewButtonClick();
      goTo('/customers/new');
    },
    children: t('customerListPage.addCustomersMobile'),
  };

  const renderMenuMobile = canDownloadCsv && (
    <Popover
      name="dropdownMenu"
      position="right"
      renderInitiator={(onClick) => (
        <Link
          icon={EllipsisIcon}
          iconSize="medium"
          appearance="secondary"
          onClick={onClick}
        />
      )}
    >
      <ul className="nimbus--popover-menu">
        <li className="nimbus--popover-menu__item">
          <Link
            icon={DownloadIcon}
            spinner={isExporting}
            disabled={isExporting}
            onClick={handleExportCustomers}
          >
            <Stack justify="space-between">
              <Stack.Item>{t('customerListPage.export')}</Stack.Item>
            </Stack>
          </Link>
        </li>
      </ul>
    </Popover>
  );

  useEffect(() => {
    if (isExportSuccess) {
      setJob(exportToken);
    }
    if (isExportError) {
      addToast({
        label: t('customerListPage.exportError'),
        appearance: 'danger',
      });
    }
  }, [isExportSuccess, isExportError, addToast, t, exportToken, setJob]);

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop mainAction={mainActionMobile} actions={renderMenuMobile} />
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          actions={hasCustomers && <ActionsDesktop actions={actions} />}
        />
      }
    >
      <ExportCustomerListAlert
        exportingStatus={isExporting ? 'pending' : jobStatus}
        csvUrl={csvDownloadLinkUrl}
        ressetExportingStatus={removeJob}
      />
      <Stack column align="stretch" spacing="tight">
        {showInviteToCreateMetafieldAlert && <InviteToCreateMetafieldAlert />}
        <CustomerListSearch />
        <CustomerListResults />
      </Stack>
      <HelpLink
        title={t('customerListPage.helpLink.title')}
        previousValue=""
        currentViewTracking=""
        linkURL={HELP_LINKS_CUSTOMERS_LIST[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default CustomerListPage;
