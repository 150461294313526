import { useCallback, useEffect } from 'react';
import { InterfaceNameChecked } from '@tiendanube/components';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useResponsive, Stack } from 'commons/components';
import {
  useHasCatalogInventory,
  useHasPermission,
  useHasShippingMultiCD,
} from 'domains/Auth/hooks';
import { productHasVariants } from 'domains/Catalog/Products/utils';
import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import { useShowNoWeightAndDimension } from '../../hooks';
import {
  HandleChangeInterface,
  ProductFormState,
  RelatedProductInterface,
} from '../../hooks/useProductForm/types';
import Categories from '../Categories';
import FreeShippingAndShowInStore from '../FreeShippingAndShowInStore';
import HighlightProduct from '../HighlightProduct';
import ImageGallery from '../ImageGallery';
import { ImageGalleryState } from '../ImageGallery/ImageGallery';
import Inventory from '../Inventory';
import Location from '../Location/Location';
import NameAndDescription from '../NameAndDescription';
import Prices from '../Prices';
import ProductCodes from '../ProductCodes';
import ProductSizes from '../ProductSizes';
import ProductType from '../ProductType';
import RelatedProduct from '../RelatedProduct';
import SectionProductsAndVariantsMetafieldsWrapper from '../SectionProductsAndVariantsMetafieldsWrapper';
import SocialShopping from '../SocialShopping';
import Stock from '../Stock';
import TagsAndSeo from '../TagsAndSeo';
import Variants from '../Variants';
import {
  Attributes,
  OnChangeToAllMetafieldsVariants,
  OnChangeToAllVariants,
  Variant,
} from '../Variants/types';
import Video from '../Video';

interface FormProductProps {
  initialStock?: string | null;
  values: ProductFormState;
  errors: Partial<Record<string, string>>;
  handleChange: ({ name, value }: HandleChangeInterface) => void;
  handleChangeCategories: (categories: string[]) => void;
  handleChangeImages: (images: ImageGalleryState[], id?: string) => void;
  handleChangeVariants: (index: number, data: Partial<Variant>) => void;
  handleChangeAttributes: (attributes: Attributes) => void;
  handleChangeToAllVariants: OnChangeToAllVariants;
  handleChangeSectionCodes: (event: InterfaceNameChecked) => void;
  handleChangeRelatedProducts: (
    relatedProducts: RelatedProductInterface,
  ) => void;
  handleChangeToAllMetafieldsVariants: OnChangeToAllMetafieldsVariants;
}

function FormProduct({
  initialStock,
  values,
  errors,
  handleChange,
  handleChangeCategories,
  handleChangeImages,
  handleChangeVariants,
  handleChangeAttributes,
  handleChangeToAllVariants,
  handleChangeSectionCodes,
  handleChangeToAllMetafieldsVariants,
  handleChangeRelatedProducts,
}: FormProductProps): JSX.Element {
  const hasShippingMultiCD = useHasShippingMultiCD();
  const hasCatalogInventory = useHasCatalogInventory();
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);

  const { isMobile } = useResponsive();

  const canEditStock = useHasPermission('edit_stock');

  const { fetchMetafields } = useGetProductsAndVariantsMetafields();

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  const onChangeFirstVariant = useCallback(
    ({ name, value }) => {
      const variantValue = {
        [name]: value,
      } as Partial<Variant>;
      handleChangeVariants(0, variantValue);
    },
    [handleChangeVariants],
  );

  const isEdit = values.id !== undefined && values.id !== '';
  const [firstVariant] = values.variants;
  const imageVariants = values.images[0]?.src;
  const isDigital = values.isDigital;
  const hasVariants = productHasVariants(values.variants);

  const { id: variantId, weight, depth, width, height } = firstVariant;

  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight,
    depth,
    width,
    height,
  });

  const altTextGenerationContext = {
    productName: values.name,
    categoryIds: values.categories,
  };

  return (
    <Stack column spacing="loose" align="stretch">
      <NameAndDescription
        onChange={handleChange}
        name={values.name}
        description={values.description}
        errors={errors}
        autoFocus={!isEdit}
      />
      <ImageGallery
        onChange={handleChangeImages}
        images={hasUploadVideos ? values.media : values.images}
        altTextGenerationContext={altTextGenerationContext}
        isEdit={isEdit}
      />
      <Video
        videoUrl={values.videoUrl}
        errors={errors}
        onChange={handleChange}
        isEdit={isEdit}
      />
      {!hasVariants && (
        <Prices
          price={firstVariant.price}
          promotionalPrice={firstVariant.promotionalPrice}
          published={firstVariant.pricePublished}
          costPrice={firstVariant.costPrice}
          onChange={onChangeFirstVariant}
          isEdit={isEdit}
        />
      )}
      <ProductType onChange={handleChange} isDigital={isDigital} />
      {!hasVariants && !(hasShippingMultiCD && hasCatalogInventory) && (
        <Stock
          stock={firstVariant.stock}
          onChange={onChangeFirstVariant}
          variantId={firstVariant.id}
          variantName={firstVariant.name}
          productId={firstVariant.productId}
          isEdit={isEdit}
          locationId={firstVariant.locationId}
          canEditStock={canEditStock}
        />
      )}
      {!hasVariants && hasShippingMultiCD && hasCatalogInventory && (
        <Inventory
          stock={firstVariant.stock}
          isInfinite={firstVariant.isInfinite}
          variantId={firstVariant.id}
          variantName={firstVariant.name}
          productId={firstVariant.productId}
          locationId={firstVariant.locationId}
          variant={firstVariant}
          isEdit={isEdit}
          currentStock={initialStock}
          onChange={onChangeFirstVariant}
          hasShippingMultiCD={hasShippingMultiCD}
          hasVariants={hasVariants}
          onChangeLocation={onChangeFirstVariant}
          canEditStock={canEditStock}
        />
      )}
      {!hasVariants && (
        <ProductCodes
          sku={firstVariant.sku}
          barcode={firstVariant.barcode}
          onChange={onChangeFirstVariant}
          isEdit={isEdit}
        />
      )}
      {!hasVariants && hasShippingMultiCD && !hasCatalogInventory && (
        <Location
          locationId={firstVariant.locationId}
          isMobile={isMobile}
          hasVariants={hasVariants}
          onChange={onChangeFirstVariant}
        />
      )}
      {!hasVariants && !isDigital && (
        <ProductSizes
          weight={firstVariant.weight}
          depth={firstVariant.depth}
          width={firstVariant.width}
          height={firstVariant.height}
          showAlertNoWeightAndDimension={showNoWeightAndDimension}
          onChange={onChangeFirstVariant}
          appearance="row"
          isEdit={isEdit}
        />
      )}
      {!hasVariants && (
        <SocialShopping
          mpn={firstVariant.mpn}
          ageGroup={firstVariant.ageGroup}
          gender={firstVariant.gender}
          onChange={onChangeFirstVariant}
          appearance={isMobile ? 'column' : 'row'}
          onChangeToAllVariants={handleChangeToAllVariants}
        />
      )}
      <Categories
        onChange={handleChangeCategories}
        selectedCategories={values.categories}
        isEdit={isEdit}
      />
      <Variants
        variants={values.variants}
        attributes={values.attributes}
        productImages={values.images}
        isDigital={values.isDigital}
        defaultImage={imageVariants}
        isEdit={isEdit}
        onChange={handleChangeVariants}
        onChangeAttributes={handleChangeAttributes}
        onChangeToAllVariants={handleChangeToAllVariants}
        onChangeToAllMetafieldsVariants={handleChangeToAllMetafieldsVariants}
        productId={values.id || ''}
      />
      <SectionProductsAndVariantsMetafieldsWrapper
        source="page"
        hasVariant={hasVariants}
        productId={values.id || ''}
        selecteds={{
          product_variant: firstVariant.metafields?.internals || [],
          product: values.productMetafields?.internals || [],
        }}
        apiSelecteds={{
          product_variant: firstVariant.metafields?.fromApi || [],
          product: values.productMetafields?.fromApi || [],
        }}
        onVariantChange={onChangeFirstVariant}
        onProductChange={handleChange}
      />
      <TagsAndSeo
        tags={values.tags}
        brand={values.brand}
        name={values.name}
        description={values.description}
        seoDescription={values.seoDescription}
        seoTitle={values.seoTitle}
        seoUrl={values.seoUrl}
        onChangeBrand={handleChange}
        onChangeSeo={handleChange}
        onChangeTags={handleChange}
        isEdit={isEdit}
      />
      <HighlightProduct
        productId={values.id}
        sectionCodes={values.sectionCodes}
        onChange={handleChangeSectionCodes}
        isEdit={isEdit}
      />
      {!!values?.id && (
        <RelatedProduct
          mainProduct={values}
          relatedProducts={values.relatedProducts}
          onChange={handleChangeRelatedProducts}
        />
      )}
      <FreeShippingAndShowInStore
        onChange={handleChange}
        freeShipping={values.freeShipping}
        published={values.published}
        isDigital={isDigital}
      />
    </Stack>
  );
}

export default FormProduct;
