import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { ChargeDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  GO_TO_CHECKOUT_AFTER_PAYMENT,
  useAfterPaymentRedirection,
  useChargesToPay,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';

export default function useSelectCharges() {
  const { goTo } = useNavegate();

  const {
    chargesToSelect,
    chargeTotalWithoutTaxes,
    status: chargesStatus,
    refreshChargesToPay,
  } = useChargesToPay();

  const [selectedCharges, setSelectedCharges] = useState<ChargeDto[]>([]);

  useEffect(() => {
    chargesToSelect &&
      setSelectedCharges(
        chargesToSelect.filter((charge) => charge.status !== 'IN_PROCESS'),
      );
  }, [chargesToSelect]);

  const totalOfSelectedCharges = useMemo(
    () =>
      selectedCharges.reduce(
        (acc, charge) => acc + chargeTotalWithoutTaxes(charge),
        0,
      ),
    [chargeTotalWithoutTaxes, selectedCharges],
  );
  const { createPayorder, payOrderCreationStatus } = usePayorderToPay();

  const { addAfterPayActionToPath } = useAfterPaymentRedirection();

  const onPay = useCallback(() => {
    createPayorder(selectedCharges.map(({ id }) => id))
      .unwrap()
      .then((payOrder) => {
        if (payOrder)
          goTo(
            addAfterPayActionToPath(
              generatePath(CHECKOUT_ROUTES.choosePaymentMethod, {
                payOrderId: payOrder.id,
              }),
              GO_TO_CHECKOUT_AFTER_PAYMENT,
            ),
          );
      });
  }, [createPayorder, selectedCharges, goTo, addAfterPayActionToPath]);

  const planIsListed = useMemo(
    () =>
      chargesToSelect?.some(({ conceptCode }) => conceptCode === 'plan-cost'),
    [chargesToSelect],
  );

  return {
    chargesStatus,
    refreshChargesToPay,
    totalOfSelectedCharges,
    setSelectedCharges,
    chargesToSelect,
    onPay,
    payOrderCreationStatus,
    isCreatingPayOrder: payOrderCreationStatus === 'loading',
    selectedCharges,
    planIsListed,
    ...convertStatusTypeToObject(chargesStatus),
  };
}
