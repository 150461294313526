import { useCallback } from 'react';
import { CheckoutPaymentMethod, ConceptCode } from '@tiendanube/common';
import { BE_APP_SUBSCRIPTION_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { logEvent } from 'commons/utils/tracking';
import {
  BILLING_CHANGE_PLANS_CLICK,
  BILLING_CHECKOUT_CREDIT_CARD_PAY_CLICK,
  BILLING_CHECKOUT_SELECT_METHOD_FOR_PLAN_CLICK,
  BILLING_CHECKOUT_START_PLAN_PAYMENT,
  BILLING_CHECKOUT_VIEW_DETAILS,
  BILLING_CHECKOUT_WIRE_TRANSFER_PAY_CLICK,
  BILLING_LAST_PAYMENTS,
  BILLING_LAST_PAYMENTS_TAB_CLICK,
  BILLING_PAYORDER_CHECKOUT_CONTINUE_CLICK,
  BILLING_PAYORDER_CHECKOUT_FINISH,
  BILLING_PAYORDER_CHECKOUT_VIEW,
  BILLING_RECURRING_CANCEL_CLICK,
  BILLING_VIEW_CHECKOUT_CHARGES,
  BILLING_VIEW_CHECKOUT_CHARGES_AFTER_PAYMENT,
  BILLING_VIEW_CHECKOUT_PAY_CREDIT_CARD,
  BILLING_VIEW_CHECKOUT_PAY_WIRE_TRANSFER,
  BILLING_VIEW_CHECKOUT_SELECT_METHOD_FOR_PLAN,
} from 'config/trackingEvents';

export type VIEW_DETAILS_ORIGIN =
  | 'lastPayments'
  | 'chargesToPay'
  | 'recurrentPaymentStatus';

type LoggingFunction = (
  eventName: string,
  properties: Record<string, string>,
) => void;

export function useCheckoutTracking(): LoggingFunction {
  const [hasTag] = useHasTags(BE_APP_SUBSCRIPTION_ENABLED);
  const log = useCallback(
    (eventName: string, properties: Record<string, string>) =>
      logEvent(eventName, {
        billing_checkout_origin: hasTag ? 'new_checkout_v2' : 'new_checkout_v1',
        ...properties,
      }),
    [hasTag],
  );

  return log;
}

export const trackingBillingViewCheckoutCharges = (
  log: LoggingFunction,
): void => {
  log(BILLING_VIEW_CHECKOUT_CHARGES, {});
};

export const trackingBillingViewCheckoutChargesAfterPayment = (
  log: LoggingFunction,
): void => {
  log(BILLING_VIEW_CHECKOUT_CHARGES_AFTER_PAYMENT, {});
};

export const trackingBillingStartPlanPayment = (log: LoggingFunction): void => {
  log(BILLING_CHECKOUT_START_PLAN_PAYMENT, {});
};

export const trackingBillingViewSelectPaymentMethodForPlan = (
  log: LoggingFunction,
): void => {
  log(BILLING_VIEW_CHECKOUT_SELECT_METHOD_FOR_PLAN, {});
};

export const trackingBillingSelectPaymentMethodForPlanClick = (
  log: LoggingFunction,
  method: CheckoutPaymentMethod,
): void => {
  log(BILLING_CHECKOUT_SELECT_METHOD_FOR_PLAN_CLICK, {
    payment_method: method,
  });
};

export const trackingBillingViewPayCreditCard = (
  log: LoggingFunction,
): void => {
  log(BILLING_VIEW_CHECKOUT_PAY_CREDIT_CARD, {});
};

export const trackingBillingPayCreditCardClick = (
  log: LoggingFunction,
): void => {
  log(BILLING_CHECKOUT_CREDIT_CARD_PAY_CLICK, {});
};

export const trackingBillingViewPayWireTransfer = (
  log: LoggingFunction,
): void => {
  log(BILLING_VIEW_CHECKOUT_PAY_WIRE_TRANSFER, {});
};

export const trackingBillingPayWireTransferClick = (
  log: LoggingFunction,
): void => {
  log(BILLING_CHECKOUT_WIRE_TRANSFER_PAY_CLICK, {});
};

export const trackingBillingRecurringCancel = (log: LoggingFunction): void => {
  log(BILLING_RECURRING_CANCEL_CLICK, {});
};

export const trackingBillingChangePlansClick = (log: LoggingFunction): void => {
  log(BILLING_CHANGE_PLANS_CLICK, {});
};

export const trackingBillingCheckoutLastPayments = (
  log: LoggingFunction,
): void => {
  log(BILLING_LAST_PAYMENTS, {});
};

export const trackingBillingCheckoutViewDetails = (
  log: LoggingFunction,
  origin: VIEW_DETAILS_ORIGIN,
): void => {
  log(BILLING_CHECKOUT_VIEW_DETAILS, { origin });
};

export const trackingBillingPayOrderCheckoutView = (
  mainConcept: ConceptCode,
): void => {
  logEvent(BILLING_PAYORDER_CHECKOUT_VIEW, {
    main_concept: mainConcept,
  });
};

export const trackingBillingLastPaymentsTabClick = (): void => {
  logEvent(BILLING_LAST_PAYMENTS_TAB_CLICK, {});
};

export const trackingBillingPayOrderCheckoutContinueClick = (
  method: CheckoutPaymentMethod,
): void => {
  logEvent(BILLING_PAYORDER_CHECKOUT_CONTINUE_CLICK, {
    payment_method: method,
  });
};

export const trackingBillingPayOrderCheckoutFinish = (): void => {
  logEvent(BILLING_PAYORDER_CHECKOUT_FINISH, {});
};
