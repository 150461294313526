import { Redirect, Route } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import {
  TransactionFeesMonthSelectionPage,
  TransactionFeesPage,
  TransactionFeesListPage,
} from './pages';
import { TRANSACTION_FEES_ROUTES } from './transactionFeesRoutes';

function TransactionFees(): JSX.Element {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  return (
    <>
      {isRevampMenu ? (
        <Route exact path={TRANSACTION_FEES_ROUTES.transactionFees}>
          <Redirect to={CONFIGURATIONS_ROUTES.transactionFees} />
        </Route>
      ) : (
        <Route exact path={TRANSACTION_FEES_ROUTES.transactionFees}>
          <TransactionFeesPage />
        </Route>
      )}

      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesSplitList}>
        <TransactionFeesListPage split />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesNoSplitList}>
        <TransactionFeesListPage split={false} />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesSplitMonthly}>
        <TransactionFeesListPage split />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesNoSplitMonthly}>
        <TransactionFeesListPage split={false} />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesMonthSelection}>
        <TransactionFeesMonthSelectionPage />
      </Route>
    </>
  );
}

export default TransactionFees;
