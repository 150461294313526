import { useMemo } from 'react';
import { NEW_ADMIN_ORDERS_SAVED_SEARCHES } from 'App/featuresFlags';
import { useResponsive } from 'commons/components/Responsive';
import { useGetTags } from 'domains/Auth/hooks';

export function useIsSavedSearchesEnabled() {
  const tags = useGetTags();
  const { isMobile } = useResponsive();

  const hasSavedSearchesTag = useMemo(
    () => tags.includes(NEW_ADMIN_ORDERS_SAVED_SEARCHES),
    [tags],
  );

  const isEnabled = useMemo(
    () => hasSavedSearchesTag && !isMobile,
    [hasSavedSearchesTag, isMobile],
  );

  return isEnabled;
}
