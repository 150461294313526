/* eslint-disable max-statements */
import { Tooltip, IconButton } from '@nimbus-ds/components';
import {
  EyeIcon,
  EyeOffIcon,
  SubcategoryIcon,
  PlusCircleIcon,
  TrashIcon,
  EditIcon,
} from '@nimbus-ds/icons';
import { Stack } from '@tiendanube/components';
import { FEATURE_HIDE_CATEGORIES } from 'App/features';
import { PopoverMenu, useResponsive } from 'commons/components';
import { CATALOG_EDIT_CATEGORIES } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  useHiddenCategories,
  useTrackFullCatalog,
} from 'domains/Catalog/hooks';
import {
  trackingCategoriesActionCategoryClick,
  trackingCategoriesActionDeleteClick,
  trackingCategoriesActionEditClick,
  trackingCategoriesActionHideClick,
  trackingCategoriesActionSubcategoryClick,
  trackingCategoriesActionUnhideClick,
} from 'domains/Catalog/Products/tracking';
import { VisibilityCategoryType } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

const MAX_ADD_MOBILE = 5;
const MAX_ADD_DESKTOP = 14;

interface ActionsProps {
  isAdding: boolean;
  depth: number;
  visibility?: VisibilityCategoryType;
  forbiddenUnhide?: boolean;
  onAddSubcategory: () => void;
  onAddCategory: () => void;
  onDelete: () => void;
  onGoToDeatils: () => void;
  openDeleteModal: () => void;
  onShow?: () => void;
  onHide?: () => void;
}

const noop = () => null;

function Actions({
  isAdding,
  depth,
  visibility = 'visible',
  forbiddenUnhide = false,
  onAddSubcategory,
  onAddCategory,
  onDelete,
  onGoToDeatils,
  openDeleteModal,
  onShow = noop,
  onHide = noop,
}: ActionsProps): JSX.Element {
  const t = useTranslationCatalog();
  const { isMobile } = useResponsive();
  const { hasHiddenCategories } = useHiddenCategories();

  const isMinorLimit = isMobile
    ? depth < MAX_ADD_MOBILE
    : depth < MAX_ADD_DESKTOP;
  const showAdd = isAdding && isMinorLimit;
  const showDelete = isAdding;
  const showEdit = !isAdding && !isMinorLimit;
  const showMenu = !isAdding && isMinorLimit;

  const sender = useTrackFullCatalog();

  const handleAddSubcategory = () => {
    sender(trackingCategoriesActionSubcategoryClick);
    onAddSubcategory();
  };

  const handleOnGoToDeatils = () => {
    sender(trackingCategoriesActionEditClick);
    onGoToDeatils();
  };

  const handleDelete = () => {
    sender(trackingCategoriesActionDeleteClick);
    openDeleteModal();
  };

  const handleOnAddCategory = () => {
    sender(trackingCategoriesActionCategoryClick);
    onAddCategory();
  };

  const handleHideCategory = useUpsellFlow({
    title: t('categories.detail.visibility.upsell'),
    featureKey: FEATURE_HIDE_CATEGORIES,
    trackingSource: CATALOG_EDIT_CATEGORIES,
    callback: () => {
      sender(trackingCategoriesActionHideClick);
      onHide();
    },
  });

  const handleUnHideCategory = () => {
    sender(trackingCategoriesActionUnhideClick);
    onShow();
  };

  const visibleOption = {
    label: t('categories.actions.hide'),
    onClick: handleHideCategory,
    startIcon: EyeOffIcon,
    tooltip: '',
    disabled: false,
  };

  const hiddenOption = {
    label: t('categories.actions.show'),
    onClick: handleUnHideCategory,
    startIcon: EyeIcon,
    tooltip: forbiddenUnhide ? t('categories.actions.forbiddenUnhide') : '',
    disabled: forbiddenUnhide,
  };

  const visibilityOptionsByType = {
    visible: visibleOption,
    hidden: hiddenOption,
    'soft-hidden': hiddenOption,
  };

  const visibilityOptions = visibilityOptionsByType[visibility];

  const VisibilityIcon = visibilityOptions.startIcon;

  const menu = [
    {
      label: t('categories.actions.addSubcategory'),
      onClick: handleAddSubcategory,
      startIcon: SubcategoryIcon,
    },
    {
      label: t('categories.actions.edit'),
      onClick: handleOnGoToDeatils,
      startIcon: EditIcon,
    },
    {
      label: t('categories.actions.delete'),
      onClick: handleDelete,
      startIcon: TrashIcon,
    },
    {
      topSeparator: true,
      label: t('categories.actions.addCategory'),
      onClick: handleOnAddCategory,
      startIcon: PlusCircleIcon,
    },
  ];

  if (hasHiddenCategories) {
    menu.splice(2, 0, visibilityOptions);
  }

  const { tooltip, onClick, disabled } = visibilityOptions;

  const renderVisibilityIcon = (
    <IconButton
      size="2rem"
      source={<VisibilityIcon />}
      onClick={onClick}
      disabled={disabled}
    />
  );

  return (
    <Stack spacing="tight">
      {showAdd && (
        <>
          {hasHiddenCategories && !!tooltip && (
            <Tooltip content={tooltip}>{renderVisibilityIcon}</Tooltip>
          )}
          {hasHiddenCategories && !tooltip && renderVisibilityIcon}
          <Tooltip content={t('categories.actions.addSubcategory')}>
            <IconButton
              size="2rem"
              source={<PlusCircleIcon />}
              onClick={handleOnAddCategory}
            />
          </Tooltip>
        </>
      )}
      {showDelete && (
        <Tooltip content={t('categories.actions.delete')}>
          <IconButton size="2rem" source={<TrashIcon />} onClick={onDelete} />
        </Tooltip>
      )}
      {showEdit && (
        <Tooltip content={t('categories.actions.edit')}>
          <IconButton
            size="2rem"
            source={<EditIcon />}
            onClick={onGoToDeatils}
          />
        </Tooltip>
      )}
      {showMenu && <PopoverMenu actions={menu} />}
    </Stack>
  );
}

export default Actions;
