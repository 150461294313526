import { useMemo } from 'react';
import { FEATURE_EDIT_ORDER } from 'App/features';
import {
  useGetPlan,
  useStatusFeatureByKeyWithDefaultValue,
} from 'domains/Auth/hooks';

function useIsEditOrdersEnabled() {
  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_EDIT_ORDER,
    0,
  );
  const { niceName } = useGetPlan();
  const isUpsellAvailable = useMemo(() => niceName === 'plan-B', [niceName]);

  return {
    isAvailable,
    isUpsellAvailable,
  };
}

export default useIsEditOrdersEnabled;
