import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { StoreIcon } from '@tiendanube/icons';
import { useHandleMenuClick } from 'App/hooks';

const POS_URL_PATH = '/sales-channels/marketplaces';

function MarketplacesMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  return (
    <MenuButton
      as={Link}
      to={POS_URL_PATH}
      startIcon={StoreIcon}
      onClick={handleClickItem}
      active={pathname === POS_URL_PATH}
      label={`${t('menu.salesChannels.more.marketplaces')}`}
    />
  );
}

export default MarketplacesMenu;
