import { Locale } from 'App/i18n';

type LocalesLinksType = Partial<Record<Locale, string>>;

export const STORE_APP = {
  ios: 'https://apps.apple.com/us/app/nuvemshop/id1038913504',
  android: 'https://play.google.com/store/apps/details?id=com.nuvemshop.admin',
};

export const HOW_TO_CONFIGURE_SEO_LINK =
  'https://atendimento.nuvemshop.com.br/adicionar-um-novo-produto/como-configurar-o-seo-dos-meus-produtos?from_search=78832723';

export const TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK =
  'https://www.tiendanube.com/blog/fotografia-de-productos';

export const TIPS_TO_TAKE_PHOTO_BR_LINK =
  'https://www.nuvemshop.com.br/blog/como-tirar-boas-fotos-dos-produtos';

export const TIPS_TO_TAKE_PHOTO_MX_LINK =
  'https://www.tiendanube.com/blog/mx/fotografia-de-producto';

export const HOW_TO_GENERATE_MORE_VISITS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/blog/10-tips-imperdibles-para-aumentar-el-trafico-de-tu-tienda-online-y-tener-mas-ventas/',
  'es-MX':
    'https://www.tiendanube.com/blog/10-tips-imperdibles-para-aumentar-el-trafico-de-tu-tienda-online-y-tener-mas-ventas/',
  'pt-BR':
    'https://www.nuvemshop.com.br/blog/estrategias-aumentar-visitas-do-site/',
};

export const GOOGLE_ANALYTICS_CONFIGURATION_LINK: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_ES/123490-google-analytics/como-integrar-google-analytics-a-mi-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/google-analytics/como-vincular-google-analytics-4-con-mi-tiendanube ',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/google-analytics/como-configurar-e-usar-o-google-analytics',
};

export const GOOGLE_ANALYTICS_DIFFERENCES_WITH_STATISTICS_LINK: LocalesLinksType =
  {
    'es-AR':
      'https://ayuda.tiendanube.com/es_ES/123489-estadisticas/por-que-veo-diferencias-entre-las-estadisticas-de-mi-tienda-y-google-analytics',
    'es-MX':
      'https://ayuda.tiendanube.com/es_MX/estadisticas/por-que-veo-diferencias-entre-las-estadisticas-de-mi-tienda-y-google-analytics',
    'pt-BR':
      'https://atendimento.nuvemshop.com.br/pt_BR/estatisticas/porque-vejo-diferencas-entre-as-estatisticas-da-minha-loja-e-do-google-analytics',
  };

export const PRODUCTS_IN_NUMBER_EMPTY_STATE_STOCK_LINKS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/blog/estrategias-para-aumentar-tus-ventas/',
  'es-MX':
    'https://www.tiendanube.com/blog/10-tips-imperdibles-para-aumentar-el-trafico-de-tu-tienda-online-y-tener-mas-ventas/',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/datas-especiais/como-aumentar-as-visitas-da-minha-loja-e-gerar-mais-vendas',
};

export const PRODUCTS_IN_NUMBER_EMPTY_STATE_VISITS_LINKS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/blog/10-tips-imperdibles-para-aumentar-el-trafico-de-tu-tienda-online-y-tener-mas-ventas/',
  'es-MX':
    'https://www.tiendanube.com/blog/10-tips-imperdibles-para-aumentar-el-trafico-de-tu-tienda-online-y-tener-mas-ventas/',
  'pt-BR':
    'https://www.nuvemshop.com.br/blog/estrategias-aumentar-visitas-do-site/',
};

export const PRODUCTS_IN_NUMBER_EMPTY_STATE_SALES_LINKS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/blog/estrategias-para-aumentar-tus-ventas/',
  'es-MX':
    'https://www.tiendanube.com/blog/estrategias-para-aumentar-tus-ventas/',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/datas-especiais/como-aumentar-as-visitas-da-minha-loja-e-gerar-mais-vendas',
};

export const STORE_PAYMENT_APPS_LINKS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/tienda-aplicaciones-nube/categorias/pagos',
  'es-MX':
    'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/categorias/pagos',
  'pt-BR':
    'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/categorias/pagamentos',
  'es-CO':
    'https://www.tiendanube.com/co/tienda-aplicaciones-nube/categorias/pagos',
  'es-CL':
    'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/categorias/pagos',
};

export const OCA_HOW_IT_WORKS_LINK =
  'https://ayuda.tiendanube.com/es/articles/677262-como-funciona-la-logistica-con-cada-medio-de-envio/#oca';

export const HOW_TO_CANCEL_AN_ORDER_LINKS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123288-mis-ventas/como-cancelar-una-venta-en-mi-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123288-mis-ventas/como-cancelar-una-venta-en-mi-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/cancelar-pedidos/como-cancelar-uma-venda',
};

export const RECOVER_PASSWORD_LINKS: LocalesLinksType = {
  'es-AR': 'https://www.tiendanube.com/recuperar-contrasena',
  'es-MX': 'https://www.tiendanube.com/recuperar-contrasena',
  'pt-BR': 'https://www.nuvemshop.com.br/recuperar-senha',
};

export const HELP_LINKS_TO_CANCELLATION: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/cancelar-y-reactivar-tienda/como-dar-de-baja-mi-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/cancelar-y-reactivar-tienda/como-dar-de-baja-mi-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/cancelar-y-reactivar-tienda/como-dar-de-baja-mi-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/cancelar-y-reactivar-tienda/como-dar-de-baja-mi-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/cancelar-e-reativar?utm_source=newadmin&utm_medium=account&utm_campaign=CAN',
};

export const HELP_LINKS_ORGANIZE_PRODUCTS: LocalesLinksType = {
  'es-AR': 'https://ayuda.tiendanube.com/es_AR/122707-organizar-productos',
  'es-MX': 'https://ayuda.tiendanube.com/es_MX/122707-organizar-productos',
  'es-CO': 'https://ayuda.tiendanube.com/es_ES/122707-organizar-productos',
  'es-CL': 'https://ayuda.tiendanube.com/es_ES/122707-organizar-productos',
};

export const HELP_LINKS_CATEGORIES: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/122709-categorias/como-asociar-mis-productos-a-una-categoria',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/122709-categorias/como-asociar-mis-productos-a-una-categoria',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/122709-categorias/como-asociar-mis-productos-a-una-categoria',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/122709-categorias/como-asociar-mis-productos-a-una-categoria',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/organizar-categorias/guia-como-configurar-as-categorias',
};

export const HELP_LINKS_BUSINESS_DATA: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123485-datos-de-tu-cuenta/como-configurar-la-informacion-de-la-seccion-datos-de-mi-negocio',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123485-datos-de-tu-cuenta/como-configurar-la-informacion-de-la-seccion-datos-de-mi-negocio',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123485-datos-de-tu-cuenta/como-configurar-la-informacion-de-la-seccion-datos-de-mi-negocio',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123485-datos-de-tu-cuenta/como-configurar-la-informacion-de-la-seccion-datos-de-mi-negocio',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/dados-do-negocio?utm_source=newadmin&utm_medium=account&ut',
};

export const HELP_LINKS_COUPON_LIST: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/121901-cupons-e-promocoes/como-criar-cupons-de-desconto',
};

export const DASHBOARD_CAN_LINKS: LocalesLinksType = {
  'es-AR': 'https://ayuda.tiendanube.com/es_AR',
  'es-MX': 'https://ayuda.tiendanube.com/es_MX',
  'es-CO': 'https://ayuda.tiendanube.com/es_ES',
  'es-CL': 'https://ayuda.tiendanube.com/es_ES',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/?utm_source=newadmin&utm_medium=homepage&utm_campaign=CAN',
};

export const TERMS_OF_USE_LINK: LocalesLinksType = {
  'es-AR': 'https://www.tiendanube.com/terminos-de-uso',
  'es-MX': 'https://www.tiendanube.com/mx/terminos-de-uso',
  'es-CO': 'https://www.tiendanube.com/co/terminos-de-uso',
  'es-CL': 'https://www.tiendanube.com/terminos-de-uso',
  'pt-BR': 'https://www.nuvemshop.com.br/termos-de-uso',
};

export const HELP_LINKS_PAY_U_HOW_ENABLE_INT_PAYMENTS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/122923-payu/como-habilitar-los-pagos-internacionales-con-payu',
  'es-MX':
    'https://ayuda.tiendanube.com/es_ES/122923-payu/como-habilitar-los-pagos-internacionales-con-payu',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/122923-payu/como-habilitar-los-pagos-internacionales-con-payu',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/122923-payu/como-habilitar-los-pagos-internacionales-con-payu',
};

export const HELP_LINKS_TO_EXPORT_ORDERS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123338-exportar-lista-de-ventas/como-exportar-mi-lista-de-ventas',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123338-exportar-lista-de-ventas/como-exportar-mi-lista-de-ventas',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123338-exportar-lista-de-ventas/como-exportar-mi-lista-de-ventas',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123338-exportar-lista-de-ventas/como-exportar-mi-lista-de-ventas',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/minhas-vendas/como-exportar-as-minhas-vendas-para-uma-planilha',
};

export const HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/122710-importar-y-exportar-productos/como-completar-el-excel-de-carga-masiva-de-productos',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/122710-importar-y-exportar-productos/como-completar-el-excel-de-carga-masiva-de-productos',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/122710-importar-y-exportar-productos/como-completar-el-excel-de-carga-masiva-de-productos',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/122710-importar-y-exportar-productos/como-completar-el-excel-de-carga-masiva-de-productos',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/importar-e-exportar-produtos/perguntas-frequentes-sobre-importar-e-exportar-produtos',
};

export const HELP_LINKS_CONTACT_INFO: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123264-informacion-de-contacto/como-mostrar-mi-informacion-de-contacto',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123264-informacion-de-contacto/como-mostrar-mi-informacion-de-contacto',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123264-informacion-de-contacto/como-mostrar-mi-informacion-de-contacto',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123264-informacion-de-contacto/como-mostrar-mi-informacion-de-contacto',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/barra-de-navegacao-rodape-do-layout/como-adicionar-meus-contatos-em-minha-loja',
};

export const HELP_LINKS_PASSWORD_PROTECTED: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123265-pagina-en-construccion/como-activar-el-modo-pagina-en-construccion-en-mi-tienda',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123265-pagina-en-construccion/como-activar-el-modo-pagina-en-construccion-en-mi-tienda',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123265-pagina-en-construccion/como-activar-el-modo-pagina-en-construccion-en-mi-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123265-pagina-en-construccion/como-activar-el-modo-pagina-en-construccion-en-mi-tienda',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/123410-pagina-em-construcao/como-deixar-minha-loja-no-modo-pagina-em-construcao',
};

export const APP_STORE_LINKS: LocalesLinksType = {
  'es-AR': 'https://www.tiendanube.com/tienda-aplicaciones-nube',
  'es-CL': 'https://www.tiendanube.com/cl/tienda-aplicaciones-nube',
  'es-CO': 'https://www.tiendanube.com/co/tienda-aplicaciones-nube',
  'es-MX': 'https://www.tiendanube.com/mx/tienda-aplicaciones-nube',
  'pt-BR': 'https://www.nuvemshop.com.br/loja-aplicativos-nuvem',
};

export const MARKETING_APP_STORE_LINKS: LocalesLinksType = {
  'es-AR': `${APP_STORE_LINKS['es-AR']}/categorias/marketing`,
  'es-CL': `${APP_STORE_LINKS['es-CL']}/categorias/marketing`,
  'es-CO': `${APP_STORE_LINKS['es-CO']}/categorias/marketing`,
  'es-MX': `${APP_STORE_LINKS['es-MX']}/categorias/marketing`,
  'pt-BR': `${APP_STORE_LINKS['pt-BR']}/categorias/marketing`,
};

export const SHIPPING_APP_STORE_LINKS: LocalesLinksType = {
  'es-AR': `${APP_STORE_LINKS['es-AR']}/categorias/envios`,
  'es-CL': `${APP_STORE_LINKS['es-CL']}/categorias/envios`,
  'es-CO': `${APP_STORE_LINKS['es-CO']}/categorias/envios`,
  'es-MX': `${APP_STORE_LINKS['es-MX']}/categorias/envios`,
  'pt-BR': `${APP_STORE_LINKS['pt-BR']}/categorias/logistica`,
};

export const HELP_LINKS_APPS: LocalesLinksType = {
  'es-AR': 'https://ayuda.tiendanube.com/es_AR/todo-sobre-aplicaciones',
  'es-CL': 'https://ayuda.tiendanube.com/es_ES/todo-sobre-aplicaciones',
  'es-CO': 'https://ayuda.tiendanube.com/es_CO/todo-sobre-aplicaciones',
  'es-MX': 'https://ayuda.tiendanube.com/es_MX/todo-sobre-aplicaciones',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/aplicativos?utm_source=Apps&utm_medium=newadmin&utm_campaign=CAN',
};

export const SALES_CHANNELS_APP_STORE_LINKS: LocalesLinksType = {
  'es-AR': `${APP_STORE_LINKS['es-AR']}/categorias/canales-de-venta`,
  'es-CL': `${APP_STORE_LINKS['es-CL']}/categorias/canales-de-venta`,
  'es-CO': `${APP_STORE_LINKS['es-CO']}/categorias/canales-de-venta`,
  'es-MX': `${APP_STORE_LINKS['es-MX']}/categorias/canales-de-venta`,
  'pt-BR': `${APP_STORE_LINKS['pt-BR']}/categorias/canais-de-vendas`,
};

export const DROPSHIPPING_APP_STORE_LINK =
  'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/categorias/dropshipping';

export const DROPSHIPPING_APP_INSTALL_LINK = '/apps/2179/authorize';

export const ALIEXPRESS_DROPSHIPPING_APP_STORE_LINK =
  'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/dropi';

export const HELP_LINKS_MENUS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123259-menues/como-editar-el-menu-principal-de-navegacion',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123259-menues/como-editar-el-menu-principal-de-navegacion',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123259-menues/como-editar-el-menu-principal-de-navegacion',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123259-menues/como-editar-el-menu-principal-de-navegacion',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/barra-de-navegacao-rodape-do-layout/como-criar-e-organizar-o-menu-navegacao',
};

export const HELP_LINKS_ADD_CURRENCIES_LANGUAGES: LocalesLinksType = {
  'es-AR': 'https://ayuda.tiendanube.com/es_AR',
  'es-MX': 'https://ayuda.tiendanube.com/es_MX',
  'es-CL': 'https://ayuda.tiendanube.com/es_ES',
  'es-CO': 'https://ayuda.tiendanube.com/es_ES',
  'pt-BR': 'https://atendimento.nuvemshop.com.br/',
};

export const HELP_LINK_MORE_OF_LANGUAGES_CURRENCIES: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123363-idiomas-y-monedas/como-configurar-paises-idiomas-y-monedas',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123363-idiomas-y-monedas/como-configurar-paises-idiomas-y-monedas',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123363-idiomas-y-monedas/como-configurar-paises-idiomas-y-monedas',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123363-idiomas-y-monedas/como-configurar-paises-idiomas-y-monedas',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/dados-do-negocio/como-definir-idioma-e-a-moeda-da-minha-nuvemshop?utm_source=newadmin&utm_medium=online&utm_campaign=CAN',
};

export const DASHBOARD_TIPS_LINKS: LocalesLinksType = {
  'es-AR':
    'https://www.tiendanube.com/blog/estrategias-para-aumentar-tus-ventas/',
  'es-MX': 'https://www.tiendanube.com/mx/blog/estrategias-de-ventas/',
  'es-CO': 'https://www.tiendanube.com/co/blog/estrategias-de-ventas/',
  'es-CL': 'https://www.tiendanube.com/cl/blog/estrategias-de-ventas/',
  'pt-BR':
    'https://www.nuvemshop.com.br/trilhas/gestao-de-ecommerce/curso-gestao-de-vendas-online?utm_source=admin&utm_medium=panel&utm_campaign=onboarding',
};

export const HELP_LINKS_DOMAINS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/dominios/guia-configurar-el-dominio-de-la-tienda',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/dominios/guia-configurar-el-dominio-de-la-tienda',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/dominios/guia-configurar-el-dominio-de-la-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/dominios/guia-configurar-el-dominio-de-la-tienda',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/dominios?utm_source=newadmin&utm_medium=domain&utm_campaign=CAN',
};

export const TOP_ADBAR_LINKS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/como-actualizar-la-configuracion-del-dominio-de-mi-tienda-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/como-actualizar-la-configuracion-del-dominio-de-mi-tienda-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/como-actualizar-la-configuracion-del-dominio-de-mi-tienda-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/como-actualizar-la-configuracion-del-dominio-de-mi-tienda-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/como-atualizar-as-configuracoes-do-dominio-da-sua-loja-nuvemshop',
};

export const HELP_LINKS_PROMOTIONS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123465-cupones-y-promociones/como-ofrecer-promociones-y-descuentos',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/121901-cupons-e-promocoes/como-oferecer-promocoes-e-descontos',
};

export const HELP_LINKS_PROMOTIONS_FTP: LocalesLinksType = {
  'es-AR': 'https://docs-disenadores.knowledgeowl.com/help/promociones',
  'es-MX': 'https://docs-disenadores.knowledgeowl.com/help/promociones',
  'es-CO': 'https://docs-disenadores.knowledgeowl.com/help/promociones',
  'es-CL': 'https://docs-disenadores.knowledgeowl.com/help/promociones',
  'pt-BR': 'https://docs.nuvemshop.com.br/help/promotions',
};

export const HELP_LINKS_USERS_PERMISSIONS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123366-permisos-para-usuarios/como-agregar-mas-usuarios-para-que-administren-mi-tienda',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123366-permisos-para-usuarios/como-agregar-mas-usuarios-para-que-administren-mi-tienda',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/123366-permisos-para-usuarios/como-agregar-mas-usuarios-para-que-administren-mi-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123366-permisos-para-usuarios/como-agregar-mas-usuarios-para-que-administren-mi-tienda',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/gestao-de-usuarios?utm_source=newadmin&utm_medium=account&utm_campaign=CAN',
};

export const HELP_LINKS_NOTIFICATIONS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123361-emails-automaticos/cuales-son-los-emails-automaticos-que-se-envian-a-mi-cliente',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123361-emails-automaticos/cuales-son-los-emails-automaticos-que-se-envian-a-mi-cliente',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123361-emails-automaticos/cuales-son-los-emails-automaticos-que-se-envian-a-mi-cliente',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123361-emails-automaticos/cuales-son-los-emails-automaticos-que-se-envian-a-mi-cliente',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/12312-emails?utm_source=newadmin&utm_medium=online&utm_campaign=CAN',
};

export const HELP_LINKS_FREE_SHIPPING: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/envio-gratis/como-ofrecer-envio-gratis-para-aplicaciones-de-envio-y-oca',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/envio-gratis/como-ofrecer-envio-gratis-para-aplicaciones-de-envio',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/envio-gratis/como-ofrecer-envio-gratuito',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/122907-envio-gratis/como-ofrecer-envio-gratuito',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/configurando-frete-gratis/como-configurar-um-frete-gratis-na-minha-loja',
};

export const HELP_LINKS_THEMES: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/diseno/guia-personalizar-el-diseno-de-tu-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/diseno/guia-personalizar-el-diseno-de-tu-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/diseno/guia-personalizar-el-diseno-de-tu-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/diseno/guia-personalizar-el-diseno-de-tu-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/01-layout/tudo-sobre-layout',
};

export const HELP_LINKS_CUSTOMER_FILTERS: LocalesLinksType = {
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/configuracoes-gerais/como-usar-os-campos-personalizados-na-nuvemshop?utm_source=newadmin&utm_medium=customers&utm_campaign=CAN',
};

export const HELP_LINKS_THEMES_LOGO: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/124496-logo-de-tu-tienda/que-es-un-logo-y-como-agregarlo-a-mi-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/124496-logo-de-tu-tienda/que-es-un-logo-y-como-agregarlo-a-mi-tienda',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/124496-logo-de-tu-tienda/que-es-un-logo-y-como-agregarlo-a-mi-tienda',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/124496-logo-de-tu-tienda/que-es-un-logo-y-como-agregarlo-a-mi-tienda',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/banners-e-logo-do-layout/como-inserir-um-logo-no-meu-layout',
};

export const HELP_LINKS_THEMES_CSS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123225-edicion-avanzada-de-css/como-editar-el-diseno-con-codigo-css',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123225-edicion-avanzada-de-css/como-editar-el-diseno-con-codigo-css',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123225-edicion-avanzada-de-css/como-editar-el-diseno-con-codigo-css',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123225-edicion-avanzada-de-css/como-editar-el-diseno-con-codigo-css',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/personalizacao-avancada-do-layout/como-inserir-um-codigo-css',
};

export const DESIGNERS_DOCUMENTATION_LINKS: LocalesLinksType = {
  'es-AR': 'https://docs.tiendanube.com/help',
  'es-MX': 'https://docs.tiendanube.com/help',
  'es-CO': 'https://docs.tiendanube.com/help',
  'es-CL': 'https://docs.tiendanube.com/help',
  'pt-BR': 'https://docs.nuvemshop.com.br/help',
};

export const TIENDA_NUBE_THEMES_LINKS = {
  'es-AR': 'https://www.tiendanube.com/tienda-disenos-nube',
  'es-CL': 'https://www.tiendanube.com/cl/tienda-disenos-nube',
  'es-CO': 'https://www.tiendanube.com/co/tienda-disenos-nube',
  'es-MX': 'https://www.tiendanube.com/mx/tienda-disenos-nube',
  'pt-BR': 'https://www.nuvemshop.com.br/loja-layouts-nuvem',
};

export const HELP_LINKS_PAYMENTS_PROVIDERS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/medios-de-pago/guia-medios-de-pago-para-cobrar-en-tu-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/metodos-de-pago/guia-medios-de-pago-para-cobrar-en-tu-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/metodos-de-pago/guia-medios-de-pago-para-cobrar-en-tu-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/medios-de-pago/guia-medios-de-pago-para-cobrar-en-tu-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/meios-de-pagamento/guia-meios-de-pagamento-disponiveis-na-sua-nuvemshop',
};

export const HELP_LINKS_CUSTOMERS_LIST: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/123344-exportar-lista-de-clientes/como-exportar-mi-lista-de-clientes',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123344-exportar-lista-de-clientes/como-exportar-mi-lista-de-clientes',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123344-exportar-lista-de-clientes/como-exportar-mi-lista-de-clientes',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123344-exportar-lista-de-clientes/como-exportar-mi-lista-de-clientes',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/clientes?utm_source=newadmin&utm_medium=customers&utm_campaign=CAN',
};

export const HELP_LINKS_CUSTOMER_DETAILS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123342-mis-clientes/como-contactar-a-mi-cliente-desde-el-administrador-de-mi-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123342-mis-clientes/como-contactar-a-mi-cliente-desde-el-administrador-de-mi-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123342-mis-clientes/como-contactar-a-mi-cliente-desde-el-administrador-de-mi-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123342-mis-clientes/como-contactar-a-mi-cliente-desde-el-administrador-de-mi-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/clientes?utm_source=newadmin&utm_medium=customers&utm_campaign=CAN',
};

export const HELP_LINKS_CUSTOMER_CREATE: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123342-mis-clientes/como-agrego-un-nuevo-cliente',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123342-mis-clientes/como-agrego-un-nuevo-cliente',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123342-mis-clientes/como-agrego-un-nuevo-cliente',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123342-mis-clientes/como-agrego-un-nuevo-cliente',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/meus-clientes/como-cadastrar-clientes-manualmente?utm_source=newadmin&utm_medium=customers&utm_campaign=CAN',
};

export const HELP_LINKS_CUSTOMERS_MESSAGES: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/123345-consultas/como-responder-las-consultas-de-mis-clientes',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123345-consultas/como-responder-las-consultas-de-mis-clientes',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123345-consultas/como-responder-las-consultas-de-mis-clientes',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123345-consultas/como-responder-las-consultas-de-mis-clientes',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/contatos-da-minha-loja/como-administrar-os-contatos-recebidos-em-minha-loja?utm_source=newadmin&utm_medium=customers&utm_campaign=CAN',
};

export const getHelpLinkForThemeCustomization: (
  themeCode: string,
) => LocalesLinksType = (themeCode) => ({
  'es-AR': `https://ayuda.tiendanube.com/es_AR/diseno-${themeCode}/como-personalizar-mi-diseno-${themeCode}`,
  'es-MX': `https://ayuda.tiendanube.com/es_ES/diseno-${themeCode}/como-personalizar-mi-diseno-${themeCode}`,
  'es-CO': `https://ayuda.tiendanube.com/es_ES/diseno-${themeCode}/como-personalizar-mi-diseno-${themeCode}`,
  'es-CL': `https://ayuda.tiendanube.com/es_ES/diseno-${themeCode}/como-personalizar-mi-diseno-${themeCode}`,
  'pt-BR': `https://atendimento.nuvemshop.com.br/pt_BR/layout-${themeCode}/como-personalizar-o-layout-${themeCode}`,
});

export const MARKETING_AUTOMATION_APP_INSTALL_LINK: LocalesLinksType = {
  'es-AR': 'https://www.tiendanube.com/apps/738/authorize',
  'es-MX': 'https://www.tiendanube.com/apps/738/authorize',
  'es-CO': 'https://www.tiendanube.com/apps/738/authorize',
  'es-CL': 'https://www.tiendanube.com/apps/738/authorize',
  'pt-BR': 'https://www.nuvemshop.com.br/apps/738/authorize',
};

export const PRODUCT_UPDATES_LINKS: LocalesLinksType = {
  'es-AR':
    'https://lanzamientos.tiendanube.com/?utm_source=producto&utm_medium=admin&utm_campaign=product-release&utm_content=product-release_AR',
  'pt-BR':
    'https://lancamentos.nuvemshop.com.br/?utm_source=producto&utm_medium=admin&utm_campaign=product-release&utm_content=product-release_BR',
  'es-MX':
    'https://lanzamientos.tiendanube.com/?utm_source=producto&utm_medium=admin&utm_campaign=product-release&utm_content=product-release_MX',
  'es-CL':
    'https://lanzamientos.tiendanube.com/?utm_source=producto&utm_medium=admin&utm_campaign=product-release&utm_content=product-release_CH',
  'es-CO':
    'https://lanzamientos.tiendanube.com/?utm_source=producto&utm_medium=admin&utm_campaign=product-release&utm_content=product-release_CO',
};

export const HELP_LINK_SOCIAL_NETWORKS: LocalesLinksType = {
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/123412-redes-sociais/como-inserir-os-icones-das-redes-sociais-em-minha-loja?utm_source=newadmin&utm_medium=account&utm_campaign=CAN',
};

export const CAN_LINK_ABOUT_PRODUCTS: LocalesLinksType = {
  'es-AR': 'https://ayuda.tiendanube.com/es_ES/productos/todo-sobre-productos',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/productos/guia-gestionar-los-productos-de-tu-tiendanube',
  'es-CL': 'https://ayuda.tiendanube.com/es_ES/productos/todo-sobre-productos',
  'es-CO': 'https://ayuda.tiendanube.com/es_ES/productos/todo-sobre-productos',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/produtos/guia-gerenciar-os-produtos-da-sua-nuvemshop',
};

export const HELP_LINK_WEIGHT_CALCULATION_URL: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_ES/peso-y-dimensiones-del-producto/como-colocar-peso-y-dimensiones-a-mis-productos',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/peso-y-dimensiones-del-producto/como-colocar-peso-y-dimensiones-a-mis-productos',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/peso-y-dimensiones-del-producto/como-colocar-peso-y-dimensiones-a-mis-productos',
  'es-CO':
    'https://ayuda.tiendanube.com/es_ES/peso-y-dimensiones-del-producto/como-colocar-peso-y-dimensiones-a-mis-productos',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/adicionar-um-novo-produto/como-adicionar-peso-e-dimensoes-nos-produtos-da-minha-loja',
};

export const CAN_LINK_ABOUT_ORDERS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/123288-mis-ventas/como-es-el-proceso-de-venta-en-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/123288-mis-ventas/como-es-el-proceso-de-venta-en-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/123288-mis-ventas/como-es-el-proceso-de-venta-en-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/123288-mis-ventas/como-es-el-proceso-de-venta-en-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/vendas/guia-gerenciar-minhas-vendas-da-sua-nuvemshop',
};

export const HELP_LINKS_INVENTORY_LIST: LocalesLinksType = {
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/gestao-Inventario/como-administrar-o-estoque-dos-meus-produtos',
};

export const DOMAIN_SOFTBLOCK_TUTORIAL_LINK: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/como-actualizar-mi-dominio-para-evitar-inconvenientes-en-el-envio-de-emails-o-el-acceso-a-mi-tienda',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/como-actualizar-mi-dominio-para-evitar-inconvenientes-en-el-envio-de-emails-o-el-acceso-a-mi-tienda',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/como-actualizar-mi-dominio-para-evitar-inconvenientes-en-el-envio-de-emails-o-el-acceso-a-mi-tienda',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/como-actualizar-mi-dominio-para-evitar-inconvenientes-en-el-envio-de-emails-o-el-acceso-a-mi-tienda',
};
export const NUVEM_CHAT_APP_INSTALL_LINK: LocalesLinksType = {
  'es-AR': 'https://www.tiendanube.com/apps/12888/authorize',
  'es-MX': 'https://www.tiendanube.com/apps/12888/authorize',
  'es-CO': 'https://www.tiendanube.com/apps/12888/authorize',
  'es-CL': 'https://www.tiendanube.com/apps/12888/authorize',
  'pt-BR': 'https://www.nuvemshop.com.br/apps/12888/authorize',
};
