import { useState, useMemo, useEffect } from 'react';
import { PickupPointRequestDto } from '@tiendanube/common';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { PickupForm } from '../../components';
import { validationSchemaFirsStep } from '../../components/PickupForm/validationSchemaFirstStep';
import { validationSchemaSecondStep } from '../../components/PickupForm/validationSchemaSecondStep';
import usePickupPoints from '../../hooks/usePickupPoints';
import { initialValues } from '../utils';

function NewPickupPointPage(): JSX.Element {
  initialValues.country = useGetCountry();
  const { scrollToTop } = useLayoutScroll();
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.pickup.toast.create',
  );
  const [step, setStep] = useState(1);
  const {
    fetchPickupPoints,
    otherPickupPoints,
    createPickupPoint,
    isErrorList,
  } = usePickupPoints();
  const { fetchLocations, isError, locations } = useLocations();
  const { goBack } = useNavegate();

  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const handleChangeStep = (step: number) => {
    setStep(step);
    scrollToTop();
  };

  const [handleCreatePickupPoint, isSaving] = useAsyncFunc<
    PickupPointRequestDto,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        addToastProgress({
          label: t('loading'),
        });
        await createPickupPoint(data);
      }
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('success'),
        appearance: 'success',
      });
      goBack();
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('error'),
        appearance: 'danger',
      });
    },
  );

  const _validationSchemaFirsStep = useMemo(
    () => validationSchemaFirsStep([...otherPickupPoints, ...locations]),
    [locations, otherPickupPoints],
  );

  const onRetryError = () => {
    fetchLocations();
    fetchPickupPoints();
  };

  useEffect(() => {
    fetchLocations();
    fetchPickupPoints();
  }, [fetchLocations, fetchPickupPoints]);

  const {
    errors,
    values,
    isDirty,
    handleChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<PickupPointRequestDto>({
    initialValues,
    validationSchema:
      step === 1 ? _validationSchemaFirsStep : validationSchemaSecondStep,
    onSubmit: step === 1 ? () => handleChangeStep(2) : handleCreatePickupPoint,
  });

  return (
    <PickupForm
      isNew
      step={step}
      type="pickup"
      isSaving={isSaving}
      errors={errors}
      values={values}
      isDirty={isDirty}
      isError={isError || isErrorList}
      handleSave={handleOnSubmit}
      handleChange={handleChange}
      handleChangeStep={handleChangeStep}
      setFieldValue={setFieldValue}
      onRetryError={onRetryError}
    />
  );
}

export default NewPickupPointPage;
