import { Box, Icon, Text, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { CurrencyType, FulfillmentOrdersResponseDto } from '@tiendanube/common';
import { FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum } from '@tiendanube/common/src/enums';
import { DataList } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import Strikethrough from 'commons/components/Strikethrough';
import { useTranslationLanguage } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface ShippingCostsProps {
  shippingDetail?: string;
  shipping?: number | null;
  previousShipping?: number | null;
  currency: CurrencyType;
  fulfillmentOrders?: FulfillmentOrdersResponseDto[];
}

function ShippingCosts({
  shippingDetail,
  shipping,
  previousShipping,
  currency,
  fulfillmentOrders,
}: Readonly<ShippingCostsProps>): JSX.Element {
  const t = useTranslationOrders();
  const { isAvailable: hasEditOrders } = useIsEditOrdersEnabled();
  const language = useTranslationLanguage();

  // Filtra fulfillmentOrders para excluir el tipo no deseado
  const filteredFulfillmentOrders = fulfillmentOrders?.filter(
    ({ shipping }) =>
      shipping.type !== FulfillmentPreferenceTypeEnum.NON_SHIPPABLE,
  );

  return (
    <>
      {!!filteredFulfillmentOrders && filteredFulfillmentOrders.length >= 1 && (
        <Box id="shippingCost">
          {filteredFulfillmentOrders.map(({ number, shipping }) => {
            const hasCostDiff =
              hasEditOrders &&
              shipping.previousMerchantCost &&
              shipping.previousMerchantCost?.value !==
                shipping.merchantCost.value;

            return (
              <DataList.Row id={`shippingCost${number}`} key={number}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flex="1"
                  paddingX="2"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="row" flex="7" gap="1">
                    <Text>
                      {t(
                        shipping.option.name
                          ? 'orderSummaryCard.tableAmounts.fulfillmentShippingDetail'
                          : 'orderSummaryCard.tableAmounts.fulfillmentShippingDetailNoName',
                        {
                          number,
                          shippingDetail:
                            shipping.option.name ?? shippingDetail,
                        },
                      )}
                    </Text>
                    {hasCostDiff && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Tooltip
                          position="right"
                          content={t(
                            'orderSummaryCard.tableAmounts.newShippingCost',
                          )}
                        >
                          <Icon
                            color="primary-textLow"
                            source={<InfoCircleIcon />}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="1"
                    flex="3"
                    justifyContent="flex-end"
                    paddingLeft="2"
                    alignItems="center"
                    alignSelf="center"
                  >
                    {hasCostDiff && (
                      <Text fontSize="base" color="neutral-textLow">
                        <Strikethrough>
                          {formatCurrency(
                            shipping.previousMerchantCost.value ?? 0,
                            currency,
                            language,
                          )}
                        </Strikethrough>
                      </Text>
                    )}
                    <CurrencyPrice
                      price={shipping.merchantCost.value}
                      currency={currency}
                      block
                      textAlign="right"
                    />
                  </Box>
                </Box>
              </DataList.Row>
            );
          })}
        </Box>
      )}
      {(filteredFulfillmentOrders?.length === 0 ||
        !filteredFulfillmentOrders) &&
        shipping !== null &&
        shipping !== undefined &&
        shipping >= 0 &&
        shippingDetail !== null && (
          <DataList.Row id="shippingCost">
            <DataList.Cell width={70}>
              <Text>
                {shippingDetail
                  ? `${t('orderSummaryCard.tableAmounts.shippingDetail', {
                      shippingDetail,
                    })}`
                  : `${t('orderSummaryCard.tableAmounts.shipping')}`}
              </Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <Box display="flex" justifyContent="flex-end" gap="1">
                {hasEditOrders &&
                  previousShipping !== shipping &&
                  !!previousShipping && (
                    <Text fontSize="base" color="neutral-textLow">
                      <Strikethrough>
                        {formatCurrency(
                          previousShipping ?? 0,
                          currency,
                          language,
                        )}
                      </Strikethrough>
                    </Text>
                  )}
                <CurrencyPrice
                  price={shipping}
                  currency={currency}
                  block
                  textAlign="right"
                />
              </Box>
            </DataList.Cell>
          </DataList.Row>
        )}
    </>
  );
}

export default ShippingCosts;
