import { Box, Card, Link, Select } from '@nimbus-ds/components';
import { AppsIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { CalloutCard, FormField } from '@nimbus-ds/patterns';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionTypes } from 'domains/Marketing/Promotions/pages/constants';
import { usePromotionTypesQuery } from 'domains/Marketing/Promotions/promotionsApi';
import { BuyXPayY } from './BuyXPayY';
import { ProgressiveDiscounts } from './ProgressiveDiscounts';
import { PromotionFormProps } from '../PromotionForm';

export function DiscountTypeCard({
  values,
  errors,
  onChangeSelect,
  onChangeProgressiveDiscounts,
  onChangeBuyXPayY,
}: Readonly<
  Pick<
    PromotionFormProps,
    | 'values'
    | 'errors'
    | 'onChangeSelect'
    | 'onChangeProgressiveDiscounts'
    | 'onChangeBuyXPayY'
  >
>) {
  const t = useTranslationMarketing('promotions.settingsPage.discountType');

  const { data: discountTypes, isLoading, isError } = usePromotionTypesQuery();

  const { promotionType } = values;

  const discountTypeSelected = discountTypes?.find(
    (current) => current.type === promotionType,
  );

  const external = discountTypeSelected?.external || false;

  const isCrossSelling = promotionType === PromotionTypes.CROSS_SELLING;

  if (isLoading || isError) return null;

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="3" marginBottom="1">
          <FormField.Select
            id="discountTypes"
            name="promotionType"
            onChange={onChangeSelect}
            value={promotionType}
          >
            {discountTypes?.map(({ type, description }) => (
              <Select.Option key={type} label={description} value={type} />
            ))}
          </FormField.Select>
          {external && (
            <CalloutCard
              icon={AppsIcon}
              appearance="primary"
              subtitle={external.subtitle || ''}
              title={external.title || ''}
              link={
                <Link
                  appearance="primary"
                  as="a"
                  href={external.linkUrl}
                  target="_link"
                >
                  {external.linkDescription}
                </Link>
              }
            />
          )}
          <ProgressiveDiscounts
            values={values}
            errors={errors}
            onChangeProgressiveDiscounts={onChangeProgressiveDiscounts}
          />
          <BuyXPayY
            values={values}
            errors={errors}
            onChangeBuyXPayY={onChangeBuyXPayY}
          />
          {isCrossSelling && (
            <CalloutCard
              icon={InfoCircleIcon}
              appearance="primary"
              subtitle={t('crossSelling.text')}
              link={
                <Link
                  fontSize="caption"
                  appearance="neutral"
                  textDecoration="underline"
                  as="a"
                  href="#"
                  target="_link"
                >
                  {t('crossSelling.buttonText')}
                </Link>
              }
              title=""
            />
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}
