/* eslint-disable max-statements */
import { useCallback, useEffect, useState } from 'react';
import { Text } from '@nimbus-ds/components';
import dayjs from 'dayjs';
import { generatePath } from 'react-router';
import { ErrorState, Stack } from '@tiendanube/components';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import { useToastProgress } from 'App/components/ToastProgressContext';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
} from 'commons/components';
import { useAsyncFunc, useBoolean, useModal, useToast } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import goToAdmin from 'commons/utils/gotToAdmin';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import { billingRoutes, useTranslationBilling } from 'domains/Billing';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  useAfterPaymentRedirection,
  useTrackBillingPayOrderCheckoutFinish,
} from 'domains/Billing/Checkout/hooks';
import { useHasMigratedToBillingEngine } from 'domains/Billing/hooks';
import { trackingBillingGoToPayPlanClick } from 'domains/Billing/tracking';
import {
  ComparisonPlansCards,
  PlanCardSkeleton,
  ComparisonPlansCardsDesktopSkeleton,
  ComparisonTable,
  ComparisonTableMobileSkeleton,
  ComparisonTableDesktopSkeleton,
  ChangePlanModal,
  ChangePlanAlert,
} from './components';
import { SelectedPlan } from './components/ComparisonPlansCards/components/PlanCard/types';
import { useBillingPlans } from '../../hooks';

export type ChangeType = 'basic' | 'downgrade' | 'upgrade';

function BillingPlans(): JSX.Element {
  const t = useTranslationBilling();
  const { addToast } = useToast();
  const { isShowMenu, showMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu, showMenu: showAsideMenu } =
    useDesktopMenuContext();
  const { goBack } = useNavegate();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const { status: hasMigratedStatus, fetchStoreSubscriptionIsEnabled } =
    useHasMigratedToBillingEngine();
  const {
    status: billingPlansStatus,
    features,
    plansList,
    currentPlanId,
    currentPlanLevel,
    fetchBillingPlans,
    updateBillingPlan,
    openUpgradedPlanModal,
  } = useBillingPlans();
  const { isLoading, isError, isSuccess } = combineStatus(
    billingPlansStatus,
    hasMigratedStatus,
  );
  const retryLoad = useCallback(() => {
    fetchStoreSubscriptionIsEnabled();
    fetchBillingPlans();
  }, [fetchBillingPlans, fetchStoreSubscriptionIsEnabled]);
  const storePaymentDiscount = useGetStorePaymentDiscount();
  const { addAfterPayActionToPath } = useAfterPaymentRedirection();

  const { goTo } = useNavegate();

  useTrackBillingPayOrderCheckoutFinish();

  const [isOpenChangePlanModal, openChangePlanModal, closeChangePlanModal] =
    useModal();
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>();
  const [changeType, setChangeType] = useState<ChangeType>();
  const [showAlert, setShowAlert] = useState(false);
  const [startPlanChangeFlow, initializePlanChangeFlow, resetPlanChangeFlow] =
    useBoolean(false);

  const [handleOnConfirmChangePlan, , error, clearError] = useAsyncFunc(
    useCallback(async () => {
      if (!selectedPlan) return;
      addToastProgress({
        label:
          changeType === 'upgrade'
            ? t('billingPlansPage.toast.loadingUpgrade')
            : t('billingPlansPage.toast.loadingDowngrade'),
      });
      const { needRedirect, paymentId, payOrderId } = await updateBillingPlan(
        selectedPlan.id,
      );

      if (needRedirect) {
        if (paymentId) {
          goToAdmin(`account/checkout/payment/${paymentId}`)();
          return;
        }
        payOrderId &&
          goTo(
            addAfterPayActionToPath(
              generatePath(CHECKOUT_ROUTES.choosePaymentMethodForPayOrder, {
                payOrderId,
              }),
              undefined,
              'plan-cost-upgrade',
            ),
          );
      }

      if (changeType === 'downgrade') {
        addToast({
          label: t('billingPlansPage.toast.successDowngrade'),
          appearance: 'success',
        });
      }

      if (changeType === 'upgrade') openUpgradedPlanModal();

      setShowAlert(true);
      closeToastProgress();
      closeChangePlanModal();

      if (storePaymentDiscount && changeType === 'upgrade') {
        goTo(billingRoutes.plansAndSubscriptions);
      }
    }, [
      addAfterPayActionToPath,
      addToast,
      addToastProgress,
      changeType,
      closeChangePlanModal,
      closeToastProgress,
      goTo,
      openUpgradedPlanModal,
      selectedPlan,
      storePaymentDiscount,
      t,
      updateBillingPlan,
    ]),
  );

  useEffect(() => {
    if (error) {
      addToast({
        label:
          changeType === 'upgrade'
            ? t('billingPlansPage.toast.errorUpgrade')
            : t('billingPlansPage.toast.errorDowngrade'),
        appearance: 'danger',
      });
      closeChangePlanModal();
      clearError();
    }
  }, [error, changeType, clearError, addToast, closeChangePlanModal, t]);

  const handleOnPlanSelected = (
    position: 'top' | 'bottom',
    plan: SelectedPlan,
  ): void => {
    if (plan.id === currentPlanId) {
      trackingBillingGoToPayPlanClick(position, newPlanName);
      return goToAdmin('account/checkout/start/')();
    }

    setShowAlert(false);
    setSelectedPlan(plan);
    setChangeType(
      plan.level === 0 && plan.isFree
        ? 'basic'
        : plan.level > currentPlanLevel
        ? 'upgrade'
        : 'downgrade',
    );
    initializePlanChangeFlow(); // This will trigger the effect below to open the modal or change plan immediately
  };

  const handleOnCancelChangePlan = (): void => {
    closeChangePlanModal();
    setSelectedPlan(undefined);
  };

  useEffect(() => {
    if (!startPlanChangeFlow) return;
    resetPlanChangeFlow();

    // If there's a discount, skip the modal and chanmge plan immediately
    if (storePaymentDiscount && changeType === 'upgrade') {
      handleOnConfirmChangePlan();
    } else {
      openChangePlanModal();
    }
  }, [
    startPlanChangeFlow,
    storePaymentDiscount,
    changeType,
    resetPlanChangeFlow,
    openChangePlanModal,
    handleOnConfirmChangePlan,
  ]);

  const newPlanName = plansList.find((p) => p.id === selectedPlan?.id)?.name;
  const currentPlanName = plansList.find((p) => p.id === currentPlanId)?.name;

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('billingPlansPage.title')} />}
      headerTop={
        !isShowMenu &&
        !isShowAsideMenu &&
        !!storePaymentDiscount && (
          <HeaderTop
            navigation={{
              onClick() {
                goBack();
                showMenu();
                showAsideMenu();
              },
              children: t('billingPlansPage.back'),
            }}
          />
        )
      }
    >
      <ChangePlanAlert
        show={showAlert}
        changeType={changeType}
        newPlanName={newPlanName}
      />
      {isLoading && (
        <Stack column align="stretch" spacing="loose">
          <Stack.Item>
            <Responsive
              mobile={<PlanCardSkeleton />}
              desktop={<ComparisonPlansCardsDesktopSkeleton />}
            />
          </Stack.Item>
          <Stack.Item>
            <Responsive
              mobile={<ComparisonTableMobileSkeleton />}
              desktop={<ComparisonTableDesktopSkeleton />}
            />
          </Stack.Item>
        </Stack>
      )}
      {isSuccess && (
        <Stack column align="stretch" spacing="loose">
          <Stack.Item>
            <ComparisonPlansCards
              onSelectPlan={(plan) => handleOnPlanSelected('top', plan)}
            />
          </Stack.Item>
          <Stack.Item>
            {features.length !== 0 && (
              <ComparisonTable
                onSelectPlan={(plan) => handleOnPlanSelected('bottom', plan)}
              />
            )}
          </Stack.Item>
        </Stack>
      )}
      {isError && (
        <ErrorState
          title={t('billingPlansPage.errorState.title')}
          action={{
            children: t('billingPlansPage.errorState.retry'),
            onClick: retryLoad,
          }}
        />
      )}
      {storePaymentDiscount && (
        <>
          <Text textAlign="center">
            {t('billingPlansPage.paymentDiscountLegal', {
              percentage: storePaymentDiscount.percentage,
              expirationDate: dayjs(storePaymentDiscount.expirationDate)
                .tz('UTC')
                .format('DD/MM'),
            })}
          </Text>
          <Text textAlign="center">
            {t('billingPlansPage.paymentDiscountLegal2')}
          </Text>
          <Text textAlign="center">
            {t('billingPlansPage.paymentDiscountLegal3')}
          </Text>
        </>
      )}

      <ChangePlanModal
        show={isOpenChangePlanModal}
        changeType={changeType}
        onConfirm={handleOnConfirmChangePlan}
        onCancel={handleOnCancelChangePlan}
        newPlan={newPlanName}
        currentPlan={currentPlanName}
      />
    </IonPageStratus>
  );
}

export default BillingPlans;
