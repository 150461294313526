import uniqBy from 'lodash.uniqby';
import {
  GetRelatedProductsResponseDto,
  i18nResponseDto,
  MetafieldSelectedsOrErrorListV2Type,
  ProductDetailsResponseDto,
  ProductImageResponseDto,
  ProductMediaResponseDto,
  VariantResponseDto,
} from '@tiendanube/common';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { ImageGalleryState } from '../../../components/ImageGallery/ImageGallery';
import { Attributes, Variant } from '../../../components/Variants/types';
import { ProductFormState } from '../../../hooks/useProductForm';
import {
  buildNewVariant,
  createVariantsStructure,
} from '../../../NewProductPage/productFormUtils';

const attributesDigest = (
  attributes: i18nResponseDto[],
  variants: VariantResponseDto[],
  language: LanguagesType,
): Attributes => {
  if (!Array.isArray(attributes)) return {};

  // This is for unnamed variants (attributes empty and variants without name)
  if (
    attributes.length === 0 &&
    variants.length > 0 &&
    variants[0].values.length > 0
  ) {
    return {
      '': {
        name: { [language]: '' },
        values: uniqBy(
          variants.map((variant) => variant.values[0]),
          language,
        ),
      },
    };
  }

  const newAttributes = attributes.reduce((acc, current, index) => {
    const name = current[language] as string;
    acc[name] = {
      name: current,
      values: uniqBy(
        variants.map((variant) => variant.values[index]),
        language,
      ),
    };
    return acc;
  }, {});

  return newAttributes as Attributes;
};

const variantsDigest = (
  attributes: Attributes,
  variants: VariantResponseDto[],
  images: ProductImageResponseDto[],
  language: LanguagesType,
  languages: LanguagesType[],
  hasShippingMultiCD: boolean,
): Variant[] => {
  const activeVariants = variants.map((variant) => {
    const imageUrl = images.find((v) => v.id === variant.image_id)?.src;
    const pricePublished =
      variant.price !== null || variant.promotional_price !== null;
    const price = pricePublished && variant.price ? String(variant.price) : '';
    const promotionalPrice =
      pricePublished && variant.promotional_price
        ? String(variant.promotional_price)
        : '';
    const costPrice = variant.cost ? String(variant.cost) : '';
    return {
      id: variant.id,
      name: variant.values
        .map((value: LanguageInterface) => value[language])
        .join(' '),
      productId: variant.product_id,
      pricePublished,
      promotionalPrice,
      price,
      sku: variant.sku,
      barcode: variant.barcode,
      isInfinite: variant.stock === null,
      stock: variant.stock !== null ? String(variant.stock) : null,
      weight: String(variant.weight),
      depth: String(variant.depth),
      width: String(variant.width),
      height: String(variant.height),
      imageUrl: imageUrl || '',
      imageId: variant.image_id || '',
      mpn: variant.mpn,
      ageGroup: variant.age_group,
      gender: variant.gender,
      values: variant.values,
      locationId: hasShippingMultiCD ? variant.locationId : undefined,
      costPrice,
      metafields: variant.metafields as MetafieldSelectedsOrErrorListV2Type,
      inventory_levels: variant.inventory_levels,
      show: true,
    };
  });

  const generatedVariants = buildNewVariant(
    createVariantsStructure(attributes, language, languages),
    activeVariants,
    language,
  );

  const newVariants = generatedVariants.map((variant) => ({
    ...variant,
    show: !!variant.id,
  }));

  return newVariants as Variant[];
};

const mediaDigest = (
  medias: ProductMediaResponseDto[] = [],
): ImageGalleryState[] =>
  medias.map(({ id, src, alt = {}, media_type }) => ({
    id,
    src,
    alt,
    publicUrl: src,
    isLoading: false,
    isError: false,
    isEdited: false,
    name: '',
    type: '',
    size: 0,
    mimeType: '',
    mediaType: media_type,
  }));

const imagesDigest = (images: ProductImageResponseDto[]): ImageGalleryState[] =>
  images.map(({ id, src, alt = {} }) => ({
    id,
    src,
    alt,
    publicUrl: src,
    isLoading: false,
    isError: false,
    isEdited: false,
    name: '',
    type: '',
    size: 0,
    mimeType: '',
    mediaType: 'image',
  }));

const relatedProductsDigest = (
  relatedProducts?: GetRelatedProductsResponseDto,
) => {
  if (!relatedProducts) {
    return undefined;
  }

  const alternative = relatedProducts.alternative.map((relatedProduct) => ({
    id: relatedProduct.id,
    name: relatedProduct.name,
    imageUrl: relatedProduct.imageUrl,
    visible: relatedProduct.published,
  }));
  const complementary = relatedProducts.complementary.map((relatedProduct) => ({
    id: relatedProduct.id,
    name: relatedProduct.name,
    imageUrl: relatedProduct.imageUrl,
    visible: relatedProduct.published,
  }));

  const status = relatedProducts.status;

  return { alternative, complementary, status };
};

export const productDigest = (
  product: ProductDetailsResponseDto,
  language: LanguagesType,
  languages: LanguagesType[],
  hasShippingMultiCD: boolean,
): ProductFormState => {
  const {
    id,
    requires_shipping,
    name,
    description,
    images,
    media,
    video_url,
    variants,
    attributes,
    free_shipping,
    canonical_url,
    brand,
    seo_description,
    seo_title,
    handle,
    tags,
    sectionCodes,
    productMetafields,
    relatedProducts,
  } = product;

  return {
    id,
    isDigital: !requires_shipping,
    name,
    description,
    categories: product.categories.map((cat) => cat.id),
    published: product.published,
    images: imagesDigest(images), // POST-ROLLOUT: remove when the sunset of new-admin-catalog-upload-videos is done
    media: mediaDigest(media),
    videoUrl: video_url,
    attributes: attributesDigest(attributes, variants, language),
    variants: variantsDigest(
      attributesDigest(attributes, variants, language),
      variants,
      images,
      language,
      languages,
      hasShippingMultiCD,
    ),
    freeShipping: free_shipping,
    canonicalUrl: canonical_url,
    brand,
    seoDescription: seo_description,
    seoTitle: seo_title,
    seoUrl: handle,
    tags: tags ? tags.split(', ') : [],
    sectionCodes,
    relatedProducts: relatedProductsDigest(relatedProducts),
    productMetafields:
      productMetafields !== 'error'
        ? productMetafields
        : {
            internals: [],
            fromApi: [],
          },
  };
};
