import { useEffect } from 'react';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import { useTopAdBar } from 'App/components/TopAdBar/useTopAdBar';
import useTopBar from 'App/components/Topbar/useTopBar';
import { BE_APP_SUBSCRIPTION_ENABLED, REVAMP_MENU_V1 } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { useHasTags, useNavegate, useQuery } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  FirstPaymentModal,
  PlansAndSubscriptionsV1,
  PlansAndSubscriptionsV2,
} from './components';
import { FROM_PAYMENT_QUERY } from '../../checkoutRoutes';
import { useTrackBillingPayOrderCheckoutFinish } from '../../hooks';
import { useAssertStore } from '../../hooks/useAssertStore';
import {
  trackingBillingViewCheckoutCharges,
  trackingBillingViewCheckoutChargesAfterPayment,
  useCheckoutTracking,
} from '../../tracking';

function PlansAndSubscriptions(): JSX.Element {
  const t = useTranslationBilling();
  const hideAdBars = useQuery().get('hideAdBars') === 'true';
  const { hideTopAdBar, showTopAdBar } = useTopAdBar();
  const { hasSearchParam, goBack } = useNavegate();
  const { isShowMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu } = useDesktopMenuContext();
  const backNavigation = {
    onClick: goBack,
    children:
      !isShowMenu && !isShowAsideMenu ? t('billingPlansPage.back') : undefined,
  };
  const fromPayment = hasSearchParam(FROM_PAYMENT_QUERY);

  useAssertStore();
  const logEvent = useCheckoutTracking();

  useEffect(() => {
    if (fromPayment) {
      trackingBillingViewCheckoutChargesAfterPayment(logEvent);
    } else {
      trackingBillingViewCheckoutCharges(logEvent);
    }
  }, [fromPayment, logEvent]);

  useTrackBillingPayOrderCheckoutFinish();

  const [appsEnabled, isRevampMenu] = useHasTags([
    BE_APP_SUBSCRIPTION_ENABLED,
    REVAMP_MENU_V1,
  ]);

  useEffect(() => {
    if (hideAdBars) hideTopAdBar();
    return () => {
      showTopAdBar();
    };
  }, [hideAdBars, hideTopAdBar, showTopAdBar]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent
          title={t(
            `checkout.plansAndSubscriptions.${
              isRevampMenu ? 'revampTitle' : 'title'
            }`,
          )}
          subtitle={t('checkout.plansAndSubscriptions.subTitle')}
        />
      }
      width="medium"
    >
      {!hideAdBars && <FirstPaymentModal />}

      {appsEnabled ? <PlansAndSubscriptionsV2 /> : <PlansAndSubscriptionsV1 />}

      <HelpLink
        linkURL={t('checkout.plansAndSubscriptions.helpLink.url')}
        previousValue="checkout_charges"
        title={t('checkout.plansAndSubscriptions.helpLink.text')}
        currentViewTracking={t(
          'checkout.plansAndSubscriptions.helpLink.amplitudeName',
        )}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default PlansAndSubscriptions;
