import { PROJECT_SHIVA_TAG } from 'App/featuresFlags';
import {
  useGetIsFreemium,
  useGetIsFreePlan,
  useGetTags,
} from 'domains/Auth/hooks';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import useNeedsCloudflareMigration from 'domains/Auth/hooks/useNeedsCloudflareMigration';
import useCloudflareBlocked from 'domains/Dashboard/hooks/useCloudflareBlocked';
import { ExpiredStoreAdBar } from './ExpiredStoreAdBar';
import { NeedsCloudflareMigration } from './NeedsCloudflareMigration';
import { PostUpsellPaymentInvitation } from './PostUpsellPaymentInvitation';
import { StorePaymentDiscount } from './StorePaymentDiscount';
import { useExpirationBanner } from '../ExpirationBanner/useExpirationBanner';

export function TopAdBar() {
  const needsCloudflareMigration = useNeedsCloudflareMigration();
  const { showExpirationBanner, isExpired } = useExpirationBanner();
  const { cloudflareBlocked } = useCloudflareBlocked();
  const { isFreePlan, isTrial } = useGetIsFreePlan();
  const isFreemium = useGetIsFreemium();
  const storePaymentDiscount = useGetStorePaymentDiscount();
  const tags = useGetTags();

  const isShivaProject = tags.includes(PROJECT_SHIVA_TAG);

  if (!isTrial && isExpired && showExpirationBanner) {
    return <ExpiredStoreAdBar />;
  }

  if (storePaymentDiscount) {
    return (
      <StorePaymentDiscount
        percentage={storePaymentDiscount.percentage}
        expirationDate={new Date(storePaymentDiscount.expirationDate)}
      />
    );
  }

  if (isFreemium && isTrial && !isFreePlan && !isShivaProject) {
    return <PostUpsellPaymentInvitation />;
  }

  if (needsCloudflareMigration && !cloudflareBlocked) {
    return <NeedsCloudflareMigration />;
  }

  return null;
}
