import {
  CustomerResponseDto,
  CustomersOrdersResponseDto,
  CustomersDetailsResponseDto,
  CustomersResponseDto,
  PaginationResponseDto,
  ResultPaginationResponseDto,
  CustomersMessagesResponseDto,
} from '@tiendanube/common';
import { CustomersFiltersType } from '../customersServices';

export type GetCustomersType = (
  f: CustomersFiltersType,
) => Promise<CustomerResponseDto[]>;

export type GetCustomerByIdType = (id: string) => Promise<CustomerResponseDto>;

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export type CustomersMessagesType =
  | 'contact'
  | 'newsletter'
  | 'newsletter-popup'
  | 'order_cancellation';

export type CustomerEntitiesType = Record<string, CustomersResponseDto>;

export type CustomerMessagesEntitiesType = Record<
  string,
  CustomersMessagesResponseDto
>;

export interface CustomerEntityDetails {
  status: statusType;
  data: CustomersDetailsResponseDto;
}

export interface CustomerEntityDetailsOrders {
  status: statusType;
  data: ResultPaginationResponseDto<CustomersOrdersResponseDto[]>;
  ids: string[];
}

export interface CustomerEntityMessages {
  status: statusType;
  entities: CustomerMessagesEntitiesType;
  ids: string[];
}

export interface CustomersInterface {
  status: statusType;
  refreshStatus: statusType;
  exportStatus: statusType;
  exportToken: string;
  currentRequestID: string;
  entities: CustomerEntitiesType;
  ids: string[];
  entityDetails: CustomerEntityDetails;
  customerOrders: CustomerEntityDetailsOrders;
  customerMessages: CustomerEntityMessages;
  pagination: PaginationResponseDto;
  filters: CustomersFiltersType;
  sendEmail: statusType;
  isEdited: boolean;
}
