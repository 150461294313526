import { useEffect, useState } from 'react';
import { Alert } from '@nimbus-ds/components';
import { PickupPointResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationsAsPickup from './components/LocationsAsPickup';
import OtherPickupPoints from './components/OtherPickupPoints';
import PickupPointStatusModal from './components/PickupPointStatusModal';
import usePickupPoints from './hooks/usePickupPoints';

function PickupPoints() {
  const t = useTranslationShipping('deliveryMethods');
  const { fetchLocations } = useLocations();
  const { fetchPickupPoints } = usePickupPoints();

  const [selectedPickupPoint, setSelectedPickupPoint] =
    useState<PickupPointResponseDto | null>(null);
  const [isOpen, open, close] = useModal();
  const [showAlert, setShowAlert] = useState<boolean>(true);

  const handleUpdateStatus = (pickupPoint: PickupPointResponseDto) => {
    setSelectedPickupPoint(pickupPoint);
    open();
  };

  useEffect(() => {
    fetchLocations();
    fetchPickupPoints();
  }, [fetchLocations, fetchPickupPoints]);

  const onRemove = () => {
    setShowAlert(false);
  };

  return (
    <Stack column spacing="loose">
      <Alert
        show={showAlert}
        appearance="warning"
        title={t('pickupPoints.alert.title')}
        onRemove={onRemove}
      >
        {t('pickupPoints.alert.description')}
      </Alert>
      <LocationsAsPickup handleUpdateStatus={handleUpdateStatus} />
      <OtherPickupPoints handleUpdateStatus={handleUpdateStatus} />
      {isOpen && !!selectedPickupPoint && (
        <PickupPointStatusModal
          pickupPoint={selectedPickupPoint}
          onClose={close}
        />
      )}
    </Stack>
  );
}

export default PickupPoints;
