import { useCallback } from 'react';
import { Box } from '@nimbus-ds/components';
import {
  defaultFilters,
  FiltersParamsType,
  FiltersType,
} from 'domains/Orders/Orders/ordersService/constants';
import { SavedSearch } from './SavedSearch/SavedSearch';
import { SavedSearchInterface } from './types';

interface SavedSearchesProperties {
  savedSearches: SavedSearchInterface[];
  selectedSavedSearch: string; // TODO(orders/tifany): When custom saved searches exist, we must decide if it should be an id or the code is enough
  onSavedSearchClick: (newFilters: FiltersParamsType) => void;
  setSelectedSavedSearch: (code: string) => void;
}

export function SavedSearches({
  savedSearches,
  selectedSavedSearch,
  onSavedSearchClick,
  setSelectedSavedSearch,
}: SavedSearchesProperties) {
  const onClick = useCallback(
    (savedSearch) => {
      const transformedFilters: FiltersType = {
        ...defaultFilters,
        ...savedSearch.filters,
      };
      onSavedSearchClick(transformedFilters);
      setSelectedSavedSearch(savedSearch.code);
    },
    [onSavedSearchClick, setSelectedSavedSearch],
  );

  return (
    <Box flexDirection="row" display="flex">
      {savedSearches.map((savedSearch) => (
        <SavedSearch
          key={savedSearch.code}
          isSelected={savedSearch.code === selectedSavedSearch}
          savedSearch={savedSearch}
          onSavedSearchClick={onClick}
        />
      ))}
    </Box>
  );
}
