import { Modal, Box, Button, Icon } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import {
  PlansListResponseDto,
  TypeFeaturesPlansListResponseDto,
} from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import { PATH } from 'App/components/Menu/components/utils';
import { SubmitButton } from 'commons/components';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useAdminLinks,
  useGoToPlans,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import FreeTrialAlert from './components/FreeTrialAlert';
import UpsellBodyCard from './components/UpsellBodyCard';
import { BILLING_ROUTES } from '../billingRoutes';
import {
  trackingBillingUpsellModalClose,
  trackingBillingUpsellPlanChange,
  trackingBillingUpsellPlansPageClick,
} from '../tracking';
import useTranslationBilling from '../useTranslationBilling';

export interface UpsellFlowModalProps {
  show?: boolean;
  title?: string;
  featureKey: string;
  currentPlan: PlansListResponseDto;
  newPlan: PlansListResponseDto;
  ctasDisabled?: boolean;
  bulletProgress?: number;
  planChangeComplete?: boolean;
  trackingSource?: string;
  asAside?: boolean;
  confirmationCtaText?: string;
  onDismiss: () => void;
  onAccept: () => void;
  onFinalize: () => void;
  onSeePlans?: () => void;
  confirmationTextCta?: string;
  planChangeInProgress?: boolean;
}

export function UpsellFlowModal({
  show = true,
  title,
  featureKey,
  currentPlan,
  newPlan,
  ctasDisabled = false,
  bulletProgress = 0,
  planChangeComplete = false,
  trackingSource,
  asAside = false,
  onDismiss,
  onAccept,
  onFinalize,
  onSeePlans,
  confirmationTextCta,
  planChangeInProgress = false,
}: Readonly<UpsellFlowModalProps>) {
  const t = useTranslationBilling();
  const isMobileDevice = useIsMobileDevice();
  const { getOldAdminLink, getNewAdminLink } = useAdminLinks();
  const { isMobileNonFreemium } = useGoToPlans();
  const showFinalizeCta =
    planChangeComplete && bulletProgress === newPlan.upsellBullets.length;

  const currentPlanValue: TypeFeaturesPlansListResponseDto =
    currentPlan.featureKeys[featureKey];
  const newPlanValue: TypeFeaturesPlansListResponseDto =
    newPlan.featureKeys[featureKey];

  const onCloseModal = () => {
    if (planChangeComplete) {
      onFinalize();
      return;
    }

    trackingBillingUpsellModalClose({
      featureKey,
      currentPlan: currentPlan.name,
      newPlan: newPlan.name,
      currentPlanValue,
      newPlanValue,
      source: trackingSource,
    });
    onDismiss();
  };

  const onClickAccept = () => {
    trackingBillingUpsellPlanChange({
      featureKey,
      currentPlan: currentPlan.name,
      newPlan: newPlan.name,
      currentPlanValue,
      newPlanValue,
      source: trackingSource,
    });
    onAccept();
  };

  const goToPlansPage = () => {
    trackingBillingUpsellPlansPageClick({
      featureKey,
      currentPlan: currentPlan.name,
      newPlan: newPlan.name,
      currentPlanValue,
      newPlanValue,
      source: trackingSource,
    });
    goToAdmin(PATH.plans)();
  };

  const getTitle = () => {
    if (planChangeComplete) {
      return t('upsellFlow.modal.titleChangeComplete');
    }
    if (planChangeInProgress) {
      return t('upsellFlow.modal.titleChangeInProgress');
    }
    return title || t('upsellFlow.modal.title');
  };

  const modalBody = (
    <UpsellBodyCard
      newPlan={newPlan}
      currentPlan={currentPlan}
      bulletProgress={bulletProgress}
      planChangeInProgress={planChangeInProgress}
      goToPlansPage={goToPlansPage}
      planChangeComplete={!planChangeInProgress && planChangeComplete}
    />
  );

  const modalCtas =
    showFinalizeCta || planChangeInProgress ? (
      <SubmitButton
        appearance="primary"
        onClick={onFinalize}
        isLoading={planChangeInProgress}
      >
        {confirmationTextCta || t('upsellFlow.confimration.cta')}
      </SubmitButton>
    ) : (
      <>
        {!planChangeInProgress && (
          <Button onClick={onCloseModal} disabled={ctasDisabled}>
            {t('upsellFlow.modal.ctaCancel')}
          </Button>
        )}
        {isMobileDevice ? (
          <Button
            appearance="primary"
            as="a"
            onClick={() => onSeePlans?.()}
            href={
              isMobileNonFreemium
                ? getOldAdminLink(BILLING_ROUTES.plans)
                : getNewAdminLink(BILLING_ROUTES.plans)
            }
            target="_blank"
          >
            {t('upsellFlow.modal.seePlans')}{' '}
            <Icon color="currentColor" source={<ExternalLinkIcon />} />
          </Button>
        ) : (
          <SubmitButton onClick={onClickAccept} isLoading={ctasDisabled}>
            {t('upsellFlow.modal.ctaAccept')}
          </SubmitButton>
        )}
      </>
    );

  const alert =
    planChangeInProgress || planChangeComplete ? null : <FreeTrialAlert />;

  return asAside ? (
    <ModalAside isOpen={show} headerContent={getTitle()}>
      {alert}
      <Box>{modalBody}</Box>
      <Box display="flex" gap="2" justifyContent="flex-end">
        {modalCtas}
      </Box>
    </ModalAside>
  ) : (
    <Modal open={show}>
      <Modal.Header title={getTitle()} />
      <Modal.Body padding="none">{alert}</Modal.Body>
      <Modal.Body padding="none">{modalBody}</Modal.Body>
      <Modal.Footer>{modalCtas}</Modal.Footer>
    </Modal>
  );
}
