import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ShippingMethodsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getShippingMethodsAction,
  getShippingMethodsData,
  getShippingMethodsStatus,
} from '../../deliveryMethodsSlice';

const EMPTY_SHIPPING_METHODS: ShippingMethodsResponseDto = {
  others: [],
  branch: [],
  table: [],
};

function useGetShippingMethods() {
  const dispatch = useAppDispatch();
  const shippingMethods = useSelector(getShippingMethodsData);
  const status = useSelector(getShippingMethodsStatus);

  const fetchShippingMethods = useCallback(
    () => dispatch(getShippingMethodsAction()),
    [dispatch],
  );

  const filteredShippingMethods = removePickupPointFromMethods(shippingMethods);

  return {
    isIdle: shippingMethods === null,
    shippingMethods: filteredShippingMethods ?? EMPTY_SHIPPING_METHODS,
    fetchShippingMethods,
    status,
  };
}

function removePickupPointFromMethods(
  shippingMethods: ShippingMethodsResponseDto | null,
): ShippingMethodsResponseDto | null {
  if (!shippingMethods) {
    return shippingMethods;
  }
  return Object.fromEntries(
    Object.entries(shippingMethods).filter(([key]) => key !== 'pickup-point'),
  ) as ShippingMethodsResponseDto;
}

export default useGetShippingMethods;
