import { Redirect, Route, Switch } from 'react-router';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { accountRoutes } from 'domains/Account';
import { useGetTags, useIsMobileDevice } from 'domains/Auth/hooks';
import { UserFormPage, UsersListPage } from './pages';
import { CONFIGURATIONS_ROUTES } from '../configurationsRoutes';

function Users(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  if (isMobileDevice)
    return (
      <Switch>
        {isRevampMenu ? (
          <Route exact path={accountRoutes.users}>
            <Redirect to={CONFIGURATIONS_ROUTES.users} />
          </Route>
        ) : (
          <Route path={accountRoutes.users} exact>
            <UsersListPage />
          </Route>
        )}

        <Route path={accountRoutes.userForm}>
          <UserFormPage />
        </Route>
      </Switch>
    );

  return (
    <Switch>
      {isRevampMenu ? (
        <Route exact path={accountRoutes.users}>
          <Redirect to={CONFIGURATIONS_ROUTES.users} />
        </Route>
      ) : (
        <Route path={accountRoutes.users} exact>
          <ToggleMigratedOldDomain domain="users">
            <UsersListPage />
          </ToggleMigratedOldDomain>
        </Route>
      )}

      <Route path={accountRoutes.userForm}>
        <ToggleMigratedOldDomain domain="users">
          <UserFormPage />
        </ToggleMigratedOldDomain>
      </Route>
    </Switch>
  );
}

export default Users;
