import { useMemo } from 'react';
import { Accordion, Box, Button, Card, Title } from '@nimbus-ds/components';
import { useChargesToPay } from 'domains/Billing/Checkout/hooks';
import { PaymentDataContent } from 'domains/Billing/Checkout/pages/components';
import {
  trackingBillingStartPlanPayment,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useContinueWithPayment } from '../../hooks';
import SummaryError from '../../SummaryError';
import SummarySkeleton from '../../SummarySkeleton';

function SummaryWithoutRP() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.withoutRP',
  );
  const {
    totalWithoutTaxes,
    isLoading,
    isError,
    refreshChargesToPay,
    chargesToPay,
    transformChargesToDisplayable,
    currency,
  } = useChargesToPay();
  const formatCurrency = useFormatCurrency();

  const charges = useMemo(
    () => chargesToPay && transformChargesToDisplayable(chargesToPay),
    [chargesToPay, transformChargesToDisplayable],
  );
  const logEvent = useCheckoutTracking();

  const continueWithPayment = useContinueWithPayment();

  if (isLoading) return <SummarySkeleton />;

  if (isError) return <SummaryError onClick={refreshChargesToPay} />;

  if (!totalWithoutTaxes) return null;

  const goToPay = () => {
    trackingBillingStartPlanPayment(logEvent);
    continueWithPayment();
  };

  return (
    <Card padding="none">
      <Accordion>
        <Accordion.Item index="0">
          <Accordion.Header borderTop="none">
            <Box width="100%">
              <Title as="h3" textAlign="left">
                {`${t('title')}: ${formatCurrency(
                  totalWithoutTaxes,
                  currency,
                )}`}
              </Title>
            </Box>
          </Accordion.Header>
          <Accordion.Body>
            {charges && (
              <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
                <PaymentDataContent charges={charges} />
              </Box>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Box pr="4" pb="4" pl="4">
        <Card.Footer>
          <Button appearance="primary" onClick={goToPay}>
            {t('goToPay')}
          </Button>
        </Card.Footer>
      </Box>
    </Card>
  );
}

export default SummaryWithoutRP;
