import { Redirect, Route } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetTags } from 'domains/Auth/hooks';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { INVOICES_ROUTES } from './invoicesRoutes';
import { InvoicesListPage, InvoicesInfoPage } from './pages';

function Invoices() {
  const { validateRoles } = useRoles();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  if (!validateRoles('invoice_history')) return null;

  return (
    <>
      {isRevampMenu ? (
        <Route exact path={INVOICES_ROUTES.invoicesList}>
          <Redirect to={CONFIGURATIONS_ROUTES.invoiceHistory} />
        </Route>
      ) : (
        <Route exact path={INVOICES_ROUTES.invoicesList}>
          <InvoicesListPage />
        </Route>
      )}

      <Route exact path={INVOICES_ROUTES.invoiceInfo}>
        <InvoicesInfoPage />
      </Route>
    </>
  );
}

export default Invoices;
