import { useCallback, useEffect, useState } from 'react';
import { Box, Card, Link, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import {
  LocationResponseDto,
  PickupPointResponseDto,
} from '@tiendanube/common';
import { ErrorScreen, Stack } from 'commons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import usePickupPoints from '../../hooks/usePickupPoints';
import LocationPointItem from '../LocationPointItem';
import PickupPointItem from '../PickupPointItem';

interface LocationsAsPickupProps {
  handleUpdateStatus: (pickupPoint: PickupPointResponseDto) => void;
}

function LocationsAsPickup({
  handleUpdateStatus,
}: Readonly<LocationsAsPickupProps>) {
  const t = useTranslationShipping('deliveryMethods.pickupPoints.locations');
  const {
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
    locations,
    fetchLocations,
  } = useLocations();
  const {
    isLoadingList,
    isErrorList,
    locationsAsPickupPoint,
    fetchPickupPoints,
  } = usePickupPoints();

  const [showMore, setShowMore] = useState(false);
  const [locationsToShow, setLocationsToShow] = useState<LocationResponseDto[]>(
    [],
  );

  const isLoading = isLoadingList || isLoadingLocations;
  const isError = isErrorList || isErrorLocations;
  const hasLocations = locations && locations.length > 0;

  const handleShowMoreLocations = () => {
    const newLocationsToShow = locations
      .sort(sortLocations)
      .slice(0, locationsToShow.length + 3);
    const showMore = newLocationsToShow.length < locations.length;
    setLocationsToShow(newLocationsToShow);
    setShowMore(showMore);
  };

  const handleShowLessLocations = () => {
    const newLocationsToShow = locations
      .sort(sortLocations)
      .slice(0, locationsToShow.length >= 6 ? -3 : 3);
    const showMore = newLocationsToShow.length === 3;
    setLocationsToShow(newLocationsToShow);
    setShowMore(showMore);
  };

  const sortLocations = useCallback(
    (a: LocationResponseDto, b: LocationResponseDto): number => {
      const aPickup = locationsAsPickupPoint.find((pickup) =>
        pickup.locations.includes(a.id),
      );
      const bPickup = locationsAsPickupPoint.find((pickup) =>
        pickup.locations.includes(b.id),
      );

      const getPriority = (
        pickup: PickupPointResponseDto | undefined,
      ): number => {
        if (pickup?.status) return 1;
        if (pickup) return 2;
        return 3;
      };

      return getPriority(aPickup) - getPriority(bPickup);
    },
    [locationsAsPickupPoint],
  );

  useEffect(() => {
    if (!isLoading && hasLocations) {
      setShowMore(locations.length > 4);
      setLocationsToShow(
        locations.sort(sortLocations).slice(0, locations.length > 4 ? 3 : 4),
      );
    }
  }, [isLoading, hasLocations, locations, sortLocations]);

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('title')} />
      <Card.Body padding="base">
        {!isError && <Text>{t('subtitle')}</Text>}

        {isError && !isLoading && (
          <ErrorScreen
            description={t('error.description')}
            onRetry={() => {
              fetchPickupPoints();
              fetchLocations();
            }}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <Stack
          spacing="none"
          column
          flex="0 100%"
          align="flex-start"
          justify="space-around"
        >
          {!isError && !isLoading && hasLocations && (
            <DataList>
              {locationsToShow.sort(sortLocations).map((location) => {
                const locationAsPickupPoint = locationsAsPickupPoint.find(
                  ({ locations }) =>
                    locations.length && locations[0] === location.id,
                );

                if (locationAsPickupPoint) {
                  return (
                    <PickupPointItem
                      key={location.id}
                      pickupPoint={locationAsPickupPoint}
                      locations={[location]}
                      handleUpdateStatus={handleUpdateStatus}
                    />
                  );
                }

                return (
                  <LocationPointItem
                    key={location.id}
                    id={location.id}
                    address={location.address}
                    name={location.name}
                  />
                );
              })}
            </DataList>
          )}

          {isLoading && (
            <DataList bottomDivider={false}>
              <LocationPointItem.Skeleton />
            </DataList>
          )}
          {locations.length > 3 && !isLoading && !isError && (
            <Box padding="4" alignSelf="center">
              <Link
                appearance="primary"
                textDecoration="none"
                as="button"
                onClick={() =>
                  showMore
                    ? handleShowMoreLocations()
                    : handleShowLessLocations()
                }
              >
                {showMore ? t('seeMore') : t('seeLess')}
              </Link>
            </Box>
          )}
        </Stack>
      </Card.Footer>
    </Card>
  );
}

export default LocationsAsPickup;
