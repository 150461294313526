import { useEffect } from 'react';
import FeatureFlag from 'App/components/FeatureFlag';
import { PROJECT_SHIVA_TAG } from 'App/featuresFlags';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useGetPlanExpiresIn } from 'domains/Auth/hooks';
import { AffiliateDiscountPercentageAlert } from './AffiliateDiscountPercentageAlert';
import { TrialExpirationAlert } from './TrialExpirationAlert';
import useTrialMessages from './useTrialMessages/useTrialMessages';

function TrialMessages() {
  const expiresIn = useGetPlanExpiresIn();
  const { getTrialMessages, trialMessage, isSuccess } = useTrialMessages();
  const { isMobileNonFreemium } = useGoToPlans();

  useEffect(() => {
    getTrialMessages();
  }, [getTrialMessages]);

  if (!isSuccess) return null;

  const mobileSuffix = isMobileNonFreemium ? 'Mobile' : '';

  return (
    <>
      <AffiliateDiscountPercentageAlert
        mobileSuffix={mobileSuffix}
        affiliateDiscountPercentage={trialMessage?.affiliateDiscountPercentage}
        show={trialMessage?.showMessage}
      />
      <FeatureFlag
        flag={PROJECT_SHIVA_TAG}
        renderElse={
          <TrialExpirationAlert
            expiresIn={expiresIn}
            mobileSuffix={mobileSuffix}
          />
        }
      />
    </>
  );
}

export default TrialMessages;
